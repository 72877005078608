import {
  useCalculateTotalFees,
  useGetDeliveryOptions,
} from '@tradeaze/frontend/hooks';
import { useCallback, useEffect, useMemo } from 'react';
import {
  ALL_DELIVERY_VEHICLE_IDS,
  CreateDeliveryItem,
  CreateOrder,
  DeliveryOptionId,
  DeliveryVehicleId,
  MerchantAccount,
  RegionId,
} from '@tradeaze-packages/schemas';
import { useFormContext } from 'react-hook-form';
import {
  createMaxDeliveryItem,
  filterVehiclesByItemSize,
  getNextOptionOnVehicleChange,
  getVehicleSvgDimensions,
  unitConverter,
} from '../utils';
import { calc } from '@chakra-ui/react';
import { useDeliveryTimeManagement } from './useDeliveryTimeManagement';

type UseDeliveryDetailsSection = {
  merchantAccount?: MerchantAccount;
  initialDate?: Date;
  isAdmin: boolean;
  hidePriorityDelivery: boolean;
  isAnyStopOverThreshold: boolean;
  itemDetails: CreateDeliveryItem;
  isMultiDrop: boolean;
};

export const useDeliveryDetailsSection = ({
  merchantAccount,
  initialDate,
  hidePriorityDelivery,
  isAdmin,
  isAnyStopOverThreshold,
  itemDetails,
  isMultiDrop,
}: UseDeliveryDetailsSection) => {
  const form = useFormContext<CreateOrder>();
  const { watch, setValue, getValues } = form;
  const deliveryVehicleId = watch('deliveryVehicle');
  const deliveryOptionId = watch('deliveryOption');
  const deliveryPrice = watch('deliveryPrice');
  const serviceCharge = watch('merchantServiceCharge');
  const deliveryWindowStart = watch('deliveryWindowStart');
  const deliveryWindowEnd = watch('deliveryWindowEnd');
  const totalTimeEstimate = watch('totalTimeEstimate') ?? undefined;
  const selectedVehicle = watch('deliveryVehicle');
  const orderType = watch('type');
  const maxDeliveryItem =
    orderType === 'A_TO_B'
      ? itemDetails
      : createMaxDeliveryItem(form, isMultiDrop);

  const {
    fromDeliveryDateTime,
    scheduledTime,
    isPastDate,
    isSaturday,
    isInvalidScheduledTime,
    handleSelectDeliveryDate,
    handleChangeScheduledTime,
  } = useDeliveryTimeManagement({
    initialDate,
    deliveryOptionId,
    totalTimeEstimate,
    setValue,
    getValues,
  });

  const deliveryOptionsQuery = useGetDeliveryOptions({
    date: fromDeliveryDateTime.toISOString(),
    regionId: (merchantAccount?.regionId as RegionId) ?? 'LONDON',
    isAdmin,
    hidePriorityDelivery,
    isAnyStopOverThreshold,
  });

  const availableDeliveryOptions = useMemo(
    () =>
      Object.values(deliveryOptionsQuery.data ?? {})
        .flatMap((v) => v.options)
        .filter((v) => v.isAvailable),
    [deliveryOptionsQuery.data],
  );

  const deliveryVehicles = useMemo(
    () => Object.values(deliveryOptionsQuery.data ?? {}).map((v) => v.vehicle),
    [deliveryOptionsQuery.data],
  );

  const selectedVehicleDetail = useMemo(
    () =>
      deliveryVehicles.find((el) => el.deliveryVehicleId === deliveryVehicleId),
    [deliveryVehicles, deliveryVehicleId],
  );

  const scaleDimensionsMaintainingAspectRatio = (sideView: boolean) => {
    const vehicleHeight = selectedVehicleDetail?.heightCapacity ?? 0;
    const vehicleWidth = selectedVehicleDetail?.widthCapacity ?? 0;
    const vehicleLength = selectedVehicleDetail?.lengthCapacity ?? 0;

    const selectedUnit = itemDetails?.widthUnit || 'mm';

    const loadHeight =
      unitConverter(itemDetails?.height || 0, selectedUnit, 'm') ?? 0;
    const loadWidth =
      unitConverter(itemDetails?.width || 0, selectedUnit, 'm') ?? 0;
    const loadDepth =
      unitConverter(itemDetails?.length || 0, selectedUnit, 'm') ?? 0;

    const targetDimensions = getVehicleSvgDimensions(
      selectedVehicleDetail?.deliveryVehicleId,
      sideView ? 'SIDE' : 'REAR',
    );
    const svgHeight = targetDimensions?.length ?? 0;
    const svgWidth = targetDimensions?.width ?? 0;

    const heightScale = loadHeight / vehicleHeight;
    const widthScale =
      (sideView ? loadDepth : loadWidth) /
      (sideView ? vehicleLength : vehicleWidth);

    const yArea = Math.max(svgHeight * heightScale, 0.05 * svgHeight); // Ensure minimum 5% of SVG height (0.05 is the minimum area)
    const xArea = Math.max(svgWidth * widthScale, 0.05 * svgWidth); // Ensure minimum 5% of SVG width (0.05 is the minimum area)

    const xPosition = calc(
      Number(targetDimensions?.xPosition ?? 0) + (svgWidth - xArea),
    ).toString();

    const yPosition = calc(
      Number(targetDimensions?.yPosition ?? 0) + (svgHeight - yArea),
    ).toString();

    return { xArea, yArea, xPosition, yPosition };
  };

  const filteredVehicles = useMemo(() => {
    if (!deliveryVehicles || deliveryVehicles.length === 0) return undefined;
    return deliveryVehicles
      .slice(0)
      .sort((a, b) => {
        return (
          ALL_DELIVERY_VEHICLE_IDS.indexOf(a.deliveryVehicleId) -
          ALL_DELIVERY_VEHICLE_IDS.indexOf(b.deliveryVehicleId)
        );
      })
      .filter((deliveryVehicle) =>
        filterVehiclesByItemSize(deliveryVehicle, maxDeliveryItem),
      );
  }, [JSON.stringify(itemDetails), deliveryVehicles]);

  const setOptionToNull = useCallback(() => {
    setValue('deliveryOption', null as unknown as DeliveryOptionId); // cast so we can set to null, which is fine in a form
  }, [setValue]);

  const handleOptionAndItemsOnDeliveryVehicle = (
    vehicle: DeliveryVehicleId,
  ) => {
    const option = getNextOptionOnVehicleChange({
      currentOption: deliveryOptionId,
      newVehicle: vehicle,
      availableDeliveryOptions: availableDeliveryOptions,
      currentVehicle: deliveryVehicleId,
    });

    if (availableDeliveryOptions.find((el) => el.deliveryOptionId === option)) {
      setValue(
        'deliveryOption',
        option ? option : (null as unknown as DeliveryOptionId), // cast so we can set to null, which is fine in a form
      );
    } else {
      setOptionToNull();
    }
  };

  const isVehicleAvailable = useMemo(
    () =>
      availableDeliveryOptions.some((el) =>
        el.deliveryVehicleId.includes(deliveryVehicleId),
      ),
    [availableDeliveryOptions, deliveryVehicleId],
  );

  const showDeliveryPrice =
    deliveryPrice !== undefined && deliveryPrice !== null && deliveryOptionId;

  const totalTradeazeCharges = useCalculateTotalFees({
    deliveryPrice,
    serviceCharge,
  });

  useEffect(() => {
    if (!filteredVehicles) return;

    const isSelectedVehicleAvailable = filteredVehicles.some(
      (vehicle) => vehicle.deliveryVehicleId === selectedVehicle,
    );

    if (isSelectedVehicleAvailable) {
      handleOptionAndItemsOnDeliveryVehicle(selectedVehicle);
    } else {
      setValue('deliveryVehicle', filteredVehicles[0]?.deliveryVehicleId);
      setValue('deliveryOption', null as unknown as DeliveryOptionId); // Type casting is needed here as deliveryOption doesn't accept null
    }
  }, [filteredVehicles]);

  return {
    deliveryOptionId,
    deliveryOptionsByVehicle: deliveryOptionsQuery.data,
    deliveryPrice,
    deliveryVehicleId,
    deliveryWindowEnd,
    deliveryWindowStart,
    filteredVehicles,
    form,
    fromDeliveryDateTime,
    handleChangeScheduledTime,
    handleOptionAndItemsOnDeliveryVehicle,
    handleSelectDeliveryDate,
    isInvalidScheduledTime,
    isPastDate,
    isSaturday,
    isVehicleAvailable,
    deliveryOptionsError: deliveryOptionsQuery.error,
    deliveryOptionsLoading: deliveryOptionsQuery.isLoading,
    scaleDimensionsMaintainingAspectRatio,
    scheduledTime,
    selectedVehicleDetail,
    serviceCharge,
    showDeliveryPrice,
    totalTradeazeCharges,
  };
};
