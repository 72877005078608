import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { z } from 'zod';

extendZodWithOpenApi(z);

export const ApiErrorSchema = z
  .object({
    error: z.object({
      message: z.string(),
      data: z.unknown().optional(),
    }),
    status: z.number(),
  })
  .openapi('ApiErrorResponse');

export type ApiError = z.infer<typeof ApiErrorSchema>;
