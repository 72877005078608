import {
  PickupSection,
  DropOffSection,
  ItemDetailsSection,
  DeliveryDetailsSection,
  BookingInformationSection,
  LongDistanceAlertSection,
  CompleteOrderButton,
  InvoicingSection,
} from '../order-form-sections';
import {
  CreateOrder,
  MerchantAccount,
  ExistingOrder,
  DuplicateOrder,
} from '@tradeaze-packages/schemas';
import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { PostHog } from 'posthog-js/react';
import { useAtoBForm } from '../hooks';
import { AddMarkerFn, RemoveMarkerFn } from '../../map';

type AtoBOrderFormProps = {
  merchantAccount?: MerchantAccount;
  isAdmin: boolean;
  posthog?: PostHog;
  onSubmit: (order: CreateOrder) => void;
  addPickupMarker: AddMarkerFn;
  addDeliveryMarker: AddMarkerFn;
  removePickupMarker: RemoveMarkerFn;
  removeDeliveryMarker: RemoveMarkerFn;
  isLoading: boolean;
  existingOrder?: ExistingOrder;
  duplicateOrder?: DuplicateOrder;
};

export const AtoBOrderForm = ({
  merchantAccount,
  isAdmin,
  posthog,
  onSubmit,
  addPickupMarker,
  addDeliveryMarker,
  removePickupMarker,
  removeDeliveryMarker,
  isLoading,
  existingOrder,
  duplicateOrder
}: AtoBOrderFormProps) => {
  const {
    isPickupComplete,
    isDropOffComplete,
    isItemDetailsComplete,
    isVehicleDetailsComplete,
    isBookingInformationComplete,
    isInvoicingComplete,
    hidePriorityDelivery,
    thresholdDistances,
    isDeliveryPriceLoading,
    deliveryPriceErrorMessage,
    form,
    pickupForm,
    dropOffForm,
    itemForm,
    itemDetails,
    handleSubmit,
    allowLongDistance,
    isPrimaryView,
    setIsPrimaryView,
    isVehicleModalOpen,
    setIsVehicleModalOpen,
    restrictedEditing,
    currentOrder,
  } = useAtoBForm({
    merchantAccount,
    isAdmin,
    posthog,
    onSubmit,
    existingOrder,
    duplicateOrder,
  });

  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" gap={8}>
        {existingOrder?.isExternalJobAssigned && (
          <Alert status="warning">
            <AlertIcon />
            Please note: Certain attributes of a delivery assigned to an
            external courier cannot be edited.
          </Alert>
        )}
        {!merchantAccount ? (
          <InvoicingSection
            form={form}
            isSectionComplete={isInvoicingComplete}
          />
        ) : null}
        <PickupSection
          sectionName="Pick Up"
          defaultPickupAddress={merchantAccount?.address}
          defaultPickupPostcode={merchantAccount?.postCode}
          defaultPickupPosition={merchantAccount?.position}
          defaultPickupCity={merchantAccount?.city}
          merchantAccount={merchantAccount}
          showExpanded={Boolean(existingOrder) || Boolean(duplicateOrder)}
          form={pickupForm}
          isSectionComplete={isPickupComplete}
          addMarker={addPickupMarker}
          removeMarker={removePickupMarker}
          restrictedEditing={restrictedEditing}
          cypressTestId={'pickup-section'}
          stopId={1}
        />
        <DropOffSection
          sectionName="Drop Off"
          merchantAccount={merchantAccount}
          form={dropOffForm}
          isSectionComplete={isDropOffComplete}
          addMarker={addDeliveryMarker}
          removeMarker={removeDeliveryMarker}
          showExpanded={Boolean(existingOrder) || Boolean(duplicateOrder)}
          restrictedEditing={restrictedEditing}
          stopId={2}
          cypressTestId={'dropoff-section'}
        />

        <ItemDetailsSection
          sectionName="Item Details"
          isSectionComplete={isItemDetailsComplete}
          form={itemForm}
          posthog={posthog}
          setIsPrimaryView={setIsPrimaryView}
          setIsVehicleModalOpen={setIsVehicleModalOpen}
          hideSection={restrictedEditing}
        />

        <DeliveryDetailsSection
          sectionName="Delivery Details"
          merchantAccount={merchantAccount}
          initialDate={existingOrder?.orderDate || undefined}
          hidePriorityDelivery={hidePriorityDelivery}
          isAdmin={isAdmin}
          isAnyStopOverThreshold={thresholdDistances.isAnyStopOverThreshold}
          isDeliveryPriceLoading={isDeliveryPriceLoading}
          itemDetails={itemDetails}
          isSectionComplete={isVehicleDetailsComplete}
          deliveryPriceErrorMessage={deliveryPriceErrorMessage}
          isPrimaryView={isPrimaryView}
          setIsPrimaryView={setIsPrimaryView}
          isVehicleModalOpen={isVehicleModalOpen}
          setIsVehicleModalOpen={setIsVehicleModalOpen}
          hideSection={restrictedEditing}
        />
        <BookingInformationSection
          sectionName="Booking Information"
          isSectionComplete={isBookingInformationComplete}
        />
        <LongDistanceAlertSection
          isEveryStopOverThreshold={thresholdDistances.isEveryStopOverThreshold}
          allowLongDistance={allowLongDistance}
        />
        <CompleteOrderButton
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          isUpdatingOrder={Boolean(existingOrder)}
          label={existingOrder ? 'Update A to B Order' : 'Create A to B Order'}
          currentOrder={currentOrder}
          orderType="A_TO_B"
        />
      </Box>
    </FormProvider>
  );
};
