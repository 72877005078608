import {
  Company,
  CreateDeliveryStop,
  MerchantAccount,
  MerchantDefinedCompany,
  MerchantDefinedCompanyAddress,
} from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { useDeliveryStop } from './useDeliveryStop';
import {
  getAddressesQueryKey,
  useCreateMerchantCompany,
  useCreateMerchantCompanyAddress,
  useGeocodeAddress,
  useGetCompaniesByMerchant,
  useUpdateAddress,
} from '@tradeaze/frontend/hooks';
import { useCallback, useEffect, useMemo } from 'react';
import { AddMarkerFn, RemoveMarkerFn } from '../../map';
import { handleError } from '@tradeaze/frontend/utils';
import { getHasAddressChanged } from '../utils/getHasAddressChanged';

type UseDropOffSection = {
  merchantAccount?: MerchantAccount;
  stopSequence?: number;
  form: UseFormReturn<CreateDeliveryStop>;
  addMarker: AddMarkerFn;
  removeMarker: RemoveMarkerFn;
  stopId: number;
};

export const useDropOffSection = ({
  merchantAccount,
  stopSequence,
  form,
  addMarker,
  removeMarker,
  stopId,
}: UseDropOffSection) => {
  const { setValue, watch, clearErrors } = form;
  
  const companyId = watch('companyId');
  const companyName = watch('companyName');
  const addressLine1 = watch('addressLine1');
  const postCode = watch('postCode');
  const addressId = watch('addressId');
  const deliveryNotes = watch('deliveryNotes');
  const contacts = watch('contacts');
  const position = watch('position');
  const city = watch('city');

  const {
    showFullAddress,
    isManualAddress,
    instructionsVisibility,
    setInstructionsVisibility,
    isLoadingSavedAddresses,
    savedAddresses,
    savedAddressPlaceholder,
    handleSelectExistingAddress,
    handleSelectLocation,
    handleManualAddress,
  } = useDeliveryStop({
    form,
    merchantAccount,
    addressType: 'DROP_OFF',
    companyId: companyId,
  });

  const getCompaniesQuery = useGetCompaniesByMerchant(
    merchantAccount?.merchantId,
  );

  const createAddressMutation = useCreateMerchantCompanyAddress({
    invalidateQueryKeys: [getAddressesQueryKey()],
    onSuccess: ({ addressId }) => {
      setValue('addressId', addressId);
    },
  });

  const updateAddressMutation = useUpdateAddress();

  const geocodeQuery = useGeocodeAddress(
    { postcode: postCode },
    {
      enabled: isManualAddress,
    },
  );

  const {
    mutateAsync: createMerchantCompany,
    isLoading: isLoadingCreateMerchantCompany,
  } = useCreateMerchantCompany();

  const merchantCustomers = useMemo(
    () =>
      getCompaniesQuery?.data?.pages[0].companies.sort((a, b) =>
        a.companyName.localeCompare(b.companyName),
      ),
    [getCompaniesQuery.data],
  );

  const hasSelectedMerchantCustomer = useMemo(
    () => merchantCustomers?.some((el) => el.companyId === companyId),
    [merchantCustomers, companyId],
  );

  const canSaveMerchantCustomer = useMemo(
    () =>
      merchantAccount &&
      companyName &&
      !merchantCustomers?.some((el) => el.companyId === companyId),
    [companyName, merchantAccount, merchantCustomers, companyId],
  );

  const canSaveAddress =
    !!merchantAccount &&
    !!companyId &&
    !!addressLine1 &&
    !!city &&
    !!postCode &&
    !!position &&
    !addressId;

  const canUpdateAddress = useMemo<boolean>(() => {
    if (!addressId || !savedAddresses) {
      return false;
    }
    const savedAddress = savedAddresses.find(
      (el) => el.address.addressId === addressId,
    );

    if (!savedAddress) {
      return false;
    }

    return getHasAddressChanged(savedAddress.address, {
      addressLine1,
      postCode,
      companyName,
      deliveryNotes,
      city,
    });
  }, [addressId, savedAddresses, addressLine1, postCode, companyName, deliveryNotes, city]);

  const handleSaveMerchantCustomerCallback = useCallback(async () => {
    try {
      if (!companyName || !merchantAccount) {
        return;
      }
      const newCompany: MerchantDefinedCompany = {
        merchantId: merchantAccount.merchantId,
        companyName,
      };
      const company = await createMerchantCompany(newCompany);
      setValue('companyId', company.companyId);
    } catch (error) {
      handleError(error, {
        sendToSentry: true,
      });
    }
  }, [createMerchantCompany, setValue, companyName, merchantAccount]);

  const handleSelectMerchantCustomerCallback = useCallback(
    (customer: Company) => {
      setValue('addressLine1', '');
      setValue('postCode', '');
      setValue('city', '');

      if (!customer) {
        setValue('companyName', '');
        setValue('companyId', undefined);
        return;
      }

      setValue('companyName', customer.companyName);
      setValue('companyId', customer.companyId);
      clearErrors('companyName');
    },
    [setValue, clearErrors],
  );

  const handleSaveAddress = async () => {
    if (!merchantAccount || !addressLine1 || !postCode || !position || !city) {
      return;
    }

    const newAddress: MerchantDefinedCompanyAddress = {
      companyId,
      companyName,
      instructions: deliveryNotes,
      merchantId: merchantAccount.merchantId,
      addressLine1,
      city,
      postCode,
      position,
      addressType: 'DROP_OFF',
    };

    createAddressMutation.mutate(newAddress);
  };

  const handleUpdateAddress = () => {
    if (!addressId) {
      return;
    }
    updateAddressMutation.mutate({
      addressId,
      body: {
        contactIds: contacts.map((contact) => contact.siteContactId || ''),
        instructions: deliveryNotes,
        companyName: companyName,
        addressLine1,
        position,
        postCode,
      },
    });
  };

  useEffect(() => {
    if (!isManualAddress) return;

    if (geocodeQuery.data) {
      setValue('position', geocodeQuery.data.position);
    } else {
      setValue('position', null);
    }
  }, [geocodeQuery.data, isManualAddress, setValue]);

  useEffect(() => {
    if (position) {
      addMarker?.({
        id: stopId.toString() ?? '',
        position,
        stopSequence,
      });
    } else {
      removeMarker?.(stopId.toString());
    }
  }, [position]);

  return {
    form,
    showFullAddress,
    isManualAddress,
    instructionsVisibility,
    setInstructionsVisibility,
    geocodeData: geocodeQuery.data,
    geocodeError: geocodeQuery.error,
    savedAddresses,
    savedAddressPlaceholder: hasSelectedMerchantCustomer
      ? savedAddressPlaceholder
      : 'Existing customer not selected',
    isLoadingSavedAddresses,
    handleSelectExistingAddress,
    handleSelectLocation,
    handleManualAddress,
    merchantCustomers,
    canSaveMerchantCustomer,
    handleSaveMerchantCustomer: handleSaveMerchantCustomerCallback,
    hasSelectedMerchantCustomer,
    handleSelectMerchantCustomer: handleSelectMerchantCustomerCallback,
    isLoadingCreateMerchantCompany,
    isLoadingMerchantCustomers: getCompaniesQuery.isLoading,
    companyId,
    canSaveAddress,
    handleSaveAddress,
    isLoadingCreateAddress: createAddressMutation.isLoading,
    canUpdateAddress,
    handleUpdateAddress,
    isLoadingUpdateAddress: updateAddressMutation.isLoading,
  };
};
