import { useDeliveryPrice } from './useDeliveryPrice';
import { PostHog } from 'posthog-js/react';
import {
  CreateOrder,
  MerchantAccount,
  ExistingOrder,
  DuplicateOrder,
  CreateOrderAToB,
  buildCreateOrderSchema,
} from '@tradeaze-packages/schemas';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  isDropOffSectionComplete,
  isItemDetailsSectionComplete,
  isPickupSectionComplete,
  isVehicleDetailsSectionComplete,
  isBookingInformationSectionComplete,
  isInvoicingSectionComplete,
  isPricingConfigSame,
} from '../utils';
import { useOrderSubForms } from './useOrderSubForms';
import { useAtoBFormSubmission } from './useAtoBFormSubmission';
import { useState } from 'react';
import { createOrderDefaultValues } from './utils/createOrderDefaultValues';

type UseAtoBForm = {
  merchantAccount?: MerchantAccount;
  isAdmin?: boolean;
  posthog?: PostHog;
  onSubmit: (order: CreateOrder) => void;
  existingOrder?: ExistingOrder;
  duplicateOrder?: DuplicateOrder;
};

export const useAtoBForm = ({
  merchantAccount,
  isAdmin,
  posthog,
  onSubmit,
  existingOrder,
  duplicateOrder,
}: UseAtoBForm) => {
  const [isVehicleModalOpen, setIsVehicleModalOpen] = useState(false);
  const [isPrimaryView, setIsPrimaryView] = useState(true);

  const form = useForm<CreateOrder>({
    defaultValues: createOrderDefaultValues({
      orderType: 'A_TO_B',
      merchantAccount,
      existingOrder,
      duplicateOrder,
    }),
    resolver: zodResolver(buildCreateOrderSchema('A_TO_B', merchantAccount)),
    mode: 'all',
  });

  const aToBDetails = form.watch();

  const {
    pickupForm,
    dropOffForm,
    itemForm,
    pickupDetails,
    dropoffDetails,
    itemDetails,
  } = useOrderSubForms({
    merchantAccount,
    existingStopData: existingOrder
      ? {
          pickupDetails: existingOrder.pickupDetails,
          dropOffDetails: existingOrder.dropoffDetails,
        }
      : undefined,
    duplicateStopData: duplicateOrder
      ? {
          pickupDetails: duplicateOrder?.pickupDetails,
          dropOffDetails: duplicateOrder?.dropoffDetails,
        }
      : undefined,
    orderType: 'A_TO_B',
  });

  const currentOrder: Partial<CreateOrderAToB> = {
    ...aToBDetails,
    type: 'A_TO_B',
    pickup: {
      ...pickupDetails,
      type: 'PICK_UP',
      deliveryItems: [itemDetails],
      collectionReference: pickupDetails.collectionReference || '',
    },
    dropOff: {
      ...dropoffDetails,
      type: 'DROP_OFF',
    },
  };

  const { handleSubmit } = useAtoBFormSubmission({
    pickupForm,
    dropOffForm,
    itemForm,
    form,
    deliveryWindowStart: aToBDetails.deliveryWindowStart,
    deliveryWindowEnd: aToBDetails.deliveryWindowEnd,
    onSubmit,
  });

  const hidePriorityDelivery =
    merchantAccount?.featureFlags?.hidePriorityDelivery || false;

  const allStops: { postCode: string; type: 'PICK_UP' | 'DROP_OFF' }[] = [
    { postCode: pickupDetails.postCode, type: 'PICK_UP' },
    { postCode: dropoffDetails.postCode, type: 'DROP_OFF' },
  ];

  const currentPricingConfig = {
    deliveryOption: aToBDetails.deliveryOption,
    deliveryVehicle: aToBDetails.deliveryVehicle,
    deliveryWindowStart: aToBDetails.deliveryWindowStart,
    heavySideItems: itemDetails.heavySideItems ?? false,
    allStops,
  };

  const isSamePricingConfig = isPricingConfigSame(
    currentPricingConfig,
    existingOrder,
  );

  const {
    isDeliveryPriceLoading,
    deliveryPriceErrorMessage,
    thresholdDistances,
  } = useDeliveryPrice({
    merchantAccount,
    createOrderDetails: aToBDetails,
    allStops,
    isAdmin,
    posthog,
    form,
    pickupForm,
    dropOffForm,
    heavySideItems: itemDetails.heavySideItems ?? false,
    isSamePricingConfig,
    existingOrder: Boolean(existingOrder),
  });

  const allowLongDistance = Boolean(
    isAdmin || merchantAccount?.featureFlags?.allowLongDistance,
  );

  const isPickupComplete = isPickupSectionComplete(pickupDetails, pickupForm);
  const isDropOffComplete = isDropOffSectionComplete(
    dropoffDetails,
    dropOffForm,
  );
  const isItemDetailsComplete = isItemDetailsSectionComplete(
    itemDetails,
    itemForm,
  );
  const isVehicleDetailsComplete = isVehicleDetailsSectionComplete(
    aToBDetails,
    form,
  );

  const isBookingInformationComplete = isBookingInformationSectionComplete(
    form,
    merchantAccount?.referencePrefix,
  );

  const isInvoicingComplete = isInvoicingSectionComplete(form);

  const restrictedEditing =
    (!isAdmin && existingOrder?.orderStatus === 'CONFIRMED') ||
    !!existingOrder?.isExternalJobAssigned;

  return {
    isPickupComplete,
    isDropOffComplete,
    isItemDetailsComplete,
    isVehicleDetailsComplete,
    isBookingInformationComplete,
    isInvoicingComplete,
    hidePriorityDelivery,
    thresholdDistances,
    isDeliveryPriceLoading,
    deliveryPriceErrorMessage,
    form,
    pickupForm,
    dropOffForm,
    itemForm,
    itemDetails,
    handleSubmit,
    allowLongDistance,
    isPrimaryView,
    setIsPrimaryView,
    isVehicleModalOpen,
    setIsVehicleModalOpen,
    restrictedEditing,
    currentOrder,
  };
};
