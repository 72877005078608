import {
  ClientDeliveryOption,
  DeliveryOptionId,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';

export function getNextOptionOnVehicleChange({
  currentVehicle,
  currentOption,
  newVehicle,
  availableDeliveryOptions: availableOptions,
}: {
  currentVehicle: DeliveryVehicleId;
  currentOption: DeliveryOptionId | null;
  newVehicle: DeliveryVehicleId;
  availableDeliveryOptions: ClientDeliveryOption[];
}): DeliveryOptionId | null {
  const nextOptionSameType = availableOptions.find((option) => {
    const includesVehicle = option.deliveryVehicleId === newVehicle;

    const includesOptionType =
      option.deliveryOptionId.replace(option.deliveryVehicleId, '') ===
      currentOption?.replace(currentVehicle, '');

    const shouldInclude = includesVehicle && includesOptionType;

    return shouldInclude;
  });

  if (nextOptionSameType) {
    return nextOptionSameType.deliveryOptionId;
  }

  const firstNextVehicleOption = availableOptions.find(
    (option) => option.deliveryVehicleId === newVehicle,
  );

  if (firstNextVehicleOption) {
    return firstNextVehicleOption.deliveryOptionId;
  }

  return null;
}
