import { Stack, Text, HStack, Icon } from '@chakra-ui/react';
import { Address } from '@tradeaze-packages/schemas';
import { BaseCard } from '../shared';
import { FaLocationArrow } from 'react-icons/fa';
import { DeleteButton } from './DeleteButton';

interface AddressBookAddressCardProps {
  address: Address;
  onDelete?: (addressId: string) => void;
  isLoadingDelete?: boolean;
  onHover?: (isHovering: boolean) => void;
}

export const AddressBookAddressCard = ({
  address,
  onDelete,
  isLoadingDelete,
  onHover,
}: AddressBookAddressCardProps) => {
  return (
    <BaseCard isClickable={false}>
      <Stack spacing={2}>
        <HStack spacing={2} alignItems="flex-start">
          <Icon as={FaLocationArrow} color="gray.500" boxSize={4} mt={1} />
          <Stack spacing={1}>
            <Text fontSize="md" fontWeight="medium" color="gray.700" data-cy="address-line-1">
              {address.addressLine1}
            </Text>
            <Text fontSize="sm" color="gray.600" data-cy="address-city">
              {address.city}
            </Text>
            <Text fontSize="sm" color="gray.600" data-cy="address-postcode">
              {address.postCode}
            </Text>
          </Stack>
        </HStack>
        {onDelete && (
          <DeleteButton
            title="Delete Address"
            description="Are you sure you want to delete this address?"
            onConfirm={() => onDelete(address.addressId)}
            isLoading={isLoadingDelete}
            dataCy="delete-address"
          />
        )}
      </Stack>
    </BaseCard>
  );
}; 