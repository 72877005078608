import {
  MerchantAccount,
  ExistingOrder,
  DuplicateOrder,
  CreateOrderMultiCollection,
  CreateOrderMultiDrop,
  CreateOrderAToB,
} from '@tradeaze-packages/schemas';

type OrderType = 'A_TO_B' | 'MULTI_COLLECTION' | 'MULTI_DROP';

interface CreateDefaultValuesProps {
  orderType: OrderType;
  merchantAccount?: MerchantAccount;
  existingOrder?: ExistingOrder;
  duplicateOrder?: DuplicateOrder;
}

type OrderReturnType<T extends OrderType> = T extends 'A_TO_B'
  ? Partial<CreateOrderAToB>
  : T extends 'MULTI_COLLECTION'
  ? Partial<CreateOrderMultiCollection>
  : T extends 'MULTI_DROP'
  ? Partial<CreateOrderMultiDrop>
  : never;

export const createOrderDefaultValues = <T extends OrderType>({
  orderType,
  merchantAccount,
  existingOrder,
  duplicateOrder,
}: CreateDefaultValuesProps & { orderType: T }): OrderReturnType<T> => {
  const baseDefaults = {
    type: orderType,
    merchantId:
      duplicateOrder?.merchantId ??
      existingOrder?.merchantId ??
      merchantAccount?.merchantId,
    invoicingName:
      duplicateOrder?.invoicingName ??
      existingOrder?.invoicingName ??
      merchantAccount?.invoicingName ??
      merchantAccount?.merchantName ??
      '',
    invoicingEmail:
      duplicateOrder?.invoicingEmail ??
      existingOrder?.invoicingEmail ??
      merchantAccount?.invoicingEmail ??
      null,
    deliveryVehicle:
      duplicateOrder?.deliveryVehicle ??
      existingOrder?.deliveryVehicle ??
      'BIKE',
    deliveryOption:
      duplicateOrder?.deliveryOption ??
      existingOrder?.deliveryOption ??
      undefined,
    bookedBy: existingOrder?.bookedBy ?? undefined,
    merchantOrderReference:
      existingOrder?.orderReference ??
      merchantAccount?.referencePrefix ??
      undefined,
    isPriceEdited: existingOrder?.isPriceEdited ?? false,
    deliveryPrice: existingOrder?.deliveryPrice ?? undefined,
    merchantServiceCharge: existingOrder?.merchantServiceCharge ?? undefined,
  };

  const result = (() => {
    switch (orderType) {
      case 'A_TO_B':
        return baseDefaults as OrderReturnType<'A_TO_B'>;
      case 'MULTI_COLLECTION':
        return {
          ...baseDefaults,
          pickups: (existingOrder?.pickupDetails ??
            []) as CreateOrderMultiCollection['pickups'],
        } as OrderReturnType<'MULTI_COLLECTION'>;
      case 'MULTI_DROP':
        return {
          ...baseDefaults,
          dropOffs: (existingOrder?.dropoffDetails ??
            []) as CreateOrderMultiDrop['dropOffs'],
        } as OrderReturnType<'MULTI_DROP'>;
      default:
        throw new Error(`Unsupported order type: ${orderType}`);
    }
  })();

  return result as OrderReturnType<T>;
};
