import { AnimatedImage } from '../shared/AnimatedImage';
import Svg from './assets/Construction-crane-rafiki.svg';

interface HiabImageProps {
  animationDelay?: number;
}

export const HiabImage = ({ animationDelay }: HiabImageProps) => {
  return (
    <AnimatedImage
      src={Svg}
      alt="Hiab Booking"
      position="absolute"
      bottom="0"
      right="0"
      height="100%"
      maxWidth="70%"
      objectFit="contain"
      animationDelay={animationDelay}
    />
  );
};
