import { DeliveryOptionTypeId } from '../models';
import { DeliveryVehicleId } from '../models/delivery-vehicle-schema';
import { getVehicleConfig } from './vehicle';

/**
 * Fixed costs
 */
export const SERVICE_CHARGE = 3.99;
export const MORNING_FIXED_CHARGE = 10;

/**
 * Relative multipliers for each delivery option
 */
export const DAY_MULTIPLIER = 1;
export const MORNING_MULTIPLIER = 1;
export const PRIORITY_MULTIPLIER = 1.5;
export const EVENING_MULTIPLIER = 1.5;
export const SCHEDULED_MULTIPLIER = 1.5;
export const THREE_HOUR_MULTIPLIER = 1.2;

/**
 * Vehicle costs configuration - used in calculateTotalCosts.ts
 */

type VehicleCostConfigSchema = {
  vehicleId: DeliveryVehicleId;
  costsPerMile: {
    petrol: number;
  };
  costsPerMinute: {
    riderWage: number;
    insurance: number;
    vehicleMaintenance: number;
  };
};

export const VEHICLE_COSTS: VehicleCostConfigSchema[] = [
  {
    vehicleId: 'BIKE',
    costsPerMile: {
      petrol: 0.062,
    },
    costsPerMinute: {
      riderWage: 0.167,
      insurance: 0.095, // should be 0 but dont want to drop prices by changing this now (29/12/24)
      vehicleMaintenance: 0.008,
    },
  },
  {
    vehicleId: 'BICYCLE',
    costsPerMile: {
      petrol: 0.062,
    },
    costsPerMinute: {
      riderWage: 0.167,
      insurance: 0.095, // should be 0 but dont want to drop prices by changing this now (29/12/24)
      vehicleMaintenance: 0.008,
    },
  },
  {
    vehicleId: 'MOTORBIKE',
    costsPerMile: {
      petrol: 0.062,
    },
    costsPerMinute: {
      riderWage: 0.167,
      insurance: 0.095, // should be 0 but dont want to drop prices by changing this now (29/12/24)
      vehicleMaintenance: 0.008,
    },
  },
  {
    vehicleId: 'VAN_MEDIUM',
    costsPerMile: {
      petrol: 0.175,
    },
    costsPerMinute: {
      riderWage: 0.167,
      insurance: 0.028,
      vehicleMaintenance: 0.008,
    },
  },
  {
    vehicleId: 'VAN_LARGE',
    costsPerMile: {
      petrol: 0.175,
    },
    costsPerMinute: {
      riderWage: 0.167,
      insurance: 0.028,
      vehicleMaintenance: 0.008,
    },
  },
  {
    vehicleId: 'VAN_XLARGE',
    costsPerMile: {
      petrol: 0.175,
    },
    costsPerMinute: {
      riderWage: 0.167,
      insurance: 0.028,
      vehicleMaintenance: 0.008,
    },
  },
  {
    vehicleId: 'LUTON',
    costsPerMile: {
      petrol: 0.175,
    },
    costsPerMinute: {
      riderWage: 0.167,
      insurance: 0.028,
      vehicleMaintenance: 0.008,
    },
  },
  {
    vehicleId: 'CAR',
    costsPerMile: {
      petrol: 0.175,
    },
    costsPerMinute: {
      riderWage: 0.167,
      insurance: 0.028,
      vehicleMaintenance: 0.008,
    },
  },
  {
    vehicleId: 'FLATBED',
    costsPerMile: {
      petrol: 0.175,
    },
    costsPerMinute: {
      riderWage: 0.167,
      insurance: 0.028,
      vehicleMaintenance: 0.008,
    },
  },
  {
    vehicleId: 'HIAB',
    costsPerMile: {
      petrol: 0.175,
    },
    costsPerMinute: {
      riderWage: 0.167,
      insurance: 0.028,
      vehicleMaintenance: 0.008,
    },
  },
];

/**
 * Utils
 */
const getMultiplerByOptionType = (optionTypeId: DeliveryOptionTypeId) => {
  switch (optionTypeId) {
    case 'MORNING':
      return MORNING_MULTIPLIER;
    case 'PRIORITY':
      return PRIORITY_MULTIPLIER;
    case 'DAY':
      return DAY_MULTIPLIER;
    case 'EVENING':
      return EVENING_MULTIPLIER;
    case 'SCHEDULED':
      return SCHEDULED_MULTIPLIER;
    case 'THREE_HOUR':
      return THREE_HOUR_MULTIPLIER;
  }
};

const getFixedChargeByOptionType = (optionTypeId: DeliveryOptionTypeId) => {
  switch (optionTypeId) {
    case 'MORNING':
      return MORNING_FIXED_CHARGE;
    default:
      return null;
  }
};

export const getOptionPricing = (vehicleId: DeliveryVehicleId, optionTypeId: DeliveryOptionTypeId) => {
  const vehicle = getVehicleConfig(vehicleId);

  const multiplier = getMultiplerByOptionType(optionTypeId);

  const fixedCharge = getFixedChargeByOptionType(optionTypeId);

  const minimum = vehicle.minimumTotalPrice * multiplier;
  const minimumWithoutServiceCharge = minimum - SERVICE_CHARGE;
  const minimumToNearest50p = Math.round(minimumWithoutServiceCharge * 2) / 2;
  
  const totalMultiplier = vehicle.pricingMultiplier * multiplier;

  return {
    multiplier: totalMultiplier,
    minimum: minimumToNearest50p,
    serviceCharge: SERVICE_CHARGE,
    fixedCharge: fixedCharge,
  };
};

export const getVehicleCosts = (vehicleId: DeliveryVehicleId) => {
  const costs = VEHICLE_COSTS.find((config) => config.vehicleId === vehicleId);
  if (!costs) {
    throw new Error(`Vehicle costs not found for vehicleId: ${vehicleId}`);
  }
  return costs;
};
