import { z } from 'zod';
import { HydratedRiderSchema } from '../../../models';
import { processStringBoolean } from '../../../utils';

export const GetRiderByIdQuerySchema = z.object({
  includeDeleted: z.preprocess(processStringBoolean, z.boolean()).optional(),
  includeBankDetails: z.preprocess(processStringBoolean, z.boolean()).optional(),
});

export const GetRiderByIdResponseSchema = HydratedRiderSchema;

export type GetRiderByIdResponse = z.infer<typeof GetRiderByIdResponseSchema>;
export type GetRiderByIdQuery = z.infer<typeof GetRiderByIdQuerySchema>;
