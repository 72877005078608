import { Box, HStack, Show, Stack } from '@chakra-ui/react';
import { MerchantAccount } from '@tradeaze-packages/schemas';
import { OrderQueryStore } from './useOrderQueryStore';
import { OrderQueryOptionsDrawer } from './OrderQueryOptionsDrawer';
import { ShowCompletedButton } from './ShowCompletedButton';
import { ResetFiltersButton } from './ResetFiltersButton';
import { OrderPriorityFilters } from './OrderPriorityFilters';
import { OrderQueryOptionsBarContainer } from './OrderQueryOptionsBarContainer';
import { Z_INDEX } from '../../constants/zIndex';
import { NAVBAR_WIDTH_PX } from '../../navbar';

interface OrderDashboardControlsProps {
  orderQueryStore: OrderQueryStore;
  isAdmin: boolean;
  merchantAccount?: MerchantAccount;
}

export const OrderDashboardControls: React.FC<OrderDashboardControlsProps> = ({
  orderQueryStore,
  isAdmin,
  merchantAccount,
}) => {
  const isDefaultFilters = orderQueryStore.isDefaultFilters();

  return (
    <>
      <Show above="md">
        <OrderQueryOptionsBarContainer
          justifyContent={isAdmin ? 'space-between' : 'flex-start'}
        >
          {isAdmin && (
            <Box>
              <OrderPriorityFilters
                deliveryToday={orderQueryStore.deliveryToday ?? false}
                isUnassigned={orderQueryStore.isUnassigned}
                isOverRunning={orderQueryStore.isOverRunning}
                isOnJobBoard={orderQueryStore.isOnJobBoard}
                handleToggleUnassigned={orderQueryStore.handleToggleUnassigned}
                handleToggleOverRunning={
                  orderQueryStore.handleToggleOverRunning
                }
                handleToggleOnJobBoard={orderQueryStore.handleToggleOnJobBoard}
              />
            </Box>
          )}
          <HStack>
            <OrderQueryOptionsDrawer
              orderQueryStore={orderQueryStore}
              isAdmin={isAdmin}
              merchantAccount={merchantAccount}
              buttonProps={{
                size: 'sm',
                mr: 1,
              }}
            />
            {!isDefaultFilters && (
              <ResetFiltersButton
                onReset={orderQueryStore.handleResetFilters}
                buttonProps={{
                  size: 'sm',
                }}
              />
            )}
            <ShowCompletedButton
              handleToggleShowCompleted={
                orderQueryStore.handleToggleShowCompleted
              }
              showCompleted={orderQueryStore.showCompleted}
            />
          </HStack>
        </OrderQueryOptionsBarContainer>
      </Show>

      <Show below="md">
        {isAdmin && (
          //   <OrderQueryOptionsBarContainer>
          //     <OrderPriorityFilters
          //       deliveryToday={orderQueryStore.deliveryToday ?? false}
          //       isUnassigned={orderQueryStore.isUnassigned}
          //       isOverRunning={orderQueryStore.isOverRunning}
          //       isOnJobBoard={orderQueryStore.isOnJobBoard}
          //       handleToggleUnassigned={orderQueryStore.handleToggleUnassigned}
          //       handleToggleOverRunning={orderQueryStore.handleToggleOverRunning}
          //       handleToggleOnJobBoard={orderQueryStore.handleToggleOnJobBoard}
          //     />
          //   </OrderQueryOptionsBarContainer>
          <Box
            position="fixed"
            top={NAVBAR_WIDTH_PX}
            left={0}
            right={0}
            bg="rgba(255, 255, 255, 0.9)"
            backdropFilter="blur(8px)"
            borderBottom="1px solid"
            borderColor="gray.100"
            zIndex={Z_INDEX.ORDER_DASHBOARD_CONTROLS}
            px={2}
            overflowX="auto"
            whiteSpace="nowrap"
            py={2}
            css={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <OrderPriorityFilters
              deliveryToday={orderQueryStore.deliveryToday ?? false}
              isUnassigned={orderQueryStore.isUnassigned}
              isOverRunning={orderQueryStore.isOverRunning}
              isOnJobBoard={orderQueryStore.isOnJobBoard}
              handleToggleUnassigned={orderQueryStore.handleToggleUnassigned}
              handleToggleOverRunning={orderQueryStore.handleToggleOverRunning}
              handleToggleOnJobBoard={orderQueryStore.handleToggleOnJobBoard}
            />
          </Box>
        )}
        <Stack
          position={'fixed'}
          left={0}
          bottom={0}
          direction={'row'}
          maxWidth={'100vw'}
          p={2}
          zIndex={Z_INDEX.ORDER_DASHBOARD_CONTROLS}
          spacing={2}
          flexWrap={'wrap'}
        >
          <OrderQueryOptionsDrawer
            orderQueryStore={orderQueryStore}
            isAdmin={isAdmin}
            merchantAccount={merchantAccount}
            buttonProps={{
              size: 'sm',
              mr: 1,
            }}
          />
          {!isDefaultFilters && (
            <ResetFiltersButton
              onReset={orderQueryStore.handleResetFilters}
              buttonProps={{
                size: 'sm',
              }}
            />
          )}
          <ShowCompletedButton
            handleToggleShowCompleted={
              orderQueryStore.handleToggleShowCompleted
            }
            showCompleted={orderQueryStore.showCompleted}
            buttonProps={{
              size: 'sm',
            }}
          />
        </Stack>
      </Show>
    </>
  );
};
