import React from 'react';

export const CarSideIcon: React.FC<{
  width?: number;
  height?: number;
  xArea?: number;
  yArea?: number;
  xPosition?: string;
  yPosition?: string;
}> = ({ height, width, xArea, xPosition, yArea, yPosition }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="30 0 380 212"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="50" y="100" fill="#232324" fontSize="14" fontFamily="Arial">
        Height
      </text>
      <text x="175" y="10" fill="#232324" fontSize="14" fontFamily="Arial">
        Length
      </text>
      <path
        d="M339.1 102.571H332.95L291.138 52.793C288.832 50.0496 285.909 47.835 282.584 46.313C279.258 44.7909 275.616 44.0004 271.927 44H189.705C179.646 44 170.602 49.8315 166.865 58.7271L148.45 103.399C137.857 106.012 130 115.095 130 126V167C130 170.236 132.752 172.857 136.15 172.857H154.6C154.6 192.266 171.12 208 191.5 208C211.88 208 228.4 192.266 228.4 172.857H277.6C277.6 192.266 294.12 208 314.5 208C334.88 208 351.4 192.266 351.4 172.857H369.85C373.248 172.857 376 170.236 376 167V137.714C376 118.305 359.48 102.571 339.1 102.571ZM191.5 190.429C181.326 190.429 173.05 182.547 173.05 172.857C173.05 163.167 181.326 155.286 191.5 155.286C201.674 155.286 209.95 163.167 209.95 172.857C209.95 182.547 201.674 190.429 191.5 190.429ZM314.5 190.429C304.326 190.429 296.05 182.547 296.05 172.857C296.05 163.167 304.326 155.286 314.5 155.286C324.674 155.286 332.95 163.167 332.95 172.857C332.95 182.547 324.674 190.429 314.5 190.429Z"
        stroke="#A7A7A8"
        stroke-width="7"
      />
      <rect x="127" y="44" width="138" height="98" rx="12" fill="#F9F9F9" />
      <rect
        x="127"
        y="44"
        width="138"
        height="98"
        rx="12"
        stroke="#A7A7A8"
        stroke-width="8"
      />
      <rect x="139" y="56" width="114" height="74" rx="4" fill="#D5D5D5" />
      <rect
        x={xPosition}
        y={yPosition}
        width={xArea}
        height={yArea}
        rx="4"
        fill="#AF9032"
      />
      <path
        d="M107.5 53L109.887 48L104.113 48L106.5 53L107.5 53ZM106.5 133L104.113 138L109.887 138L107.5 133L106.5 133ZM106.5 52.5L106.5 133.5L107.5 133.5L107.5 52.5L106.5 52.5Z"
        fill="#AF9032"
      />
      <path
        d="M256 24.5L261 26.8868V21.1132L256 23.5V24.5ZM136 23.5L131 21.1132V26.8868L136 24.5V23.5ZM256.5 23.5L135.5 23.5V24.5L256.5 24.5V23.5Z"
        fill="#AF9032"
      />
    </svg>
  );
};
