import { z } from 'zod';

export const NashErrorDataSchema = z.object({
  error: z.object({
    code: z.string(),
    message: z.string(),
  }),
  response_status: z.string(),
  RequestID: z.string(),
});

export const buildAxiosErrorSchema = <T extends z.ZodRawShape>(
  errorDataSchema: z.ZodObject<T>
)=>
  z.object({
    response: z.object({
      data: errorDataSchema,
      status: z.number(),
    }),
  });

const DefaultErrorDataSchema = z.object({
  error: z.object({
    message: z.string(),
  }),
});

export const axiosErrorSchema = buildAxiosErrorSchema(DefaultErrorDataSchema);

export const nashErrorSchema = buildAxiosErrorSchema(NashErrorDataSchema);
