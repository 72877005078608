import { z } from 'zod';
import { DeliveriesQueryParamsSchema } from '../../../models';
import { MerchantDeliveriesCsvColumns } from './download-merchant-deliveries-csv-schema';

const CsvTypeSchema = z.enum(['ADMIN', 'MERCHANT']);

export const DownloadAdminDeliveriesCsvQuerySchema =
  DeliveriesQueryParamsSchema.omit({
    startIndex: true,
    limit: true,
  }).extend({
    csvType: CsvTypeSchema,
  });

export type DownloadAdminDeliveriesCsvQuery = z.infer<
  typeof DownloadAdminDeliveriesCsvQuerySchema
>;

export const DownloadAdminDeliveriesCsvResponseSchema = z.object({
  url: z.string(),
});

export type DownloadAdminDeliveriesCsvResponse = z.infer<
  typeof DownloadAdminDeliveriesCsvResponseSchema
>;

export type AdminDeliveriesCsvColumns = MerchantDeliveriesCsvColumns & {
  'Merchant ID': string;
  'Invoicing Name': string;
  'Invoicing Email': string;
};
