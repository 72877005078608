import { AnimatedImage } from '../shared/AnimatedImage';
import Svg from './assets/Construction-truck-pana.svg';

interface CreateOrderImageProps {
  animationDelay?: number;
}

export const CreateOrderImage = ({ animationDelay }: CreateOrderImageProps) => {
  return (
    <AnimatedImage
      src={Svg}
      alt="Create Order"
      position="absolute"
      bottom="0"
      right="0"
      height="100%"
      maxWidth="70%"
      objectFit="contain"
      animationDelay={animationDelay}
    />
  );
};
