import {
  CreateOrder,
  ExistingOrder,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import { FormProvider } from 'react-hook-form';
import { useMultiDropForm } from '../../hooks';
import { PostHog } from 'posthog-js';
import { AddMarkerFn, RemoveMarkerFn } from '../../../map';
import {
  BookingInformationSection,
  CompleteOrderButton,
  DeliveryDetailsSection,
  InvoicingSection,
  PickupSection,
} from '../../order-form-sections';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { MultiDropAddDelivery } from './MultiDropAddDelivery';
import { OrderStopCard } from '../../order-stop-card';
import { OptimisedDropOffsAlert } from './OptimizedDropOffCount';

type MultiDropOrderFormProps = {
  merchantAccount?: MerchantAccount;
  isAdmin: boolean;
  posthog?: PostHog;
  onSubmit: (order: CreateOrder) => void;
  setShowMainBackButton?: (value: boolean) => void;
  addPickupMarker: AddMarkerFn;
  removePickupMarker: RemoveMarkerFn;
  addDeliveryMarker: AddMarkerFn;
  removeDeliveryMarker: RemoveMarkerFn;
  existingOrder?: ExistingOrder;
  isLoading: boolean;
};

export const MultiDropOrderForm = ({
  merchantAccount,
  isAdmin,
  posthog,
  onSubmit,
  setShowMainBackButton,
  addPickupMarker,
  removePickupMarker,
  addDeliveryMarker,
  removeDeliveryMarker,
  existingOrder,
  isLoading,
}: MultiDropOrderFormProps) => {
  const {
    form,
    isInvoicingComplete,
    isPickupComplete,
    pickupForm,
    showDropOffSection,
    handleAddDeliveryButton,
    handleCreateBackButton,
    isDropOffComplete,
    dropOffForm,
    isItemDetailsComplete,
    itemForm,
    handleAddDelivery,
    multiDropDetails,
    selectedDropOffIndex,
    handleUpdateDelivery,
    handleEditBackButton,
    handleOrderCardSelect,
    handleRemoveOrderCard,
    existingDropOffs,
    hidePriorityDelivery,
    thresholdDistances,
    isDeliveryPriceLoading,
    deliveryPriceErrorMessage,
    itemDetails,
    isVehicleDetailsComplete,
    isBookingInformationComplete,
    handleSubmit,
    isDisableDropOffCollectionReference,
    pickupReference,
    formattedPickupAddress,
    currentOrder,
    deliveryCount,
    restrictedEditing,
    addDropHeaderRef,
    addDropButtonRef,
  } = useMultiDropForm({
    merchantAccount,
    isAdmin,
    posthog,
    onSubmit,
    setShowMainBackButton,
    addPickupMarker,
    removePickupMarker,
    addDeliveryMarker,
    removeDeliveryMarker,
    existingOrder,
  });
  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" gap={10}>
        {selectedDropOffIndex === null && showDropOffSection ? (
          <HStack alignItems={'center'} ref={addDropHeaderRef}>
            <Button
              mr="5px"
              variant={'ghost'}
              colorScheme={'gray'}
              onClick={handleCreateBackButton}
              data-cy={'add-drop-back-button'}
            >
              <ChevronLeftIcon fontSize={32} />
            </Button>
            <Text fontSize="xl" fontWeight={600}>
              Add Drop {existingDropOffs + 1}
            </Text>
          </HStack>
        ) : null}
        {selectedDropOffIndex !== null && showDropOffSection ? (
          <HStack alignItems={'center'} ref={addDropHeaderRef}>
            <ChevronLeftIcon
              boxSize={10}
              onClick={handleEditBackButton}
              cursor="pointer"
              data-cy={'edit-drop-back-button'}
            />
            <Text fontSize="xl" fontWeight={600}>
              Edit Multi-Drop Drop {selectedDropOffIndex + 1}
            </Text>
          </HStack>
        ) : null}
        {!merchantAccount && !showDropOffSection && (
          <InvoicingSection
            form={form}
            isSectionComplete={isInvoicingComplete}
          />
        )}
        <Box display={showDropOffSection ? 'none' : 'block'}>
          <PickupSection
            sectionName={'Pick Up'}
            defaultPickupAddress={merchantAccount?.address}
            defaultPickupPostcode={merchantAccount?.postCode}
            defaultPickupPosition={merchantAccount?.position}
            defaultPickupCity={merchantAccount?.city}
            merchantAccount={merchantAccount}
            showExpanded={Boolean(existingOrder)}
            form={pickupForm}
            isSectionComplete={isPickupComplete}
            addMarker={addPickupMarker}
            removeMarker={removePickupMarker}
            isSecondPickup={false}
            restrictedEditing={restrictedEditing}
            cypressTestId={'pickup-section'}
            stopId={1}
            isMultiDrop={true}
          />
        </Box>
        {!showDropOffSection &&
          multiDropDetails?.dropOffs?.map((dropOff, index) => (
            <OrderStopCard
              key={index}
              title={`Drop Off ${index + 1}`}
              address={`${dropOff.addressLine1}, ${dropOff.postCode}`}
              companyName={dropOff.companyName}
              itemSize={dropOff.deliveryItems ?? []}
              onClick={() => handleOrderCardSelect(index)}
              onRemove={() =>
                handleRemoveOrderCard(index, dropOff.stopSequence)
              }
              isCancelled={
                dropOff.status === 'CANCELLED' || dropOff.status === 'REJECTED'
              }
              isDelivered={dropOff.status === 'DELIVERED'}
              isAdmin={isAdmin}
              restrictedEditing={restrictedEditing}
              pickupReference={dropOff.collectionReference ?? ''}
            />
          ))}
        {!showDropOffSection && !restrictedEditing && (
          <Box >
            <OptimisedDropOffsAlert />
            <Button
              data-cy={'add-drop-button'}
              onClick={handleAddDeliveryButton}
              width={'100%'}
              ref={addDropButtonRef}
            >
              Add Drop {deliveryCount}
            </Button>
          </Box>
        )}
        {showDropOffSection && (
          <MultiDropAddDelivery
            merchantAccount={merchantAccount}
            dropOffForm={dropOffForm}
            isDropOffComplete={isDropOffComplete}
            addDeliveryMarker={addDeliveryMarker}
            removeDeliveryMarker={removeDeliveryMarker}
            showExpanded={selectedDropOffIndex !== null}
            restrictedEditing={Boolean(restrictedEditing)}
            itemForm={itemForm}
            isItemDetailsComplete={isItemDetailsComplete}
            handleAddDelivery={
              selectedDropOffIndex !== null
                ? handleUpdateDelivery
                : handleAddDelivery
            }
            buttonLabel={
              selectedDropOffIndex !== null
                ? `Update Drop ${selectedDropOffIndex + 1}`
                : `Add Drop ${deliveryCount}`
            }
            deliveryCount={
              selectedDropOffIndex !== null
                ? selectedDropOffIndex + 1
                : deliveryCount
            }
            isDisableDropOffCollectionReference={
              isDisableDropOffCollectionReference
            }
            pickupReference={pickupReference}
            formattedPickupAddress={formattedPickupAddress}
            stopId={
              selectedDropOffIndex !== null
                ? selectedDropOffIndex + 2
                : deliveryCount + 1
            }
          />
        )}
        {!showDropOffSection && multiDropDetails.dropOffs?.length >= 2 && (
          <>
            <DeliveryDetailsSection
              sectionName="Delivery Details"
              merchantAccount={merchantAccount}
              initialDate={existingOrder?.orderDate}
              hidePriorityDelivery={hidePriorityDelivery}
              isAdmin={isAdmin}
              isAnyStopOverThreshold={thresholdDistances.isAnyStopOverThreshold}
              isDeliveryPriceLoading={isDeliveryPriceLoading}
              deliveryPriceErrorMessage={deliveryPriceErrorMessage}
              itemDetails={itemDetails}
              isSectionComplete={isVehicleDetailsComplete}
              hideSection={restrictedEditing}
              isMultiDrop={true}
            />
            <BookingInformationSection
              sectionName="Booking Information"
              isSectionComplete={isBookingInformationComplete}
            />
            <CompleteOrderButton
              isLoading={isLoading}
              handleSubmit={handleSubmit}
              isUpdatingOrder={Boolean(existingOrder)}
              currentOrder={currentOrder}
              label={
                existingOrder
                  ? 'Update Multi-Drop Order'
                  : 'Create Multi-Drop Order'
              }
              orderType="MULTI_DROP"
            />
          </>
        )}
      </Box>
    </FormProvider>
  );
};
