import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(
    async (keys: QueryKey[]) => {
      for (const k of keys) {
        await queryClient.invalidateQueries(k, {
          // e.g. ['getAddresses'] will also invalidate ['getAddresses', 'id-123']
          exact: false,
          // only refetch queries that are actively being rendered
          refetchType: 'active',
        });
      }
    },
    [queryClient],
  );

  return invalidateQueries;
};
