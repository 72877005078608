import { AnimatedImage } from '../shared/AnimatedImage';
import Svg from './assets/Construction-truck-rafiki.svg';

interface OrdersImageProps {
  animationDelay?: number;
}

export const OrdersImage = ({ animationDelay }: OrdersImageProps) => {
  return (
    <AnimatedImage
      src={Svg}
      alt="Orders"
      position="absolute"
      bottom="0"
      right="0"
      height="100%"
      maxWidth="70%"
      objectFit="contain"
      animationDelay={animationDelay}
    />
  );
};
