import { z } from 'zod';

export const RiderDocumentTypeIdSchema = z.enum([
  'DRIVERS_LICENSE_FRONT',
  'DRIVERS_LICENSE_BACK',
  'RIGHT_TO_WORK',
  'CBT',
  'GOODS_IN_TRANSIT_INSURANCE',
  'HIRE_AND_REWARD_INSURANCE',
  'PROOF_OF_ADDRESS',
]);

export const RiderDocumentTypeSchema = z.object({
  id: RiderDocumentTypeIdSchema,
  createdAt: z.string(),
  updatedAt: z.string(),
  name: z.string().min(1, { message: 'Document type name is required' }),
  description: z.string().nullish(),
});

export const RiderDocumentSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  riderId: z.string(),
  documentTypeId: RiderDocumentTypeIdSchema,
  isRequired: z.boolean(),
  documentKey: z.string().nullish(),
  uploadedAt: z.string().nullish(),
});

export type RiderDocumentTypeId = z.infer<typeof RiderDocumentTypeIdSchema>;
export type RiderDocumentType = z.infer<typeof RiderDocumentTypeSchema>;
export type RiderDocument = z.infer<typeof RiderDocumentSchema>;