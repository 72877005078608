import { z } from 'zod';

export const SuccessfulQuoteSchema = z.object({
  quoteId: z.string(),
  providerId: z.string(),
  providerName: z.string(),
  providerLogo: z.string().nullish(),
  dropoffEta: z.string().nullable(),
  totalPrice: z.number(),
  currency: z.string(),
});

export const FailedQuoteSchema = z.object({
  providerId: z.string(),
  providerName: z.string(),
  errorMessage: z.string().nullable(),
});

export type SuccessfulQuote = z.infer<typeof SuccessfulQuoteSchema>;
export type FailedQuote = z.infer<typeof FailedQuoteSchema>;
