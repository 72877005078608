import { Address } from '@tradeaze-packages/schemas';

export const formatAddressDisplayName = (
  address: Pick<
    Address,
    | 'addressLine1'
    | 'addressLine2'
    | 'addressLine3'
    | 'companyName'
    | 'postCode'
    | 'city'
  >,
  options: {
    includeCompanyName?: boolean;
    includePostCode?: boolean;
  } = {
    includeCompanyName: true,
    includePostCode: true,
  }
) => {
  const addressComponents = [];

  if (address.companyName && options.includeCompanyName) {
    addressComponents.push(`${address.companyName}`);
  }

  if (address.addressLine1) {
    addressComponents.push(address.addressLine1);
  }

  if (address.addressLine2) {
    addressComponents.push(address.addressLine2);
  }

  if (address.addressLine3) {
    addressComponents.push(address.addressLine3);
  }
  
  if (address.city) {
    addressComponents.push(address.city);
  }
  
  if (address.postCode && options.includePostCode) {
    addressComponents.push(address.postCode);
  }


  return addressComponents.join(', ');
};
