import { HydratedDelivery } from '@tradeaze-packages/schemas';

export const checkDeliveriesForEtaPastWindow = (
  deliveries: Pick<HydratedDelivery, 'dropOff'>[],
): boolean => {
  return deliveries.some((delivery) => {
    const dropOff = delivery.dropOff;

    if (!dropOff?.etaStart || !dropOff?.windowEnd) {
      return false;
    }

    return new Date(dropOff.etaStart) > new Date(dropOff.windowEnd);
  });
};
