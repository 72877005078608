import { z } from 'zod';
import {
  GigJobSchema,
  CreateGigJobSchema,
  VehicleCategoryIdSchema,
  DeliveryVehicleIdSchema,
  GigJobBoardSchema,
} from '../../../models';

export const CreateGigJobBodySchema = CreateGigJobSchema.extend({
  deliveryVehicleIds: z.array(DeliveryVehicleIdSchema).optional(), // todo - deprecate
  vehicleCategoryIds: z.array(VehicleCategoryIdSchema).optional(),
})

export const CreateGigJobResponseSchema = GigJobSchema.extend({
  deliveryIds: z.array(z.string()),
  gigJobBoard: z.array(GigJobBoardSchema),
});

export type MakeGigJob = z.infer<typeof CreateGigJobSchema>;

export type CreateGigJobBody = z.infer<typeof CreateGigJobBodySchema>;

export type CreateGigJobResponse = z.infer<typeof CreateGigJobResponseSchema>;
