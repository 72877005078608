import { z } from 'zod';
import { DeliveryVehicleIdSchema, DeliveryVehicleSchema } from './delivery-vehicle-schema';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';

extendZodWithOpenApi(z);

export const DeliveryOptionTypeIdSchema = z.enum([
  'PRIORITY',
  'DAY',
  'MORNING',
  'EVENING',
  'SCHEDULED',
  'THREE_HOUR'
]);

export const DeprecatedDeliveryOptionIdSchema = z.enum([
  'VAN_PRIORITY',
  'VAN_DAY',
  'VAN_MORNING',
  'VAN_EVENING',
  'BIKE_DIRECT',
  'BIKE_ONE_HOUR',
  'ONE_HOUR',
  'THREE_HOUR',
  'ANYTIME_TODAY',
  'SCHEDULED',
  'AM',
  'PM',
  'DAY',
]);

export const CurrentDeliveryOptionIdSchema = z
  .enum([
    'BIKE_PRIORITY',
    'BIKE_THREE_HOUR',
    'BIKE_SCHEDULED',
    'BIKE_DAY',
    'CAR_PRIORITY',
    'CAR_DAY',
    'CAR_MORNING',
    'CAR_EVENING',
    'VAN_MEDIUM_PRIORITY',
    'VAN_MEDIUM_DAY',
    'VAN_MEDIUM_MORNING',
    'VAN_MEDIUM_EVENING',
    'VAN_LARGE_PRIORITY',
    'VAN_LARGE_DAY',
    'VAN_LARGE_MORNING',
    'VAN_LARGE_EVENING',
    'VAN_XLARGE_PRIORITY',
    'VAN_XLARGE_DAY',
    'VAN_XLARGE_MORNING',
    'VAN_XLARGE_EVENING',
    'LUTON_PRIORITY',
    'LUTON_DAY',
    'LUTON_MORNING',
    'LUTON_EVENING',
    'FLATBED_PRIORITY',
    'FLATBED_DAY',
    'FLATBED_MORNING',
    'FLATBED_EVENING',
    'HIAB_DAY',
  ])
  .openapi('DeliveryOptionId');

export const DeliveryOptionIdSchema = z.union([
  CurrentDeliveryOptionIdSchema,
  DeprecatedDeliveryOptionIdSchema,
]);

export const DeliveryOptionIdWithDeprecatedSchema = z.union([
  DeliveryOptionIdSchema,
  DeprecatedDeliveryOptionIdSchema,
]);

export const CutOffTimeSchema = z.object({
  hour: z.number(),
  minute: z.number(),
});

export const DeliveryOptionSchema = z.object({
  deliveryOptionId: CurrentDeliveryOptionIdSchema,
  name: z.string(),
  deliveryVehicleId: DeliveryVehicleIdSchema,
  cutOffTime: CutOffTimeSchema,
  pricingMultiplier: z.number(),
  minimumPrice: z.number(),
  fixedCharge: z.number().nullish(),
  serviceCharge: z.number().nullish(),
  isUnavailable: z.boolean().optional(), // manually set by admin
  isPastCutOff: z.boolean().optional(),
  isAvailable: z.boolean().optional(),
});

export const DeliveryOptionTypeSchema = z.object({
  id: DeliveryOptionTypeIdSchema,
  name: z.string(),
  description: z.string(),
});

export const ClientDeliveryOptionSchema = DeliveryOptionSchema.omit({
  pricingMultiplier: true,
  minimumPrice: true,
  fixedCharge: true,
});

export const ClientDeliveryOptionWithIsAvailableSchema = ClientDeliveryOptionSchema.extend({
  isAvailable: z.boolean(),
});

export const GroupedOptionsByVehicleSchema = z.record(
  DeliveryVehicleIdSchema,
  z.object({
    options: z.array(ClientDeliveryOptionWithIsAvailableSchema),
    vehicle: DeliveryVehicleSchema,
  }),
);

export const CreateDeliveryOptionSchema = DeliveryOptionSchema.omit({
  cutOffTime: true,
  isPastCutOff: true,
  isUnavailable: true,
}); 

export type DeliveryOptionTypeId = z.infer<typeof DeliveryOptionTypeIdSchema>;
export type DeliveryOptionType = z.infer<typeof DeliveryOptionTypeSchema>;

export type DeprecatedDeliveryOptionId = z.infer<
  typeof DeprecatedDeliveryOptionIdSchema
>;
export type CurrentDeliveryOptionId = z.infer<
  typeof CurrentDeliveryOptionIdSchema
>;
export type DeliveryOptionId = z.infer<
  typeof DeliveryOptionIdWithDeprecatedSchema
>;
export type DeliveryOption = z.infer<typeof DeliveryOptionSchema>;
export type ClientDeliveryOption = z.infer<typeof ClientDeliveryOptionSchema>;
export type ClientDeliveryOptionWithIsAvailable = z.infer<
  typeof ClientDeliveryOptionWithIsAvailableSchema
>;
export type CutOffTime = z.infer<typeof CutOffTimeSchema>;
export type CreateDeliveryOption = z.infer<typeof CreateDeliveryOptionSchema>;
export type GroupedOptionsByVehicle = z.infer<typeof GroupedOptionsByVehicleSchema>;