import React, { createContext, useContext } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { usePostHog } from 'posthog-js/react';
import { captureEvent } from '@tradeaze/frontend/utils';
import { DeliveryPricingModal } from '@tradeaze/frontend/ui-web';
import { useGetMerchantAccount } from '@tradeaze/frontend/hooks';
import { useAuthContext } from '../auth/AuthProvider';

type PricingCalculatorContextType = {
  openDeliveryPricingModal: () => void;
};

const PricingCalculatorContext = createContext<PricingCalculatorContextType | undefined>(
  undefined
);

export const PricingCalculatorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const posthog = usePostHog();
  const { cognitoUserId } = useAuthContext();
  const { data: merchantAccount } = useGetMerchantAccount(cognitoUserId);
  const deliveryPricingDisclosure = useDisclosure();

  const openDeliveryPricingModal = () => {
    deliveryPricingDisclosure.onOpen();
    captureEvent(posthog)('open_pricing_modal');
  };

  const hidePriorityDelivery = merchantAccount?.featureFlags?.hidePriorityDelivery || false;
  const allowLongDistance = merchantAccount?.featureFlags?.allowLongDistance || false;

  return (
    <PricingCalculatorContext.Provider value={{ openDeliveryPricingModal }}>
      {children}
      {merchantAccount && (
        <DeliveryPricingModal
          posthog={posthog}
          isOpen={deliveryPricingDisclosure.isOpen}
          onClose={deliveryPricingDisclosure.onClose}
          defaultFromPostcode={merchantAccount.postCode}
          merchantId={merchantAccount.merchantId}
          hidePriorityDelivery={hidePriorityDelivery}
          allowLongDistance={allowLongDistance}
        />
      )}
    </PricingCalculatorContext.Provider>
  );
};

export const usePricingCalculator = () => {
  const context = useContext(PricingCalculatorContext);
  if (!context) {
    throw new Error('usePricingCalculator must be used within a PricingCalculatorProvider');
  }
  return context;
}; 