import { validatePostcode } from '@tradeaze/shared/utils';

export const postcodeValidate = (stopPostcodes: string[]): (string | null)[] => {
  return stopPostcodes.map((postcode) => {
    if (!validatePostcode(postcode)) {
      return 'Invalid UK postcode';
    }
    if (!postcode.includes(' ')) {
      return 'Postcode must include a space';
    }
    return null;
  });
};

export  const getIsValidPostcode = (postcode: string): boolean => {
  return validatePostcode(postcode) && postcode.includes(' ');
};

export const getStopTitle = (
  stopPostcodes: string[],
  index: number,
  isMultiDrop: boolean,
) => {
  const isAtoB = stopPostcodes.length === 2;
  const isFirstStop = index === 0;
  const isLastStop = index === stopPostcodes.length - 1;

  if (isFirstStop && (isMultiDrop || isAtoB)) {
    return 'Collection';
  }
  if (isLastStop && !isMultiDrop) {
    return 'Delivery';
  }
  
  return isMultiDrop ? `Delivery ${index}` : `Collection ${index + 1}`;
};
