import { useMutation } from '@tanstack/react-query';
import { DownloadAdminDeliveriesCsvQuery } from '@tradeaze-packages/schemas';
import { downloadUrl } from '@tradeaze/frontend/utils';
import { fetchAdminDeliveriesCsv } from '@tradeaze-packages/api-client';
import toast from 'react-hot-toast';

export const useDownloadAdminDeliveries = () => {
  let loadingToastId: string;

  return useMutation(
    {
      mutationFn: async (filters: DownloadAdminDeliveriesCsvQuery) => fetchAdminDeliveriesCsv(filters),
      onError() {
        toast.error('Error generating CSV');
      },
      onSuccess: async (data) => {
        toast.success('Generated CSV');
        downloadUrl(data.url, `Deliveries - ${new Date().toISOString()}.csv`);
      },
      onMutate: () => {
        loadingToastId = toast.loading('Generating CSV');
      },
      onSettled: () => {
        toast.dismiss(loadingToastId);
      },
    }
  );
};
