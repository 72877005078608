import { CreateDeliveryItem, CurrentOrder } from "@tradeaze-packages/schemas";

export const getAllItems = (order: CurrentOrder): CreateDeliveryItem[] => {
  switch (order.type) {
    case 'A_TO_B':
      return order.pickup?.deliveryItems || [];
    case 'MULTI_COLLECTION':
      return order.pickups?.flatMap((pickup) => pickup.deliveryItems) || [];
    case 'MULTI_DROP':
      return order.dropOffs?.flatMap((dropoff) => dropoff.deliveryItems) || [];
    default:
      return [];
  }
};
