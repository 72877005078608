import {
  Box,
  Divider,
  HStack,
  SimpleGrid,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { CreateDeliveryStop, CurrentOrder, OrderType } from '@tradeaze-packages/schemas';
import {
  calculateTotalWeightKg,
  formatTimeRange,
  getMaxItemDimension,
} from '@tradeaze/shared/utils';
import { DeliveryVehicleIcon } from '../../../delivery';
import { DeliveryOptionValue } from '../../../shared';
import { SummaryAddressCard } from './SummaryAddressCard';
import { SummaryDisplayField } from './SummaryDisplayField';
import { getAllItems, getDropoffs, getPickups } from './utils';
import { formatTotalPrice } from './utils/';

const AddressSection = ({
  title,
  stops,
  orderType,
}: {
  title: string;
  stops: CreateDeliveryStop[];
  orderType: OrderType;
}) => (
  <Box>
    <Text fontWeight="500" fontSize="sm" color="gray.600" mb={2}>
      {title}
    </Text>
    <VStack spacing={2} align="stretch">
      {stops.length > 0 ? (
        stops.map((stop, idx) => (
          <SummaryAddressCard
            key={idx}
            stop={stop}
            orderType={orderType}
          />
        ))
      ) : (
        <Text fontSize="sm" color="red.500">
          Incomplete
        </Text>
      )}
    </VStack>
  </Box>
);

export const OrderConfirmationSummary = ({
  currentOrder,
  orderType,
}: {
  currentOrder: CurrentOrder;
  orderType: OrderType;
}) => {
  const columns = useBreakpointValue({ base: 2, md: 3 }) || 1;
  const pickups = getPickups(currentOrder);
  const dropoffs = getDropoffs(currentOrder);
  const allItems = getAllItems(currentOrder);

  const totalWeight = allItems.length ? calculateTotalWeightKg(allItems) : null;
  const maxLength = allItems.length ? getMaxItemDimension(allItems) : null;

  const hasHeavySideItems = allItems.some((item) =>
    Boolean(item.heavySideItems),
  );

  const totalPrice =
    currentOrder.deliveryPrice && currentOrder.merchantServiceCharge
      ? formatTotalPrice({
          deliveryPrice: currentOrder.deliveryPrice,
          serviceCharge: currentOrder.merchantServiceCharge,
        })
      : undefined;

  return (
    <VStack spacing={3} align="stretch" width="100%">
      <VStack align="stretch" spacing={4}>
        <AddressSection
          title="Pickup(s)"
          stops={pickups}
          orderType={orderType}
        />
        <AddressSection
          title="Drop off(s)"
          stops={dropoffs}
          orderType={orderType}
        />
      </VStack>

      <Divider />

      <SimpleGrid columns={columns} spacing={4}>
        <SummaryDisplayField
          label="Delivery Window"
          value={
            currentOrder.deliveryWindowStart && currentOrder.deliveryWindowEnd
              ? formatTimeRange({
                  from: currentOrder.deliveryWindowStart,
                  to: currentOrder.deliveryWindowEnd,
                })
              : undefined
          }
        />

        <SummaryDisplayField
          label="Delivery Option"
          value={
            currentOrder.deliveryVehicle && currentOrder.deliveryOption ? (
              <HStack>
                <DeliveryVehicleIcon
                  fontSize={'sm'}
                  deliveryVehicle={currentOrder.deliveryVehicle}
                />
                <DeliveryOptionValue
                  deliveryOption={currentOrder.deliveryOption}
                />
              </HStack>
            ) : undefined
          }
        />

        <SummaryDisplayField
          label="Max Dimension"
          value={maxLength ? maxLength : undefined}
          suffix="m"
        />

        <SummaryDisplayField
          label="Total Weight"
          value={totalWeight ? totalWeight : undefined}
          suffix="kg"
        />

        <SummaryDisplayField
          label="Type of Items"
          value={hasHeavySideItems ? 'Heavy-Side Items' : 'Shop Items'}
        />

        <SummaryDisplayField label="Booked By" value={currentOrder.bookedBy} />

        <SummaryDisplayField
          label="PO Reference"
          value={currentOrder.merchantOrderReference}
        />

        <SummaryDisplayField label="Total (exc. VAT)" value={totalPrice} />
      </SimpleGrid>
    </VStack>
  );
};
