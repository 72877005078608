import { Stack, Text, HStack, Icon } from '@chakra-ui/react';
import { Company } from '@tradeaze-packages/schemas';
import { useNavigate } from 'react-router-dom';
import { useDeleteMerchantCompany } from '@tradeaze/frontend/hooks';
import { BaseCard } from '../shared';
import { FaBuilding } from 'react-icons/fa';
import { DeleteButton } from './DeleteButton';

interface CustomerCompanyCardProps {
  customer: Company;
  merchantId: string;
}

export const CustomerCompanyCard = ({ merchantId, customer }: CustomerCompanyCardProps) => {
  const navigate = useNavigate();

  const { mutate: deleteMerchantCustomer, isLoading } = useDeleteMerchantCompany({
    invalidateQueryKeys: [['getCompaniesByMerchant', merchantId]],
  });

  const deleteCustomer = (companyId: string) => {
    deleteMerchantCustomer({ merchantId, companyId });
  };

  const handleCardClick = () => {
    navigate(`/address-book/company/${customer.companyId}`);
  };

  return (
    <BaseCard isClickable onClick={handleCardClick}>
      <Stack spacing={2}>
        <HStack spacing={2} alignItems="center">
          <Icon as={FaBuilding} color="gray.500" boxSize={4} />
          <Text fontSize="md" fontWeight="medium" color="gray.700" data-cy="customer-name">
            {customer.companyName}
          </Text>
        </HStack>
        <DeleteButton
          title="Delete Customer"
          description="Are you sure you want to delete this customer?"
          onConfirm={() => deleteCustomer(customer.companyId)}
          isLoading={isLoading}
          dataCy="delete-customer"
        />
      </Stack>
    </BaseCard>
  );
}; 