import { AnimatedImage } from '../shared/AnimatedImage';
import Svg from './assets/Calculator-rafiki.svg';

interface CalculatorImageProps {
    animationDelay?: number;
}

export const CalculatorImage = ({ animationDelay }: CalculatorImageProps) => {
    return (
        <AnimatedImage
            src={Svg}
            alt="Calculator"
            position="absolute"
            bottom="0"
            right="0"
            height="100%"
            maxWidth="70%"
            objectFit="contain"
            animationDelay={animationDelay}
        />
    );
};
