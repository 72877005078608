import { UseFormReturn } from 'react-hook-form';
import {
  CreateDeliveryStop,
  DeliveryStopContact,
} from '@tradeaze-packages/schemas';

type DropOffDetails = {
  addressLine1: string;
  postCode: string;
  companyName?: string | null;
  contacts: DeliveryStopContact[];
  city?: string | null;
};

type Form = UseFormReturn<CreateDeliveryStop>;

export const isDropOffSectionComplete = (
  dropOffDetails: DropOffDetails,
  dropOffForm: Form,
): boolean => {
  return (
    Boolean(dropOffDetails.postCode) &&
    Boolean(dropOffDetails.addressLine1) &&
    Boolean(dropOffDetails.city) &&
    Boolean(dropOffDetails.companyName) &&
    Boolean(dropOffDetails.contacts?.length) &&
    dropOffDetails.contacts.every(
      (contact) => contact.contactName && contact.contactNumber,
    ) &&
    dropOffForm.formState?.errors?.contacts?.length === undefined &&
    !dropOffForm.getFieldState('addressLine1').invalid &&
    !dropOffForm.getFieldState('city').invalid &&
    !dropOffForm.getFieldState('postCode').invalid &&
    !dropOffForm.getFieldState('companyName').invalid
  );
};
