import { QueryKey, useQuery } from '@tanstack/react-query';
import { getDelivery } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared';

export const getDeliveryQueryKey = (deliveryId?: string): QueryKey => {
  const key = ['getDelivery'];

  if (deliveryId) {
    key.push(deliveryId);
  }

  return key;
};

export const useGetDelivery = (
  deliveryId: string | undefined,
  options?: CustomUseQueryOptions<typeof getDelivery>,
) =>
  useQuery(
    {
      queryKey: getDeliveryQueryKey(deliveryId),
      queryFn: () => getDelivery(deliveryId),
      enabled: !!deliveryId,
      ...options,
    },
  );