import {
  ExternalJobStatusSchema,
  HydratedDelivery,
} from '@tradeaze-packages/schemas';
import { useNavigate } from 'react-router-dom';
import { buildNotesPreview, getHasNotes } from '../utils';
import { isSameDay } from 'date-fns';
import { usePostHog } from 'posthog-js/react';
import {
  buildStatusDescription,
  getDescriptionColorScheme,
  getProgressColorScheme,
} from '../../utils';
import { captureEvent } from '@tradeaze/frontend/utils';
import { useMemo } from 'react';

const checkDropOffEtaPastWindow = (
  delivery: Pick<HydratedDelivery, 'dropOff'>,
): boolean => {
  const dropOff = delivery.dropOff;
  return Boolean(
    dropOff.etaStart &&
      dropOff.windowEnd &&
      new Date(dropOff.etaStart) > new Date(dropOff.windowEnd),
  );
};

export const useDeliveryCardData = (
  delivery: HydratedDelivery,
  isAdmin: boolean,
  onSelectDelivery?: (delivery?: HydratedDelivery) => void,
) => {
  const {
    deliveryId,
    createdAt,
    deliveredAt,
    cancelledAt,
    updatedAt,
    pickup,
    dropOff,
    deliveryOptionId,
    deliveryVehicleId,
    status: deliveryStatus,
    deliveryItems,
    rider: tradeazeRider,
    externalCourier,
    order,
    etaStatus,
    heavySideItems,
    orderId,
    riderId,
    merchant,
  } = delivery;
  const posthog = usePostHog();
  const navigate = useNavigate();

  const nextIncompleteStop = [pickup, dropOff].find(
    (stop) => !stop.completedAt,
  );

  const earliestArrival = nextIncompleteStop?.etaStart;
  const latestArrival = nextIncompleteStop?.etaEnd;
  const nextStopType = nextIncompleteStop?.type;
  const stopIndex = nextIncompleteStop?.incompleteStopIndex;
  const showLateEtaTag = isAdmin;

  const rider =
    tradeazeRider ||
    (externalCourier
      ? {
          ...externalCourier,
          riderId: externalCourier?.id,
          deliveryVehicle: deliveryVehicleId,
        }
      : null);

  const primaryContact = useMemo(
    () =>
      dropOff?.contacts?.find((contact) => contact.isPrimary) ||
      dropOff?.contacts?.[0],
    [dropOff],
  );

  const companyName = dropOff?.companyName || primaryContact?.contactName || '';

  const showLate = isAdmin;
  const showMerchantName = isAdmin;
  const showItemSizing = isAdmin;
  const showNotesPreview = isAdmin;
  const hasNotes = getHasNotes(order, delivery);
  const notesPreview = buildNotesPreview(order, delivery);
  const isHeavyside = Boolean(heavySideItems);
  const isAssigned = Boolean(riderId);
  const isToday = isSameDay(new Date(), new Date(dropOff.windowEnd));
  const showEta = isToday;
  const showProgress = deliveryStatus === 'CONFIRMED' && isToday && isAssigned;
  const showRiderVehicle = isAdmin;
  const showStatusDescription = !isAdmin;
  const hasShiftRider = !delivery.gigJob?.riderId && Boolean(delivery.riderId);
  const isExternalJobCancelled =
    delivery?.externalJob?.status ===
      ExternalJobStatusSchema.Values.CANCELLED_EXTERNAL ||
    delivery?.externalJob?.status ===
      ExternalJobStatusSchema.Values.CANCELLED_INTERNAL;

  const statusDescription = buildStatusDescription({
    etaStatus,
    deliveryStatus,
    isToday,
    stopIndex,
    proofName: dropOff?.proofName,
    deliveredAt: dropOff?.completedAt,
  });

  const descriptionColorScheme = getDescriptionColorScheme({
    deliveryStatus,
    etaStatus,
    isToday,
  });

  const progressColorScheme = getProgressColorScheme({
    deliveryStatus,
    etaStatus,
    isToday,
  });

  const handleCardClick = () => {
    captureEvent(posthog)('delivery_card_clicked', {
      deliveryId: deliveryId,
    });
    if (onSelectDelivery && isAdmin) {
      onSelectDelivery?.(delivery);
    } else {
      navigate(`/orders/${orderId}`);
    }
  };

  const onCloseMenu = () => {
    onSelectDelivery?.(undefined);
  };

  const isEtaPastWindow = checkDropOffEtaPastWindow(delivery);

  return {
    deliveryId,
    cancelledAt,
    companyName,
    createdAt,
    deliveredAt,
    deliveryOptionId,
    pickup,
    dropOff,
    deliveryVehicleId,
    deliveryWindowStart: dropOff.windowStart,
    deliveryWindowEnd: dropOff.windowEnd,
    deliveryItems,
    descriptionColorScheme,
    earliestArrival,
    handleCardClick,
    hasNotes,
    isToday,
    isHeavyside,
    latestArrival,
    merchant,
    merchantOrderReference: order.merchantOrderReference,
    orderType: order.type,
    nextStopType,
    notesPreview,
    onCloseMenu,
    deliveryStatus,
    hasShiftRider,
    progressColorScheme,
    rider,
    showEta,
    showItemSizing,
    showLate,
    showMerchantName,
    showNotesPreview,
    showProgress,
    showRiderVehicle,
    showStatusDescription,
    statusDescription,
    updatedAt,
    isExternalJobCancelled,
    isEtaPastWindow,
    showLateEtaTag,
  };
};
