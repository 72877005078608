import {
  Box,
  Center,
  Heading,
  RadioGroup,
  Select,
  Show,
  SimpleGrid,
  Stack,
  Spinner,
  Text,
  Radio,
} from '@chakra-ui/react';
import {
  AtoBTypeIcon,
  MultiCollectionTypeIcon,
  MultiDropTypeIcon,
} from '../assets/orderTypeIcons';
import { OrderTypeCard } from './order-type-card';
import React from 'react';
import { MerchantAccount, OrderType } from '@tradeaze-packages/schemas';

type OrderOptionsProps = {
  handleOrderSelection: (orderType: OrderType) => void;
  isAdmin: boolean;
  merchantsLoading?: boolean;
  selectedMerchantId?: string;
  selectedMerchant?: MerchantAccount;
  handleMerchantSelect?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  sortedMerchants?: MerchantAccount[];
  handleGuestOrderToggle: (isGuest: boolean) => void;
  isGuestOrder: boolean;
};

type OrderTypeOption = {
  icon: React.ReactNode;
  title: string;
  description: string;
  enabled: boolean;
};

export const orderTypeOptions: Partial<Record<OrderType, OrderTypeOption>> = {
  A_TO_B: {
    icon: <AtoBTypeIcon height={80} />,
    title: 'A to B',
    description:
      'One way trip from location A to location B. Manage one single collection to one single destination.',
    enabled: true,
  },
  MULTI_COLLECTION: {
    icon: <MultiCollectionTypeIcon height={80} />,
    title: 'Multi-Collection',
    description:
      'Load several times and unload once. Manage multiple collections to one single destination.',
    enabled: true,
  },
  MULTI_DROP: {
    icon: <MultiDropTypeIcon height={80} />,
    title: 'Multi-Drop',
    description:
      'Load once and unload several times. Manage one single collection to multiple destinations.',
    enabled: true,
  },
};

type GuestOrderOptions = 'merchant' | 'guest';

export const OrderOptions = ({
  handleOrderSelection,
  merchantsLoading,
  selectedMerchantId,
  selectedMerchant,
  handleMerchantSelect,
  sortedMerchants,
  handleGuestOrderToggle,
  isGuestOrder,
  isAdmin,
}: OrderOptionsProps) => {

  const showOrderTypeSelection = !isAdmin || isGuestOrder || Boolean(selectedMerchant);
  const showGuestOrderToggle = isAdmin;
  const showMerchantSelection = isAdmin && !isGuestOrder;

  const onChangeGuestOrder = (value: GuestOrderOptions) => {
    handleGuestOrderToggle(value === 'guest');
  };

  const getRadioValue = () => {
    return isGuestOrder ? 'guest' : 'merchant';
  };

  return (
    <Box display={'flex'} flexDirection={'row'}>
      <Box display={'flex'} flexDirection={'column'} gap={4}>
        <Heading size="md" mb={4}>
          Create an order
        </Heading>

        {showGuestOrderToggle && (
          <Box mb={4}>
            <Text  mb={3} fontWeight={500}>Create order as</Text>
            <RadioGroup onChange={onChangeGuestOrder} value={getRadioValue()}>
              <Stack direction="row">
                <Radio value={"merchant" satisfies GuestOrderOptions}>Merchant</Radio>
                <Radio value={"guest" satisfies GuestOrderOptions}>Guest</Radio>
              </Stack>
            </RadioGroup>
          </Box>
        )}

        {showMerchantSelection && (
          <Box mb={4}>
            <Text data-cy={'order-address-text'} mb={4} fontWeight={500}>
              Create order for
            </Text>
            {merchantsLoading ? (
              <Center h="16">
                <Spinner />
              </Center>
            ) : (
              <Select
                data-cy={'order-address-select'}
                defaultValue={selectedMerchantId}
                onChange={handleMerchantSelect}
              >
                <option value={undefined} data-cy={'order-address-blank'}>
                  -
                </option>
                {sortedMerchants?.map((merchant, i) => (
                  <option
                    value={merchant.merchantId}
                    data-cy={`order-address-${i + 1}`}
                  >
                    {merchant.merchantName} - {merchant.address},{' '}
                    {merchant.postCode}
                  </option>
                ))}
              </Select>
            )}
          </Box>
        )}

        {showOrderTypeSelection && (
          <Box>
            <Text fontWeight={500} mb={4}>
              Which delivery type do you need?
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={8}>
              {Object.entries(orderTypeOptions)
                .filter(([_, option]) => option.enabled)
                .map(([orderType, option]) => (
                  <OrderTypeCard
                    key={orderType}
                    icon={option.icon}
                    title={option.title}
                    description={option.description}
                    onClick={() => handleOrderSelection(orderType as OrderType)}
                  />
                ))}
              <div style={{ width: '250px', height: '1px' }} />
            </SimpleGrid>
          </Box>
        )}
      </Box>
      <Show above="2xl">
        <Box width={'100px'} ml={8}></Box>
      </Show>
    </Box>
  );
};
