import { AnimatedImage } from '../shared/AnimatedImage';
import Svg from './assets/Account-amico.svg';

interface AccountImageProps {
  animationDelay?: number;
}

export const AccountImage = ({ animationDelay }: AccountImageProps) => {
  return (
    <AnimatedImage
      src={Svg}
      alt="Account"
      position="absolute"
      bottom="0"
      right="0"
      height="100%"
      maxWidth="70%"
      objectFit="contain"
      animationDelay={animationDelay}
    />
  );
};
