import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { CreateOrder, OrderType } from '@tradeaze-packages/schemas';
import { OrderConfirmationSummary } from './OrderConfirmationSummary';

interface OrderConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  currentOrder: Partial<CreateOrder>;
  isLoading: boolean;
  isUpdatingOrder?: boolean;
  orderType: OrderType;
}

export const OrderConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  currentOrder,
  isLoading,
  isUpdatingOrder = false,
  orderType,
}: OrderConfirmationModalProps) => {
  const headerText = isUpdatingOrder ? 'Update Order' : 'Confirm Order Details';

  const confirmationText = isUpdatingOrder ? 'Update Order' : 'Confirm Order';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      scrollBehavior="outside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxHeight="90vh">
        <ModalHeader>{headerText}</ModalHeader>
        <ModalBody
          maxHeight={'100%'}
          overflowY="auto"
          border="1px solid"
          borderColor="gray.200"
        >
          <OrderConfirmationSummary
            currentOrder={currentOrder}
            orderType={orderType}
          />
        </ModalBody>
        <ModalFooter gap={3}>
          <Button variant="ghost" onClick={onClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            isLoading={isLoading}
            loadingText="Processing..."
            data-cy="confirm-order-button"
          >
            {confirmationText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
