import { z } from 'zod';
import { processStringBoolean } from '../../../utils';

export const GetOrderCountsQuerySchema = z.object({
  deliveryToday: z.preprocess(processStringBoolean, z.boolean()).optional(),
});

export const GetOrderCountsResponseSchema = z.object({
  unassignedCount: z.number(),
  overRunningCount: z.number(),
  onJobBoardCount: z.number(),
});

export type GetOrderCountsQuery = z.infer<typeof GetOrderCountsQuerySchema>;

export type GetOrderCountsResponse = z.infer<
  typeof GetOrderCountsResponseSchema
>;
