import React from 'react';

export const LutonRearIcon: React.FC<{
  width?: number;
  height?: number;
  xArea?: number;
  yArea?: number;
  xPosition?: string;
  yPosition?: string;
}> = ({ height, width, xArea, xPosition, yArea, yPosition }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 292 245"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-50, 0)">
        <text x="190" y="10" fill="#232324" fontSize="14" fontFamily="Arial">
          Width
        </text>
        <text x="50" y="130" fill="#232324" fontSize="14" fontFamily="Arial">
          Height
        </text>
        <path
          d="M107.5 52L109.887 47L104.113 47L106.5 52L107.5 52ZM106.5 195L104.113 200L109.887 200L107.5 195L106.5 195ZM106.5 51.5L106.5 195.5L107.5 195.5L107.5 51.5L106.5 51.5Z"
          fill="#AF9032"
        />
        <path
          d="M278 24.5L283 26.8868V21.1132L278 23.5V24.5ZM136 23.5L131 21.1132V26.8868L136 24.5V23.5ZM278.5 23.5L135.5 23.5V24.5L278.5 24.5V23.5Z"
          fill="#AF9032"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M158.593 219.791V239.001C158.593 242.315 155.907 245.001 152.593 245.001H129C125.686 245.001 123 242.315 123 239.001V238.75V219.762V156.596L144.273 92.8262C144.636 91.4857 145.382 90.4803 146.509 89.8101C147.636 89.1398 148.891 88.8047 150.273 88.8047H264.818C265.929 88.8047 266.937 89.1733 267.842 89.9106C268.748 90.6479 269.377 91.6198 269.727 92.8262L291 156.596V219.738C291 219.746 291 219.754 291 219.762V239.001C291 242.315 288.314 245.001 285 245.001H261.407C258.093 245.001 255.407 242.315 255.407 239.001V219.791H158.593ZM280.091 148.553H133.909L151.364 96.8477H262.636L280.091 148.553ZM164.591 192.291C162.5 194.539 159.93 195.662 156.882 195.662C153.834 195.662 151.243 194.561 149.11 192.359C146.976 190.157 145.909 187.45 145.909 184.24C145.909 181.029 146.955 178.3 149.045 176.053C151.136 173.806 153.706 172.682 156.754 172.682C159.802 172.682 162.393 173.783 164.527 175.985C166.66 178.188 167.727 180.894 167.727 184.105C167.727 187.315 166.682 190.044 164.591 192.291ZM264.955 192.291C262.864 194.539 260.294 195.662 257.246 195.662C254.198 195.662 251.607 194.561 249.473 192.359C247.34 190.157 246.273 187.45 246.273 184.24C246.273 181.029 247.318 178.3 249.409 176.053C251.5 173.806 254.07 172.682 257.118 172.682C260.166 172.682 262.757 173.783 264.89 175.985C267.024 178.188 268.091 180.894 268.091 184.105C268.091 187.315 267.045 190.044 264.955 192.291ZM283.364 211.748H130.636V156.596H283.364V211.748Z"
          fill="#A7A7A8"
        />
        <rect
          x="126.636"
          y="43"
          width="160.727"
          height="161"
          rx="12"
          fill="#F9F9F9"
        />
        <rect
          x="126.636"
          y="43"
          width="160.727"
          height="161"
          rx="12"
          stroke="#A7A7A8"
          stroke-width="8"
        />
        <rect
          x="138.636"
          y="55"
          width="136.727"
          height="137"
          rx="2"
          fill="#D5D5D5"
        />
        <rect
          x={xPosition}
          y={yPosition}
          width={xArea}
          height={yArea}
          rx="4"
          fill="#AF9032"
        />
      </g>
    </svg>
  );
};
