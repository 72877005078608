import { IconButton, Tooltip } from '@chakra-ui/react';
import { MdRestartAlt } from 'react-icons/md';

interface ResetFiltersButtonProps {
  onReset: () => void;
  buttonProps: Omit<React.ComponentProps<typeof IconButton>, 'aria-label'>;
}

export const ResetFiltersButton: React.FC<ResetFiltersButtonProps> = ({
  onReset,
  buttonProps,
}) => {
  return (
    <Tooltip label="Reset Filters" placement="bottom">
      <IconButton
        icon={<MdRestartAlt fontSize={18} />}
        size="sm"
        onClick={onReset}
        {...buttonProps}
        aria-label="Reset filters"
      />
    </Tooltip>
  );
};
