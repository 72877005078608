export const vehicleOptions = [
    {
      type: 'Van' as const,
      capacity: {
        length: 4.2,
        weight: 1200,
      },
      price: 350,
      description: 'An extra long-wheelbase van suitable for most deliveries.',
    },
    {
      type: 'Flatbed' as const,
      capacity: {
        length: 7,
        weight: 1200,
      },
      price: 400,
      description: 'Open bed truck ideal for construction materials.',
    },
    {
      type: 'Luton' as const,
      capacity: {
        length: 4,
        weight: 1000,
      },
      price: 400,
      description: 'Large box van perfect for furniture and bulky items.',
    },
  ] as const;

export type VehicleType = (typeof vehicleOptions)[number]['type']; 