import { QueryKey, useQuery } from '@tanstack/react-query';
import { fetchDeliveryOptions } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared/CustomUseQueryOptions';
import { GetDeliveryOptionsQuery } from '@tradeaze-packages/schemas';

export const getDeliveryOptionsQueryKey = (
  args?: GetDeliveryOptionsQuery,
): QueryKey => {
  const key: (string | GetDeliveryOptionsQuery)[] = ['getDeliveryOptions'];

  if (args) {
    key.push(args);
  }

  return key;
};

export const useGetDeliveryOptions = (
  args: GetDeliveryOptionsQuery,
  options?: CustomUseQueryOptions<typeof fetchDeliveryOptions>,
) => {
  return useQuery({
    queryKey: getDeliveryOptionsQueryKey(args),
    queryFn: async () => fetchDeliveryOptions(args),
    keepPreviousData: true,
    ...options,
  });
};
