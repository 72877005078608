import { Box, BoxProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';

const MotionBox = motion(Box);

type BaseCardProps = Omit<BoxProps, 'animation'> & {
  selected?: boolean;
  primaryColor?: string;
  secondaryColor?: string;
  gradientStart?: string;
  gradientEnd?: string;
  animation?: boolean;
  isClickable?: boolean;
};

export const BaseCard = forwardRef<HTMLDivElement, BaseCardProps>(
  (
    {
      children,
      selected,
      primaryColor = 'yellow.400',
      secondaryColor = 'yellow.500',
      gradientStart = 'yellow.50',
      gradientEnd = 'transparent',
      animation = false,
      isClickable = false,
      ...props
    },
    ref,
  ) => {
    const interactiveStyles =
      isClickable || props.onClick
        ? {
            cursor: 'pointer',
            transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
            _hover: {
              transform: 'translateY(-2px)',
              boxShadow: 'md',
              borderColor: selected ? primaryColor : secondaryColor,
            },
            _active: {
              transform: 'translateY(0px)',
              transition: 'all 0.1s ease-out',
              borderColor: primaryColor,
            },
            _focusVisible: {
              outline: 'none',
              boxShadow: `md, 0 0 0 2px var(--chakra-colors-gray-100), 0 0 0 4px var(--chakra-colors-${primaryColor})`,
              borderColor: secondaryColor,
            },
          }
        : {};

    const baseProps = {
      ref,
      bg: 'white',
      p: 6,
      borderRadius: 'lg',
      borderWidth: selected ? '2px' : '1px',
      borderColor: selected ? primaryColor : 'gray.200',
      position: 'relative',
      display: 'block',
      h: '100%',
      w: '100%',
      textAlign: 'left',
      _before: {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderRadius: 'lg',
        bgGradient: `linear(180deg, ${gradientStart} 0%, ${gradientEnd} 100%)`,
        opacity: selected ? 0.5 : 0,
        transition: 'opacity 0.3s ease-in-out',
        zIndex: 0,
      },
      sx: {
        '& > *': {
          position: 'relative',
          zIndex: 1,
        },
      },
      ...interactiveStyles,
    } as const;

    const Component = animation ? MotionBox : Box;

    return (
      <Component {...baseProps} {...props}>
        {children}
      </Component>
    );
  },
);

BaseCard.displayName = 'BaseCard';
