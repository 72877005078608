import { Address } from '@tradeaze-packages/schemas';

type AddressFields = {
  addressLine1: string | null;
  postCode: string | null;
  companyName?: string | null;
  deliveryNotes?: string | null;
  city?: string | null;
};

export const getHasAddressChanged = (
  savedAddress: Address,
  currentAddress: AddressFields
): boolean => {
  return (
    (savedAddress.addressLine1 ?? null) !== (currentAddress.addressLine1 ?? null) ||
    (savedAddress.postCode ?? null) !== (currentAddress.postCode ?? null) ||
    (savedAddress.companyName ?? null) !== (currentAddress.companyName ?? null) ||
    (savedAddress.instructions ?? null) !== (currentAddress.deliveryNotes ?? null) ||
    (savedAddress.city ?? null) !== (currentAddress.city ?? null)
  );
}; 
