import { BoxProps } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { forwardRef } from 'react';
import { BaseCard } from '../shared';

interface HomePageCardProps extends Omit<BoxProps, 'animation'> {
  as?: typeof NavLink;
  to?: string;
  onClick?: () => void;
}

export const HomePageCard = forwardRef<HTMLDivElement, HomePageCardProps>(
  (props, ref) => {
    return (
      <BaseCard
        ref={ref}
        isClickable
        primaryColor="yellow.400"
        secondaryColor="yellow.500"
        {...props}
      />
    );
  }
);

HomePageCard.displayName = 'HomePageCard';
