import { z } from 'zod';

export const ExternalJobStatusSchema = z.enum([
  'PENDING',
  'CONFIRMED',
  'PICKUP_COMPLETE',
  'PICKUP_FAILED',
  'DROPOFF_FAILED',
  'DROPOFF_COMPLETE',
  'CANCELLED_EXTERNAL',
  'CANCELLED_INTERNAL',
]);

export const ExternalJobSchema = z.object({
  id: z.string(),
  externalFleetId: z.string(),
  externalId: z.string(),
  providerId: z.string(),
  providerName: z.string(),
  providerLogo: z.string().nullish(),
  status: ExternalJobStatusSchema,
  trackingUrl: z.string(),
  price: z.number(),
  currency: z.string(),
  quoteId: z.string(),
  deliveryId: z.string(),
  externalCourierId: z.string().nullish(),
  externalUrl: z.string(),
  cancelledAt: z.string().nullish(),
});

export type ExternalJobStatus = z.infer<typeof ExternalJobStatusSchema>;
export type ExternalJob = z.infer<typeof ExternalJobSchema>;
