import { z } from 'zod';
import { HydratedOrderSchema } from '../../../models';
import { processStringBoolean } from '../../../utils';

export const GetOrderByIdQuerySchema = z.object({
  includeProofMedia: z.preprocess(processStringBoolean, z.boolean()).optional(),
});

export const GetOrderByIdResponseSchema = HydratedOrderSchema;

export type GetOrderByIdResponse = z.infer<typeof GetOrderByIdResponseSchema>;
export type GetOrderByIdQuery = z.infer<typeof GetOrderByIdQuerySchema>;
