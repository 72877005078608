import * as z from 'zod';

export const NashWebhookTypeSchema = z.enum(['delivery', 'courier_location']);

export const NashDeliveryEventSchema = z.enum([
  'assigned_driver',
  'not_assigned_driver',
  'pickup_enroute',
  'pickup_arrived',
  'pickup_complete',
  'dropoff_enroute',
  'dropoff_arrived',
  'dropoff_complete',
  'canceled_by_nash',
  'canceled_by_provider',
  'canceled_by_customer',
  'return_in_progress',
  'return_arrived',
  'returned',
  'failed'
]);

export const NashCourierLocationEventSchema = z.enum(['updated']);

export const NashWebhookEventSchema = z.union([
  NashDeliveryEventSchema,
  NashCourierLocationEventSchema,
]);

export const NashWebhookBodySchema = z.object({
  type: NashWebhookTypeSchema,
  event: NashWebhookEventSchema,
  data: z.object({
    id: z.string(),
    createdAt: z.string(),
    portalUrl: z.string(),
    externalIdentifier: z.string().nullable(),
    isActive: z.boolean(),
    jobConfigurations: z.array(
      z.object({
        package: z.object({
          pickupEndTime: z.string().nullable(),
          pickupStartTime: z.string().nullable(),
          dropoffStartTime: z.string().nullable(),
          dropoffEndTime: z.string().nullable(),
        }),
        tasks: z.array(
          z.object({
            id: z.string(),
            status: z.string(),
            delivery: z.object({
              currency: z.string(),
              courierName: z.string().nullable(),
              courierPhoneNumber: z.string().nullable(),
              courierLocation: z
                .object({
                  lat: z.number(),
                  lng: z.number(),
                })
                .nullable(),
              pickupEta: z.string().nullable(),
              dropoffEta: z.string().nullable(),
            }),
          }),
        ),
      }),
    ),
  }),
});

export type NashWebhookType = z.infer<typeof NashWebhookTypeSchema>;
export type NashDeliveryEvent = z.infer<typeof NashDeliveryEventSchema>;
export type NashCourierLocationEvent = z.infer<
  typeof NashCourierLocationEventSchema
>;
export type NashWebhookEvent = z.infer<typeof NashWebhookEventSchema>;
export type NashWebhookBody = z.infer<typeof NashWebhookBodySchema>;
