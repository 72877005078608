import { Box, HStack, Text } from '@chakra-ui/react';
import { formatTimeRange } from '@tradeaze/shared/utils';
import { LateEtaTag } from './LateEtaTag';

export const CardDeliveryWindow: React.FC<{
  deliveryWindowStart: string;
  deliveryWindowEnd: string;
  hasEtaPastWindow?: boolean;
  showLateEtaTag?: boolean;
}> = ({
  deliveryWindowStart,
  deliveryWindowEnd,
  hasEtaPastWindow,
  showLateEtaTag = false,
}) => {
  return (
    <Box>
      <Text color={'blackAlpha.800'} fontSize={'0.9em'}>
        Delivery Window
      </Text>
      <HStack>
        <Text fontWeight={600}>
          {formatTimeRange({
            from: deliveryWindowStart,
            to: deliveryWindowEnd,
          })}
        </Text>
        {hasEtaPastWindow && showLateEtaTag && <LateEtaTag />}
      </HStack>
    </Box>
  );
};
