import { HydratedDelivery } from '@tradeaze-packages/schemas';
import {
  useAssignRider,
  useCancelGigJob,
  useGetDelivery,
} from '@tradeaze/frontend/hooks';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { ExistingGigJobModal } from './ExistingGigJobModal';

export type AssignDeliveryContextType = {
  deliveryToAssign?: HydratedDelivery;
  isLoading: boolean;
  handleStartAssign: (delivery: HydratedDelivery) => void;
  handleCancelAssign: () => void;
  handleOpenExistingGigJobModal: (riderId: string | null) => void;
  handleCloseExistingGigJobModal: () => void;
};

export const AssignDeliveryContext = React.createContext<
  AssignDeliveryContextType | undefined
>(undefined);

export const AssignDeliveryProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [initialDeliveryToAssign, setInitialDeliveryToAssign] =
    useState<HydratedDelivery>();

  const reassignDisclosure = useDisclosure();

  const getDeliveryQuery = useGetDelivery(initialDeliveryToAssign?.deliveryId, {
    initialData: initialDeliveryToAssign,
  });

  const assignRiderMutation = useAssignRider();

  const cancelGigJobMutation = useCancelGigJob({
    onSuccess: () => {
      reassignDisclosure.onClose();
    },
  });

  const [assigningRiderId, setAssigningRiderId] = useState<string | undefined>(
    undefined,
  );

  const deliveryToAssign = getDeliveryQuery.data;
  const gigJobId = deliveryToAssign?.gigJob?.gigJobId;
  const gigJobRiderId = deliveryToAssign?.gigJob?.riderId;
  const hasAcceptedRider = Boolean(gigJobRiderId);

  const handleStartAssign = useCallback((delivery: HydratedDelivery) => {
    setInitialDeliveryToAssign(delivery);
  }, []);

  const handleCancelAssign = useCallback(() => {
    setInitialDeliveryToAssign(undefined);
  }, []);

  const handleOpenExistingGigJobModal = useCallback(
    (riderId: string | null) => {
      reassignDisclosure.onOpen();
      if (riderId) {
        setAssigningRiderId(riderId);
      }
    },
    [reassignDisclosure],
  );

  const handleCloseExistingGigJobModal = useCallback(() => {
    reassignDisclosure.onClose();
    setAssigningRiderId(undefined);
  }, [reassignDisclosure]);

  const handleCancelGigJob = useCallback(async () => {
    if (!deliveryToAssign) {
      return;
    }

    await cancelGigJobMutation.mutateAsync({
      gigJobId: gigJobId ?? '',
    });

    // for some reason, the query invalidation is not refetching the data so using explicit refetch
    await getDeliveryQuery.refetch();

    handleCloseExistingGigJobModal();
  }, [
    cancelGigJobMutation,
    deliveryToAssign,
    gigJobId,
    getDeliveryQuery,
    handleCloseExistingGigJobModal,
  ]);

  const handleAssignRider = useCallback(
    async ({ keepOnJobBoard }: { keepOnJobBoard: boolean }) => {
      if (!deliveryToAssign) {
        return;
      }

      await assignRiderMutation.mutateAsync({
        deliveryId: deliveryToAssign.deliveryId,
        riderId: assigningRiderId ?? '',
        keepOnJobBoard,
      });

      // for some reason, the query invalidation is not refetching the data so using explicit refetch
      await getDeliveryQuery.refetch();

      handleCloseExistingGigJobModal();
    },
    [
      assignRiderMutation,
      deliveryToAssign,
      assigningRiderId,
      getDeliveryQuery,
      handleCloseExistingGigJobModal,
    ],
  );

  const handleConfirmGigJobAction = useCallback(
    async ({ keepOnJobBoard }: { keepOnJobBoard: boolean }) => {
      if (!deliveryToAssign) {
        return;
      }

      if (hasAcceptedRider) {
        return await handleCancelGigJob();
      }

      return await handleAssignRider({ keepOnJobBoard });
    },
    [handleAssignRider, handleCancelGigJob, hasAcceptedRider, deliveryToAssign],
  );

  const state = useMemo(
    () => ({
      deliveryToAssign,
      isLoading: getDeliveryQuery.isLoading,
      handleStartAssign,
      handleCancelAssign,
      handleOpenExistingGigJobModal,
      handleCloseExistingGigJobModal,
    }),
    [
      deliveryToAssign,
      getDeliveryQuery.isLoading,
      handleStartAssign,
      handleCancelAssign,
      handleOpenExistingGigJobModal,
      handleCloseExistingGigJobModal,
    ],
  );

  return (
    <AssignDeliveryContext.Provider value={state}>
      {children}
      <ExistingGigJobModal
        isOpen={Boolean(gigJobId) && reassignDisclosure.isOpen}
        onClose={handleCloseExistingGigJobModal}
        gigJobId={gigJobId ?? ''}
        gigJobRiderId={gigJobRiderId ?? ''}
        assigningRiderId={assigningRiderId ?? ''}
        handleConfirm={handleConfirmGigJobAction}
        isLoading={cancelGigJobMutation.isLoading || assignRiderMutation.isLoading}
        isAssigningShift={true}
        hasAcceptedRider={hasAcceptedRider}
      />
    </AssignDeliveryContext.Provider>
  );
};

export const useAssignDeliveryContext = () => {
  const context = useContext(AssignDeliveryContext);
  if (!context) {
    throw new Error(
      'useAssignDeliveryContext must be used within a AssignDeliveryProvider',
    );
  }
  return context;
};
