import { zodResolver } from '@hookform/resolvers/zod';
import {
  CreateAToBPickupStopSchema,
  CreateDeliveryItem,
  CreateDeliveryItemSchema,
  CreateDeliveryStop,
  CreateMultiDropPickupStopSchema,
  CreateMultiCollectionPickupStopSchema,
  DuplicateOrder,
  ExistingOrder,
  MerchantAccount,
  OrderType,
  CreateMultiDropDropOffStopSchema,
  CreateAToBDropOffStopSchema,
  CreateMultiCollectionDropOffStopSchema,
} from '@tradeaze-packages/schemas';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { useMemo } from 'react';

type ExistingPickupData = ExistingOrder['pickupDetails'];
type ExistingDropoffData = ExistingOrder['dropoffDetails'];

type ExistingPickup = Exclude<ExistingPickupData, undefined | null>[number];
type ExistingDropoff = Exclude<ExistingDropoffData, undefined | null>[number];

type DuplicatePickupData = DuplicateOrder['pickupDetails'];
type DuplicateDropoffData = DuplicateOrder['dropoffDetails'];

type DuplicatePickup = Exclude<DuplicatePickupData, undefined | null>[number];
type DuplicateDropoff = Exclude<DuplicateDropoffData, undefined | null>[number];

type UseOrderSubForms = {
  merchantAccount?: MerchantAccount;
  existingStopData?: {
    pickupDetails: ExistingPickupData;
    dropOffDetails: ExistingDropoffData;
  };
  duplicateStopData?: {
    pickupDetails: DuplicatePickupData;
    dropOffDetails: DuplicateDropoffData;
  };
  orderType: OrderType;
};

const determinePickupSchema = (orderType: OrderType) => {
  if (orderType === 'MULTI_DROP') {
    return CreateMultiDropPickupStopSchema;
  }
  if (orderType === 'MULTI_COLLECTION') {
    return CreateMultiCollectionPickupStopSchema;
  }
  return CreateAToBPickupStopSchema;
};

const determineDropOffSchema = (orderType: OrderType) => {
  if (orderType === 'MULTI_DROP') {
    return CreateMultiDropDropOffStopSchema;
  }
  if (orderType === 'MULTI_COLLECTION') {
    return CreateMultiCollectionDropOffStopSchema;
  }
  return CreateAToBDropOffStopSchema;
};

const usePickupForm = (
  merchantAccount: MerchantAccount | undefined,
  existingPickup: ExistingPickup | undefined,
  duplicatePickup: DuplicatePickup | undefined,
  orderType: OrderType,
) => {
  const defaultValues = useMemo(
    () => ({
      id: uuid(),
      type: 'PICK_UP' as const,
      contacts: [],
      companyName:
        duplicatePickup?.companyName ||
        existingPickup?.companyName ||
        merchantAccount?.merchantName ||
        '',
      addressLine1:
        duplicatePickup?.addressLine1 ||
        existingPickup?.addressLine1 ||
        merchantAccount?.address ||
        '',
      addressLine2:
        duplicatePickup?.addressLine2 || existingPickup?.addressLine2 || '',
      addressLine3:
        duplicatePickup?.addressLine3 || existingPickup?.addressLine3 || '',
      city: duplicatePickup?.city || existingPickup?.city || merchantAccount?.city || '',
      country: duplicatePickup?.country || existingPickup?.country || '',
      postCode:
        duplicatePickup?.postCode ||
        existingPickup?.postCode ||
        merchantAccount?.postCode ||
        '',
      position:
        duplicatePickup?.position ||
        existingPickup?.position ||
        merchantAccount?.position ||
        null,
      stopSequence:
        duplicatePickup?.stopSequence || existingPickup?.stopSequence || 0,
      windowStart:
        duplicatePickup?.windowStart || existingPickup?.windowStart || '',
      windowEnd: duplicatePickup?.windowEnd || existingPickup?.windowEnd || '',
      deliveryStopId: existingPickup?.deliveryStopId,
      requiresImage:
        duplicatePickup?.requiresImage ||
        existingPickup?.requiresImage ||
        false,
      requiresSignature:
        duplicatePickup?.requiresSignature ||
        existingPickup?.requiresSignature ||
        false,
      collectionReference:
        duplicatePickup?.collectionReference ||
        existingPickup?.collectionReference ||
        '',
    }),
    [merchantAccount, existingPickup, duplicatePickup],
  );

  const form = useForm<CreateDeliveryStop>({
    defaultValues,
    resolver: zodResolver(determinePickupSchema(orderType)),
    mode: 'all',
  });

  const reset = () => {
    form.reset({
      type: 'PICK_UP',
      contacts: [],
      companyName: merchantAccount?.merchantName || '',
      addressLine1: merchantAccount?.address || '',
      addressLine2: '',
      addressLine3: '',
      city: merchantAccount?.city || '',
      country: '',
      postCode: merchantAccount?.postCode || '',
      position: merchantAccount?.position || null,
      stopSequence: 0,
      windowStart: '',
      windowEnd: '',
      requiresImage: false,
      requiresSignature: false,
    });
  };

  return { form, reset };
};

const useDropOffForm = (
  existingDropoff: ExistingDropoff | undefined,
  duplicateDropoff: DuplicateDropoff | undefined,
  pickupCollectionReference: string | null | undefined,
  orderType: OrderType,
) => {
  const defaultValues = useMemo(
    () => ({
      id: uuid(),
      type: 'DROP_OFF' as const,
      companyName:
        duplicateDropoff?.companyName || existingDropoff?.companyName || '',
      addressLine1:
        duplicateDropoff?.addressLine1 || existingDropoff?.addressLine1 || '',
      addressLine2:
        duplicateDropoff?.addressLine2 || existingDropoff?.addressLine2 || '',
      addressLine3:
        duplicateDropoff?.addressLine3 || existingDropoff?.addressLine3 || '',
      city: duplicateDropoff?.city || existingDropoff?.city || '',
      country: duplicateDropoff?.country || existingDropoff?.country || '',
      postCode: duplicateDropoff?.postCode || existingDropoff?.postCode || '',
      stopSequence:
        duplicateDropoff?.stopSequence || existingDropoff?.stopSequence || 1,
      windowStart:
        duplicateDropoff?.windowStart || existingDropoff?.windowStart || '',
      windowEnd:
        duplicateDropoff?.windowEnd || existingDropoff?.windowEnd || '',
      requiresImage: true,
      requiresSignature: true,
      contacts: duplicateDropoff?.contacts ||
        existingDropoff?.contacts || [
          {
            contactName: '',
            contactNumber: '',
            isPrimary: true,
            trackingEnabled: true,
            siteContactId: undefined,
          },
        ],
      deliveryStopId: existingDropoff?.deliveryStopId,
      position: duplicateDropoff?.position || existingDropoff?.position || null,
      companyId: duplicateDropoff?.companyId || existingDropoff?.companyId,
      addressId: duplicateDropoff?.addressId || existingDropoff?.addressId,
      collectionReference: pickupCollectionReference,
    }),
    [existingDropoff, duplicateDropoff, pickupCollectionReference],
  );

  const form = useForm<CreateDeliveryStop>({
    defaultValues,
    resolver: zodResolver(determineDropOffSchema(orderType)),
    mode: 'all',
  });

  const reset = () => {
    form.reset({
      type: 'DROP_OFF',
      stopSequence: 1,
      windowStart: '',
      windowEnd: '',
      contacts: [
        {
          contactName: '',
          contactNumber: '',
          isPrimary: true,
          trackingEnabled: true,
          siteContactId: undefined,
        },
      ],
      companyName: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      country: '',
      postCode: '',
      position: null,
      collectionReference: pickupCollectionReference,
      requiresImage: true,
      requiresSignature: true,
    });
  };

  return { form, reset };
};

const useItemForm = (
  existingPickup: ExistingPickup | undefined,
  duplicatePickup: DuplicatePickup | undefined,
) => {
  const defaultValues = useMemo(
    () => ({
      name:
        duplicatePickup?.deliveryItems?.[0]?.name ||
        existingPickup?.deliveryItems?.[0]?.name ||
        'Total Load',
      quantity:
        duplicatePickup?.deliveryItems?.[0]?.quantity ||
        existingPickup?.deliveryItems?.[0]?.quantity ||
        1,
      heightUnit:
        duplicatePickup?.deliveryItems?.[0]?.heightUnit ||
        existingPickup?.deliveryItems?.[0]?.heightUnit ||
        'mm',
      lengthUnit:
        duplicatePickup?.deliveryItems?.[0]?.lengthUnit ||
        existingPickup?.deliveryItems?.[0]?.lengthUnit ||
        'mm',
      widthUnit:
        duplicatePickup?.deliveryItems?.[0]?.widthUnit ||
        existingPickup?.deliveryItems?.[0]?.widthUnit ||
        'mm',
      weightUnit:
        duplicatePickup?.deliveryItems?.[0]?.weightUnit ||
        existingPickup?.deliveryItems?.[0]?.weightUnit ||
        'kg',
      height:
        duplicatePickup?.deliveryItems?.[0]?.height ||
        existingPickup?.deliveryItems?.[0]?.height,
      length:
        duplicatePickup?.deliveryItems?.[0]?.length ||
        existingPickup?.deliveryItems?.[0]?.length,
      width:
        duplicatePickup?.deliveryItems?.[0]?.width ||
        existingPickup?.deliveryItems?.[0]?.width,
      weight:
        duplicatePickup?.deliveryItems?.[0]?.weight ||
        existingPickup?.deliveryItems?.[0]?.weight,
      price:
        duplicatePickup?.deliveryItems?.[0]?.price ||
        existingPickup?.deliveryItems?.[0]?.price,
      description:
        duplicatePickup?.deliveryItems?.[0]?.description ||
        existingPickup?.deliveryItems?.[0]?.description ||
        '',
      heavySideItems:
        duplicatePickup?.deliveryItems?.[0]?.heavySideItems ??
        existingPickup?.deliveryItems?.[0]?.heavySideItems,
      id:
        duplicatePickup?.deliveryItems?.[0]?.id ||
        existingPickup?.deliveryItems?.[0]?.id ||
        '',
      deliveryId:
        duplicatePickup?.deliveryItems?.[0]?.deliveryId ||
        existingPickup?.deliveryItems?.[0]?.deliveryId,
    }),
    [existingPickup, duplicatePickup],
  );

  const form = useForm<CreateDeliveryItem>({
    defaultValues,
    resolver: zodResolver(CreateDeliveryItemSchema),
    mode: 'all',
  });

  const reset = () => {
    form.reset({
      name: 'Total Load',
      quantity: 1,
      heightUnit: 'mm',
      lengthUnit: 'mm',
      widthUnit: 'mm',
      weightUnit: 'kg',
    });
  };

  return { form, reset };
};

export const useOrderSubForms = ({
  merchantAccount,
  existingStopData,
  duplicateStopData,
  orderType,
}: UseOrderSubForms) => {
  const firstExistingPickup = existingStopData?.pickupDetails?.[0];
  const firstExistingDropoff = existingStopData?.dropOffDetails?.[0];

  const firstDuplicatePickup = duplicateStopData?.pickupDetails?.[0];
  const firstDuplicateDropoff = duplicateStopData?.dropOffDetails?.[0];

  const { form: pickupForm, reset: resetPickupForm } = usePickupForm(
    merchantAccount,
    firstExistingPickup,
    firstDuplicatePickup,
    orderType,
  );

  const pickupCollectionReference = pickupForm.watch('collectionReference');

  const { form: dropOffForm, reset: resetDropOffForm } = useDropOffForm(
    firstExistingDropoff,
    firstDuplicateDropoff,
    pickupCollectionReference,
    orderType,
  );
  const { form: itemForm, reset: resetItemForm } = useItemForm(
    firstExistingPickup,
    firstDuplicatePickup,
  );

  const pickupDetails = pickupForm.watch();
  const dropoffDetails = dropOffForm.watch();
  const itemDetails = itemForm.watch();

  return {
    pickupForm,
    dropOffForm,
    itemForm,
    pickupDetails,
    dropoffDetails,
    itemDetails,
    resetPickupForm,
    resetDropOffForm,
    resetItemForm,
  };
};
