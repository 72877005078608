import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';

const LOADING_TOAST_DURATION = 500;

export const useRefreshAllQueries = () => {
  const queryClient = useQueryClient();

  const refreshAll = useCallback(() => {
    // Create a loading toast and store its ID
    const toastId = toast.loading('Refreshing data...');
    
    queryClient.invalidateQueries();

    setTimeout(() => {
      toast.dismiss(toastId);
      toast.success('All data refreshed');
    }, LOADING_TOAST_DURATION);
  }, [queryClient]);

  return refreshAll;
}; 