import { Show } from '@chakra-ui/react';
import {
  useGetMerchantAccount,
  useGetOrder,
  useMerchantUpdateOrder,
} from '@tradeaze/frontend/hooks';
import {
  Map,
  PageError,
  PageLoading,
  TwoColumnLayout,
  useFormMapMarkers,
  Layout,
  MobileMapWithToggle,
  EditOrder,
  buildExistingOrder,
} from '@tradeaze/frontend/ui-web';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../components/auth/AuthProvider';
import { useMemo } from 'react';
import { UpdateOrderBody } from '@tradeaze-packages/schemas';

export const EditOrderPage = () => {
  const { orderId } = useParams();

  const navigate = useNavigate();

  const { cognitoUserId } = useAuthContext();

  const {
    data: merchantAccount,
    error: merchantError,
    isLoading: isLoadingMerchantAccount,
  } = useGetMerchantAccount(cognitoUserId);

  const {
    data: order,
    isLoading: isLoadingOrder,
    error: orderError,
  } = useGetOrder(orderId);

  const { mutate: updateOrder, isLoading: isLoadingUpdate } =
    useMerchantUpdateOrder({
      onSuccess: () => {
        navigate(`/orders/${orderId}`);
      },
    });

  const handleUpdateOrder = async (orderUpdate: UpdateOrderBody) => {
    if (!merchantAccount || !orderUpdate) {
      toast.error('Missing required data to update');
      return;
    }

    if (merchantAccount.merchantId !== orderUpdate?.merchantId) {
      toast.error('You do not have permission to edit this order');
      return;
    }

    if (!orderId) {
      return;
    }

    updateOrder({ orderId, order: orderUpdate });
  };

  const {
    mapMarkers,
    addDeliveryMarker,
    addPickupMarker,
    removeDeliveryMarker,
    removePickupMarker,
  } = useFormMapMarkers();

  const existingOrder = useMemo(() => {
    if (!order) {
      return;
    }

    const parsedOrder = buildExistingOrder(order);

    return parsedOrder;
  }, [order]);

  if (isLoadingOrder || isLoadingMerchantAccount) {
    return <PageLoading />;
  }

  if (orderError || merchantError) {
    return <PageError />;
  }

  if (!order || !merchantAccount) {
    return <PageError />;
  }

  if (order.merchantId !== merchantAccount.merchantId || !existingOrder) {
    return <PageError text="Unauthorized to edit" />;
  }

  const MainComponent = (
    <EditOrder
      order={existingOrder}
      isAdmin={false}
      merchantAccount={merchantAccount}
      addPickupMarker={addPickupMarker}
      addDeliveryMarker={addDeliveryMarker}
      removePickupMarker={removePickupMarker}
      removeDeliveryMarker={removeDeliveryMarker}
      onSubmit={handleUpdateOrder}
      isLoading={isLoadingUpdate}
    />
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={MainComponent}
          right={<Map markers={mapMarkers} shouldUpdateBounds={true} />}
        />
      </Show>
      <Show below="md">
        <Layout>
          {MainComponent}
          <MobileMapWithToggle
            mapProps={{
              markers: mapMarkers,
              shouldUpdateBounds: true,
            }}
          />
        </Layout>
      </Show>
    </>
  );
};
