import React from 'react';

export const VanMRearIcon: React.FC<{
  width?: number;
  height?: number;
  xArea?: number;
  yArea?: number;
  xPosition?: string;
  yPosition?: string;
}> = ({ height, width, xArea, xPosition, yArea, yPosition }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="50 0 259 233"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="175" y="10" fill="#232324" fontSize="14" fontFamily="Arial">
        Width
      </text>
      <text x="50" y="130" fill="#232324" fontSize="14" fontFamily="Arial">
        Height
      </text>
      <path
        d="M109.5 51L111.887 46L106.113 46L108.5 51L109.5 51ZM108.5 187L106.113 192L111.887 192L109.5 187L108.5 187ZM108.5 50.5L108.5 187.5L109.5 187.5L109.5 50.5L108.5 50.5Z"
        fill="#AF9032"
      />
      <path
        d="M246 24.5L251 26.8868V21.1132L246 23.5V24.5ZM138 23.5L133 21.1132V26.8868L138 24.5V23.5ZM246.5 23.5L137.5 23.5V24.5L246.5 24.5V23.5Z"
        fill="#AF9032"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M153.362 210.36C153.38 210.553 153.39 210.749 153.39 210.946V227.001C153.39 230.315 150.704 233.001 147.39 233.001H131C128.573 233.001 126.482 231.56 125.537 229.486C125.179 228.888 125 228.188 125 227.386V227.001V210.946V153.607L141.968 96.3381C142.258 95.1343 142.852 94.2314 143.751 93.6295C144.65 93.0275 145.651 92.7266 146.753 92.7266H238.117C239.003 92.7266 239.807 93.0576 240.529 93.7197C241.252 94.3819 241.753 95.2547 242.032 96.3381L259 153.607V210.933C259 210.937 259 210.942 259 210.946V227.001C259 227.006 259 227.01 259 227.015V227.386C259 228.188 258.821 228.888 258.463 229.486C257.518 231.56 255.428 233.001 253 233.001H236.61C233.296 233.001 230.61 230.315 230.61 227.001V210.946C230.61 210.749 230.62 210.553 230.638 210.36H153.362ZM250.299 146.384H133.701L147.623 99.9497H236.377L250.299 146.384ZM158.174 185.664C156.506 187.682 154.456 188.691 152.025 188.691C149.594 188.691 147.527 187.702 145.825 185.724C144.124 183.747 143.273 181.316 143.273 178.433C143.273 175.55 144.107 173.099 145.774 171.081C147.442 169.063 149.492 168.054 151.923 168.054C154.354 168.054 156.421 169.042 158.123 171.02C159.824 172.998 160.675 175.428 160.675 178.312C160.675 181.195 159.841 183.645 158.174 185.664ZM238.226 185.664C236.558 187.682 234.508 188.691 232.077 188.691C229.646 188.691 227.579 187.702 225.877 185.724C224.176 183.747 223.325 181.316 223.325 178.433C223.325 175.55 224.159 173.099 225.826 171.081C227.494 169.063 229.544 168.054 231.975 168.054C234.406 168.054 236.473 169.042 238.175 171.02C239.876 172.998 240.727 175.428 240.727 178.312C240.727 181.195 239.893 183.645 238.226 185.664ZM252.909 203.137H131.091V153.607H252.909V203.137Z"
        fill="#A7A7A8"
      />
      <rect
        x="129"
        y="44"
        width="126"
        height="152.341"
        rx="12"
        fill="#F9F9F9"
      />
      <rect
        x="129"
        y="44"
        width="126"
        height="152.341"
        rx="12"
        stroke="#A7A7A8"
        stroke-width="8"
      />
      <rect x="141" y="56" width="102" height="128.341" rx="4" fill="#D5D5D5" />
      <rect
        x={xPosition}
        y={yPosition}
        width={xArea}
        height={yArea}
        rx="4"
        fill="#AF9032"
      />
    </svg>
  );
};
