import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { z } from 'zod';
import {
  CreateDeliveryItemSchema,
  UpdateDeliveryItemSchema,
} from './delivery-item-schema';
import { DeliveryOptionIdSchema } from './delivery-option-schema';
import {
  CreateDropOffStopSchema,
  CreatePickupStopSchema,
  UpdatePickupStopSchema,
  UpdateDropOffStopSchema,
  CreateAToBPickupStopSchema,
  CreateMultiCollectionPickupStopSchema,
  CreateMultiDropPickupStopSchema,
  CreateMultiDropDropOffStopSchema,
  CreateMultiCollectionDropOffStopSchema,
  CreateAToBDropOffStopSchema,
} from './delivery-stop-schema';
import { DeliveryVehicleIdSchema } from './delivery-vehicle-schema';
import { PaginationParamsSchema } from './paginated-data-schema';
import { buildArrayOrSingleSchema, processStringBoolean } from '../utils';
import { FeatureFlags } from './feature-flags-schema';

extendZodWithOpenApi(z);

export const DeliveryWindowSchema = z.object({
  from: z.string().datetime(),
  to: z.string().datetime(),
});

/**
 * Standard Orders
 */
export const OrderStatusSchema = z.enum([
  'PENDING',
  'CONFIRMED',
  'DELIVERED',
  'REJECTED',
  'CANCELLED',
]);

export const OrderTypeSchema = z.enum([
  'A_TO_B',
  'MULTI_COLLECTION',
  'MULTI_DROP',
]);

const stringRequired = (fieldName: string, customMessage?: string) =>
  z
    .string({
      invalid_type_error: customMessage || `${fieldName} must be a string`,
      required_error: customMessage || `${fieldName} is required`,
    })
    .trim()
    .min(1, { message: customMessage || `${fieldName} is required` });

const numberRequired = (fieldName: string, customMessage?: string) =>
  z
    .number({
      invalid_type_error: customMessage || `${fieldName} must be a number`,
      required_error: customMessage || `${fieldName} is required`,
    })
    .min(0, {
      message: customMessage || `${fieldName} must be a positive number`,
    });

export const OrderSchema = z.object({
  createdAt: z.string(),
  updatedAt: z.string(),
  orderId: z.string(),
  merchantId: z.string().nullish(),
  type: OrderTypeSchema,
  orderStatus: OrderStatusSchema,
  bookedBy: z.string().nullish(),
  deliveryPrice: z.number(),
  merchantServiceCharge: z.number(),
  invoicingName: z.string().nullish(),
  invoicingEmail: z.string().email().nullish(),
  cancelledAt: z.string().nullish(),
  confirmedAt: z.string().nullish(),
  deliveredAt: z.string().nullish(),
  deliveryDuration: z.number().nullish(),
  deliveryDistance: z.number().nullish(),
  totalTimeEstimate: z.number().nullish(),
  endUserId: z.string().nullish(),
  isPriceEdited: z.boolean().nullish(),
  merchantOrderReference: z.string().nullish(),
  notes: z.string().nullish(),
  metadata: z.record(z.any()).nullish(),
  deliveryWindowStart: z.string(),
  deliveryWindowEnd: z.string(),
  apiClientId: z.string().nullish(),
  cancellationFee: z.number().nullish(),
  cancellationReason: z.string().nullish(),
  tradeazeComments: z.string().nullish(),
});

export const CommonCreateOrderSchema = z.object({
  orderId: z.string().optional(),
  updatedAt: z.string().optional(),
  createdAt: z.string().optional(),
  merchantId: z.string().nullish(),
  invoicingName: stringRequired('Invoicing name'),
  invoicingEmail: z.string().email().nullish(),
  type: OrderTypeSchema,
  orderStatus: OrderStatusSchema.optional(),
  bookedBy: stringRequired('Booked by'),
  deliveryPrice: numberRequired('Delivery price'),
  merchantServiceCharge: numberRequired('Service charge'),
  confirmedAt: z.string().optional(),
  deliveredAt: z.string().optional(),
  cancelledAt: z.string().optional(),
  deliveryDuration: z.number().nullish(),
  deliveryDistance: z.number().nullish(),
  totalTimeEstimate: z.number().nullish(),
  endUserId: z.string().nullish(),
  isPriceEdited: z.boolean().nullish(),
  merchantOrderReference: stringRequired('Purchase order reference'),
  notes: z.string().nullish(),
  metadata: z.record(z.any()).nullish(),
  deliveryWindowStart: stringRequired('Delivery window start'),
  deliveryWindowEnd: stringRequired('Delivery window end'),
  deliveryOption: DeliveryOptionIdSchema,
  deliveryVehicle: DeliveryVehicleIdSchema,
  tips: z.number().nullish(),
  collectionReady: z.boolean().nullish(),
});

export const CreateOrderAToBSchema = CommonCreateOrderSchema.extend({
  type: z.literal('A_TO_B'),
  pickup: CreateAToBPickupStopSchema,
  dropOff: CreateAToBDropOffStopSchema,
});

export const CreateOrderMultiCollectionSchema = CommonCreateOrderSchema.extend({
  type: z.literal('MULTI_COLLECTION'),
  pickups: z.array(CreateMultiCollectionPickupStopSchema).min(2, {
    message: 'At least two pickups are required in multi-collection',
  }),
  dropOff: CreateMultiCollectionDropOffStopSchema,
});

export const CreateOrderMultiDropSchema = CommonCreateOrderSchema.extend({
  type: z.literal('MULTI_DROP'),
  pickup: CreateMultiDropPickupStopSchema,
  dropOffs: z
    .array(CreateMultiDropDropOffStopSchema)
    .min(2, { message: 'At least two drop-offs are required in multi-drop' }),
});

export const CreateOrderSchema = z.discriminatedUnion('type', [
  CreateOrderAToBSchema,
  CreateOrderMultiCollectionSchema,
  CreateOrderMultiDropSchema,
]);

export const travisPerkinsExtensions = {
  merchantOrderReference: z
    .string({
      invalid_type_error: 'Purchase order reference must be a string',
      required_error: 'Purchase order reference is required',
    })
    .refine((arg) => arg.length === 9 || arg.length === 11, {
      message:
        'Travis Perkins PO references must be 9 or 11 characters long',
    }),
};

export const mpMoranExtensions = {
  merchantOrderReference: z
    .string({
      invalid_type_error: 'Purchase order reference must be a string',
      required_error: 'Purchase order reference is required',
    })
    .refine((arg) => arg.length === 7, {
      message: 'MP Moran PO references must be 7 characters long',
    })
    .refine((arg) => arg.startsWith('5'), {
      message: 'MP Moran PO references must start with a 5',
    }),
};

export const CommonUpdateOrderSchema = CommonCreateOrderSchema.extend({
  orderId: z.string(),
  bookedBy: z.string().nullish(), // old orders have this as null
});

export const UpdateOrderAToBSchema = CommonUpdateOrderSchema.extend({
  orderId: z.string().optional(),
  type: z.literal('A_TO_B'),
  pickup: UpdatePickupStopSchema.extend({
    deliveryItems: z.array(UpdateDeliveryItemSchema).min(1, {
      message: 'At least one delivery item is required',
    }),
  }),
  dropOff: UpdateDropOffStopSchema,
});

export const UpdateOrderMultiCollectionSchema = CommonUpdateOrderSchema.extend({
  orderId: z.string().optional(),
  type: z.literal('MULTI_COLLECTION'),
  pickups: z
    .array(
      z.union([
        UpdatePickupStopSchema.extend({
          deliveryItems: z.array(UpdateDeliveryItemSchema).min(1, {
            message: 'At least one delivery item is required',
          }),
        }),
        CreatePickupStopSchema.extend({
          deliveryItems: z.array(CreateDeliveryItemSchema).min(1, {
            message: 'At least one delivery item is required',
          }),
        }),
      ]),
    )
    .min(2, {
      message: 'At least two pickups are required in multi-collection',
    }),
  dropOff: UpdateDropOffStopSchema,
});

export const UpdateOrderMultiDropSchema = CommonUpdateOrderSchema.extend({
  orderId: z.string().optional(),
  type: z.literal('MULTI_DROP'),
  pickup: UpdatePickupStopSchema,
  dropOffs: z
    .array(
      z.union([
        UpdateDropOffStopSchema.extend({
          deliveryItems: z.array(UpdateDeliveryItemSchema).min(1, {
            message: 'At least one delivery item is required',
          }),
        }),
        CreateDropOffStopSchema.extend({
          deliveryItems: z.array(CreateDeliveryItemSchema).min(1, {
            message: 'At least one delivery item is required',
          }),
        }),
      ]),
    )
    .min(2, { message: 'At least two drop-offs are required in multi-drop' }),
});

export const UpdateOrderSchema = z
  .discriminatedUnion('type', [
    UpdateOrderAToBSchema,
    UpdateOrderMultiCollectionSchema,
    UpdateOrderMultiDropSchema,
  ])
  .superRefine(({ merchantId, invoicingName }, refinementContext) => {
    if (!merchantId && !invoicingName) {
      return refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: `MerchantId or invoicingName required in create order`,
        path: ['order'],
      });
    }
  });

export const OrderSortByEnum = z.enum([
  'createdAt',
  'deliveredAt',
  'deliveryWindowEnd',
]);

/**
 * Query Params for querying orders
 */
export const OrdersArrayQueryParamsSchema = z.object({
  orderStatuses: buildArrayOrSingleSchema(OrderStatusSchema, false),
  riderIds: buildArrayOrSingleSchema(z.string(), false),
  deliveryVehicles: buildArrayOrSingleSchema(DeliveryVehicleIdSchema, false),
  merchantIds: buildArrayOrSingleSchema(z.string(), false),
});

export const OrdersStringQueryParamsSchema = z.object({
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  sortBy: OrderSortByEnum.optional(),
  merchantId: z.string().optional(),
  endUserId: z.string().optional(),
  merchantOrderReference: z.string().optional(),
  deliveryToday: z.preprocess(processStringBoolean, z.boolean().optional()),
  isUnassigned: z.preprocess(processStringBoolean, z.boolean().optional()),
  isOverRunning: z.preprocess(processStringBoolean, z.boolean().optional()),
  isOnJobBoard: z.preprocess(processStringBoolean, z.boolean().optional()),
  ...PaginationParamsSchema.shape,
});

export const OrdersQueryParamsSchema = z.object({
  ...OrdersArrayQueryParamsSchema.shape,
  ...OrdersStringQueryParamsSchema.shape,
});

export const AllDeliveryPricesQueryParamsSchema = z.object({
  fromPostcode: z.string().transform((arg) => arg.replace(/\s/g, '')),
  toPostcode: z.string().transform((arg) => arg.replace(/\s/g, '')),
  deliveryVehicle: DeliveryVehicleIdSchema,
  date: z.string().datetime(),
});

/**
 * Utils
 */
const getCreateOrderSchemaByType = (orderType: OrderType) => {
  switch (orderType) {
    case 'A_TO_B':
      return CreateOrderAToBSchema;
    case 'MULTI_COLLECTION':
      return CreateOrderMultiCollectionSchema;
    case 'MULTI_DROP':
      return CreateOrderMultiDropSchema;
    default:
      throw new Error('Invalid order type');
  }
};

export const buildCreateOrderSchema = (
  orderType: OrderType,
  merchantAccount?: { featureFlags?: FeatureFlags | null },
) => {
  const baseSchema = getCreateOrderSchemaByType(orderType);

  if (merchantAccount?.featureFlags?.travisPerkins) {
    return baseSchema.extend(travisPerkinsExtensions);
  }

  if (merchantAccount?.featureFlags?.mpMoran) {
    return baseSchema.extend(mpMoranExtensions);
  }

  return baseSchema;
};

/**
 * Types
 */
export type CreateOrder = z.infer<typeof CreateOrderSchema>;
export type CurrentOrder = Partial<CreateOrder>;
export type CommonCreateOrder = z.infer<typeof CommonCreateOrderSchema>;
export type CreateOrderAToB = z.infer<typeof CreateOrderAToBSchema>;
export type CreateOrderMultiCollection = z.infer<
  typeof CreateOrderMultiCollectionSchema
>;
export type CreateOrderMultiDrop = z.infer<typeof CreateOrderMultiDropSchema>;
export type UpdateOrder = z.infer<typeof UpdateOrderSchema>;
export type CommonUpdateOrder = z.infer<typeof CommonUpdateOrderSchema>;
export type UpdateOrderAToB = z.infer<typeof UpdateOrderAToBSchema>;
export type UpdateOrderMultiCollection = z.infer<
  typeof UpdateOrderMultiCollectionSchema
>;
export type UpdateOrderMultiDrop = z.infer<typeof UpdateOrderMultiDropSchema>;
export type Order = z.infer<typeof OrderSchema>;
export type OrderSortBy = z.infer<typeof OrderSortByEnum>;
export type OrderQueryParams = z.infer<typeof OrdersQueryParamsSchema>;
export type OrderStatus = z.infer<typeof OrderStatusSchema>;
export type OrderType = z.infer<typeof OrderTypeSchema>;
export type DeliveryWindow = z.infer<typeof DeliveryWindowSchema>;
export type GetAllDeliveryPricesParams = z.infer<
  typeof AllDeliveryPricesQueryParamsSchema
>;
