import React from 'react';
import { motion, Variants } from 'framer-motion';

interface Props {
  children: React.ReactNode;
}

export const PageTransition = ({ children }: Props) => {
  const pageVariants: Variants = {
    initial: {
      opacity: 0,
      x: 20,
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.35,
        ease: [0.25, 0.1, 0.25, 1],
      },
    },
    exit: {
      opacity: 0,
      x: -20,
      transition: {
        duration: 0.15,
        ease: [0.4, 0.0, 1, 1],
      },
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageVariants}
    >
      {children}
    </motion.div>
  );
};
