import { Box } from '@chakra-ui/react';
import { Map } from './Map';
import { MobileMapToggleButton } from './MobileMapToggleButton';
import { useState } from 'react';
import { Z_INDEX } from '../constants';
import { MapControlsContainerMobile } from './MapControlsContainerMobile';

export const MobileMapWithToggle: React.FC<{
  topPx?: number;
  zIndexButton?: number;
  zIndexMap?: number;
  mapProps?: React.ComponentProps<typeof Map>;
  controls?: React.ReactNode;
}> = ({
  topPx,
  mapProps,
  zIndexMap = Z_INDEX.MAP,
  zIndexButton = Z_INDEX.MAP_CONTROLS,
  controls,
}) => {
  const [showMobileMap, setShowMobileMap] = useState(false);

  return (
    <>
      <MobileMapToggleButton
        showMobileMap={showMobileMap}
        setShowMobileMap={setShowMobileMap}
        topPx={topPx}
        zIndex={zIndexButton}
      />
      {showMobileMap && (
        <Box
          h="100vh"
          w="100vw"
          position={'fixed'}
          top={0}
          left={0}
          zIndex={zIndexMap}
        >
          <Map {...mapProps} />
        </Box>
      )}
      {showMobileMap && (
        <MapControlsContainerMobile zIndex={zIndexButton}>
          {controls}
        </MapControlsContainerMobile>
      )}
    </>
  );
};

