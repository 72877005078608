import React from 'react';
import {
  Button,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { useDownloadMerchantOrders } from './useDownloadMerchantOrders';
import { useDownloadMerchantDeliveries } from './useDownloadMerchantDeliveries';
import { OrderQueryStore } from '../order';
import { DownloadMerchantOrdersCsvQuery } from '@tradeaze-packages/schemas';

type DownloadType = 'ORDERS' | 'DELIVERIES';

type DownloadMerchantCsvProps = {
  merchantId: string;
  orderQueryStore: OrderQueryStore;
} & React.ComponentProps<typeof Button>;

export const DownloadMerchantCsv: React.FC<DownloadMerchantCsvProps> = ({
  merchantId,
  orderQueryStore,
  ...props
}) => {
  const [downloadType, setDownloadType] =
    React.useState<DownloadType>('ORDERS');

  const downloadOrdersQuery = useDownloadMerchantOrders(merchantId);
  const downloadDeliveriesQuery = useDownloadMerchantDeliveries(merchantId);

  const queryParams: DownloadMerchantOrdersCsvQuery = {
    deliveryToday: orderQueryStore.deliveryToday,
    deliveryVehicles: orderQueryStore.deliveryVehicles,
    endDate: orderQueryStore.endDate,
    merchantOrderReference: orderQueryStore.merchantOrderReference,
    orderStatuses: orderQueryStore.orderStatuses,
    startDate: orderQueryStore.startDate,
    sortBy: orderQueryStore.sortBy,
  };

  const handleDownload = () => {
    if (downloadType === 'ORDERS') {
      downloadOrdersQuery.mutate(queryParams);
    } else {
      downloadDeliveriesQuery.mutate(queryParams);
    }
  };

  const isLoading =
    downloadOrdersQuery.isLoading || downloadDeliveriesQuery.isLoading;

  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} aria-label="Download Options" {...props}>
        <HStack>
          <DownloadIcon />
          <Text>Download CSV</Text>
        </HStack>
      </MenuButton>
      <MenuList minWidth="200px">
        <MenuOptionGroup
          title="Download Type"
          type="radio"
          value={downloadType}
          onChange={(value) => setDownloadType(value as DownloadType)}
        >
          <MenuItemOption value="ORDERS">Orders</MenuItemOption>
          <MenuItemOption value="DELIVERIES">Deliveries</MenuItemOption>
        </MenuOptionGroup>

        <MenuDivider />
        <Center>
          <Button
            width="90%"
            onClick={handleDownload}
            size="sm"
            isLoading={isLoading}
          >
            Download
          </Button>
        </Center>
      </MenuList>
    </Menu>
  );
};
