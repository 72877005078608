import { OrderType } from '@tradeaze-packages/schemas';

export type MeasurementUnit = 'mm' | 'cm' | 'm';

export const measurmentUnits = [
  {
    label: 'millimetres',
    value: 'mm',
  },
  {
    label: 'centimetres',
    value: 'cm',
  },
  {
    label: 'metres',
    value: 'm',
  },
];

export const REFRESH_INTERVAL_MS = 60 * 1000;

export const OrderTypeLabel: Record<OrderType, string> = {
  A_TO_B: 'A to B',
  MULTI_COLLECTION: 'Multi Collection',
  MULTI_DROP: 'Multi Drop',
};
