import React from 'react';

export const VanXLRearIcon: React.FC<{
  width?: number;
  height?: number;
  xArea?: number;
  yArea?: number;
  xPosition?: string;
  yPosition?: string;
}> = ({ height, width, xArea, xPosition, yArea, yPosition }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="50 0 259 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="175" y="10" fill="#232324" fontSize="14" fontFamily="Arial">
        Width
      </text>
      <text x="50" y="130" fill="#232324" fontSize="14" fontFamily="Arial">
        Height
      </text>
      <path
        d="M109.5 47L111.887 42L106.113 42L108.5 47L109.5 47ZM108.5 183L106.113 188L111.887 188L109.5 183L108.5 183ZM108.5 46.5L108.5 183.5L109.5 183.5L109.5 46.5L108.5 46.5Z"
        fill="#AF9032"
      />
      <path
        d="M246 24.5L251 26.8868V21.1132L246 23.5V24.5ZM138 23.5L133 21.1132V26.8868L138 24.5V23.5ZM246.5 23.5L137.5 23.5V24.5L246.5 24.5V23.5Z"
        fill="#AF9032"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M153.362 206.36C153.38 206.553 153.39 206.749 153.39 206.946V223.001C153.39 226.315 150.704 229.001 147.39 229.001H131C128.573 229.001 126.482 227.56 125.537 225.486C125.179 224.888 125 224.188 125 223.386V223.001V206.946V149.607L141.968 92.3381C142.258 91.1343 142.852 90.2314 143.751 89.6295C144.65 89.0275 145.651 88.7266 146.753 88.7266H238.117C239.003 88.7266 239.807 89.0576 240.529 89.7197C241.252 90.3819 241.753 91.2547 242.032 92.3381L259 149.607V206.933C259 206.937 259 206.942 259 206.946V223.001C259 223.006 259 223.01 259 223.015V223.386C259 224.188 258.821 224.888 258.463 225.486C257.518 227.56 255.428 229.001 253 229.001H236.61C233.296 229.001 230.61 226.315 230.61 223.001V206.946C230.61 206.749 230.62 206.553 230.638 206.36H153.362ZM250.299 142.384H133.701L147.623 95.9497H236.377L250.299 142.384ZM158.174 181.664C156.506 183.682 154.456 184.691 152.025 184.691C149.594 184.691 147.527 183.702 145.825 181.724C144.124 179.747 143.273 177.316 143.273 174.433C143.273 171.55 144.107 169.099 145.774 167.081C147.442 165.063 149.492 164.054 151.923 164.054C154.354 164.054 156.421 165.042 158.123 167.02C159.824 168.998 160.675 171.428 160.675 174.312C160.675 177.195 159.841 179.645 158.174 181.664ZM238.226 181.664C236.558 183.682 234.508 184.691 232.077 184.691C229.646 184.691 227.579 183.702 225.877 181.724C224.176 179.747 223.325 177.316 223.325 174.433C223.325 171.55 224.159 169.099 225.826 167.081C227.494 165.063 229.544 164.054 231.975 164.054C234.406 164.054 236.473 165.042 238.175 167.02C239.876 168.998 240.727 171.428 240.727 174.312C240.727 177.195 239.893 179.645 238.226 181.664ZM252.909 199.137H131.091V149.607H252.909V199.137Z"
        fill="#A7A7A8"
      />
      <rect
        x="129"
        y="40"
        width="126"
        height="152.341"
        rx="12"
        fill="#F9F9F9"
      />
      <rect
        x="129"
        y="40"
        width="126"
        height="152.341"
        rx="12"
        stroke="#A7A7A8"
        stroke-width="8"
      />
      <rect
        width="102"
        height="128.341"
        transform="translate(141 52)"
        fill="#D5D5D5"
      />
      <rect
        x={xPosition}
        y={yPosition}
        width={xArea}
        height={yArea}
        rx="4"
        fill="#AF9032"
      />
    </svg>
  );
};
