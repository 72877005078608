import { Suspense, lazy } from 'react';
import { Box, Spinner } from '@chakra-ui/react';

const Map = lazy(() => import('./Map').then(module => ({ 
  default: module.Map 
})));

const MapLoadingFallback = () => (
  <Box 
    w="full" 
    h="full" 
    display="flex" 
    bg="gray.100"
    alignItems="center" 
    justifyContent="center"
  >
    <Spinner size="xl" />
  </Box>
);

export const LazyMap = (props: React.ComponentProps<typeof Map>) => {
  return (
    <Suspense fallback={<MapLoadingFallback />}>
      <Map {...props} />
    </Suspense>
  );
}; 