import { Box, Text } from '@chakra-ui/react';

export const SummaryDisplayField: React.FC<{
  label: string;
  value?: string | number | React.ReactNode;
  suffix?: string;
}> = ({ label, value, suffix = '' }) => (
  <Box>
    <Text fontSize="sm" color="gray.600">
      {label}
    </Text>
    <Text fontSize="sm">
      {value ? (
        <>
          {value}
          {suffix}
        </>
      ) : (
        <Text as="span" color="red.500">
          Incomplete
        </Text>
      )}
    </Text>
  </Box>
);
