import React from 'react';
import {
  Button,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import {
  DeliveryStatus,
  DownloadAdminOrdersCsvQuery,
  OrderStatus,
} from '@tradeaze-packages/schemas';
import { useDownloadAdminOrders } from './useDownloadAdminOrders';
import { useDownloadAdminDeliveries } from './useDownloadAdminDeliveries';
import { OrderQueryStore } from '../order';

type DownloadType = 'ORDERS' | 'DELIVERIES';
type UserType = 'ADMIN' | 'MERCHANT';

type DownloadAdminCsvProps = {
  orderQueryStore: OrderQueryStore;
} & React.ComponentProps<typeof Button>;

const mapOrderStatusToDeliveryStatus = (orderStatus: OrderStatus) => {
  const deliveryStatus: Record<OrderStatus, DeliveryStatus> = {
    CANCELLED: 'CANCELLED',
    CONFIRMED: 'CONFIRMED',
    DELIVERED: 'DELIVERED',
    REJECTED: 'REJECTED',
    PENDING: 'PENDING',
  };

  return deliveryStatus[orderStatus];
};

export const DownloadAdminCsv: React.FC<DownloadAdminCsvProps> = ({
  orderQueryStore,
  ...props
}) => {
  const [downloadType, setDownloadType] =
    React.useState<DownloadType>('ORDERS');

  const [userType, setUserType] = React.useState<UserType>('ADMIN');

  const downloadOrdersQuery = useDownloadAdminOrders();
  const downloadDeliveriesQuery = useDownloadAdminDeliveries();

  const queryParams: DownloadAdminOrdersCsvQuery = {
    csvType: userType,
    deliveryToday: orderQueryStore.deliveryToday,
    deliveryVehicles: orderQueryStore.deliveryVehicles,
    endDate: orderQueryStore.endDate,
    merchantIds: orderQueryStore.merchantIds,
    orderStatuses: orderQueryStore.orderStatuses,
    riderIds: orderQueryStore.riderIds,
    startDate: orderQueryStore.startDate,
    merchantOrderReference: orderQueryStore.merchantOrderReference,
    sortBy: orderQueryStore.sortBy,
  };

  const handleDownload = () => {
    if (downloadType === 'ORDERS') {
      downloadOrdersQuery.mutate(queryParams);
    } else {
      downloadDeliveriesQuery.mutate({
        ...queryParams,
        deliveryStatuses: orderQueryStore.orderStatuses.map(
          mapOrderStatusToDeliveryStatus,
        ),
        csvType: userType,
      });
    }
  };

  const isLoading =
    downloadOrdersQuery.isLoading || downloadDeliveriesQuery.isLoading;

  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} aria-label="Download Options" {...props}>
        <HStack>
          <DownloadIcon />
          <Text>Download CSV</Text>
        </HStack>
      </MenuButton>
      <MenuList minWidth="200px">
        <MenuOptionGroup
          title="Download Type"
          type="radio"
          value={downloadType}
          onChange={(value) => setDownloadType(value as DownloadType)}
        >
          <MenuItemOption value="ORDERS">Orders</MenuItemOption>
          <MenuItemOption value="DELIVERIES">Deliveries</MenuItemOption>
        </MenuOptionGroup>

        <MenuDivider />
        <MenuOptionGroup
          title="CSV Type"
          type="radio"
          value={userType}
          onChange={(value) => setUserType(value as UserType)}
        >
          <MenuItemOption value="ADMIN">Admin</MenuItemOption>
          <MenuItemOption value="MERCHANT">Merchant</MenuItemOption>
        </MenuOptionGroup>

        <MenuDivider />
        <Center>
          <Button
            width="90%"
            onClick={handleDownload}
            size="sm"
            isLoading={isLoading}
          >
            Download CSV
          </Button>
        </Center>
      </MenuList>
    </Menu>
  );
};
