import { z } from 'zod';
import {
  FleetVehicleStatusSchema,
  HydratedFleetVehicleSchema,
} from '../../../models';
import { processStringBoolean } from '../../../utils';

export const GetFleetVehiclesQuerySchema = z.object({
  regNumber: z.string().optional(),
  includeDeleted: z.preprocess(processStringBoolean, z.boolean()).optional(),
  status: FleetVehicleStatusSchema.optional(),
  ownerName: z.string().optional(),
});

export const GetFleetVehiclesResponseSchema = z.array(
  HydratedFleetVehicleSchema,
);

export type GetFleetVehiclesQuery = z.infer<typeof GetFleetVehiclesQuerySchema>;
export type GetFleetVehiclesResponse = z.infer<
  typeof GetFleetVehiclesResponseSchema
>;
