import { Position } from '@tradeaze-packages/schemas';
import { z } from 'zod';
import axios from 'axios';
import { ExternalHttpError } from '../utils';

const GOOGLE_API_KEY = process.env['GOOGLE_API_KEY'] as string;

const BASE_URL = 'https://routes.googleapis.com';
const COMPUTE_ROUTES_PATH = '/directions/v2:computeRoutes';
const COMPUTE_ROUTES_URL = `${BASE_URL}${COMPUTE_ROUTES_PATH}`;

const buildGoogleLocation = (position: Position | string) => {
  return typeof position === 'string'
    ? { address: position }
    : {
        location: {
          latLng: {
            latitude: position.latitude,
            longitude: position.longitude,
          },
        },
      };
};

type RoutesHeaders = {
  'Content-Type': 'application/json';
  'X-Goog-FieldMask': string;
  'X-Goog-Api-Key': string;
};

type RoutesRequest = {
  origin: {
    sideOfRoad: boolean;
    vehicleStopover: boolean;
    address?: string;
    location?: {
      latLng: {
        latitude: number;
        longitude: number;
      };
    };
  };
  destination: {
    sideOfRoad: boolean;
    vehicleStopover: boolean;
    address?: string;
    location?: {
      latLng: {
        latitude: number;
        longitude: number;
      };
    };
  };
  intermediates?: Array<{
    sideOfRoad: boolean;
    vehicleStopover: boolean;
    address?: string;
    location?: {
      latLng: {
        latitude: number;
        longitude: number;
      };
    };
  }>;
  travelMode: 'DRIVE';
  routingPreference: 'TRAFFIC_AWARE' | 'TRAFFIC_UNAWARE';
  languageCode: string;
  regionCode: string;
  units: 'METRIC';
  optimizeWaypointOrder: boolean;
  departureTime?: string;
};

export type GoogleLocationArg = Position | string;

const GeocodedWaypointSchema = z.object({
  type: z.array(z.string()),
  partialMatch: z.boolean(),
  placeId: z.string(),
});

const GeocodingResultsSchema = z.object({
  origin: GeocodedWaypointSchema,
  destination: GeocodedWaypointSchema,
  intermediates: z.array(GeocodedWaypointSchema),
});

const GoogleRouteResponseSchema = z.object({
  geocodingResults: GeocodingResultsSchema.optional(),
  routes: z
    .array(
      z.object({
        legs: z.array(
          z.object({
            /**
             * When location is the same place between stops,
             * distanceMeters is not returned
             */
            distanceMeters: z.number().optional(),
            duration: z.preprocess(
              (val) =>
                typeof val === 'string' ? Number(val.replace('s', '')) : val,
              z.number(),
            ),
            startLocation: z.object({
              latLng: z.object({
                latitude: z.number(),
                longitude: z.number(),
              }),
            }),
            endLocation: z.object({
              latLng: z.object({
                latitude: z.number(),
                longitude: z.number(),
              }),
            }),
          }),
        ),
      }),
    )
    .min(1),
});

/**
 * If you give a postcode google cannot find, they will find the location
 * of the first part e.g. 'SW12 2RL' -> 'SW12'
 **/
const validateExactMatches = (geocodingResults: z.infer<typeof GeocodingResultsSchema>) => {
  if (!geocodingResults) return;
  
  const { origin, intermediates = [], destination } = geocodingResults;
  
  if ([origin, ...intermediates, destination].some(waypoint => waypoint?.partialMatch)) {
    throw new ExternalHttpError('One or more locations could not be exactly matched', 404);
  }
};

export const fetchGoogleDirectionsV2 = async ({
  origin,
  destination,
  waypoints,
  departureTime,
  trafficAware,
  options,
}: {
  origin: GoogleLocationArg;
  destination: GoogleLocationArg;
  waypoints?: GoogleLocationArg[];
  trafficAware: boolean;
  // required if trafficAware is true
  departureTime?: Date;
  options?: {
    requireExactMatch?: boolean;
  };
}) => {
  const originLocation = buildGoogleLocation(origin);
  const destinationLocation = buildGoogleLocation(destination);
  const waypointsLocations = waypoints?.map((waypoint) =>
    buildGoogleLocation(waypoint),
  );

  const request: RoutesRequest = {
    origin: {
      sideOfRoad: true,
      vehicleStopover: true,
      ...originLocation,
    },
    destination: {
      sideOfRoad: true,
      vehicleStopover: true,
      ...destinationLocation,
    },
    intermediates: waypointsLocations?.map((waypoint) => ({
      sideOfRoad: true,
      vehicleStopover: true,
      ...waypoint,
    })),
    travelMode: 'DRIVE',
    routingPreference: trafficAware ? 'TRAFFIC_AWARE' : 'TRAFFIC_UNAWARE',
    languageCode: 'en-GB',
    regionCode: 'GB',
    units: 'METRIC',
    optimizeWaypointOrder: false,
    departureTime: departureTime?.toISOString(),
  };

  const headers: RoutesHeaders = {
    'X-Goog-FieldMask':
      'routes.legs.duration,routes.legs.distanceMeters,routes.legs.startLocation.latLng,routes.legs.endLocation.latLng',
    'Content-Type': 'application/json',
    'X-Goog-Api-Key': GOOGLE_API_KEY,
  };

  const response = await axios
    .post(COMPUTE_ROUTES_URL, request, {
      headers,
    })
    .catch((error) => {
      console.error('Error fetching Google Directions', error.response.data);
      throw new Error('Error fetching Google Directions');
    });

  const parsedResponse = GoogleRouteResponseSchema.safeParse(response.data);

  if (!parsedResponse.success) {
    console.error(
      'Failed to parse response from Google',
      JSON.stringify(response.data, null, 2),
    );
    throw new Error('Failed to parse response from Google');
  }

  if (options?.requireExactMatch && parsedResponse.data.geocodingResults) {
    validateExactMatches(parsedResponse.data.geocodingResults);
  }

  return parsedResponse.data;
};
