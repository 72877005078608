import React from 'react';

export const CarRearIcon: React.FC<{
  width?: number;
  height?: number;
  xArea?: number;
  yArea?: number;
  xPosition?: string;
  yPosition?: string;
}> = ({ height, width, xArea, xPosition, yArea, yPosition }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="50 0 242 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="165" y="10" fill="#232324" fontSize="12" fontFamily="Arial">
        Width
      </text>
      <text x="60" y="100" fill="#232324" fontSize="12" fontFamily="Arial">
        Height
      </text>
      <path
        d="M107.5 53L109.887 48L104.113 48L106.5 53L107.5 53ZM106.5 133L104.113 138L109.887 138L107.5 133L106.5 133ZM106.5 52.5L106.5 133.5L107.5 133.5L107.5 52.5L106.5 52.5Z"
        fill="#AF9032"
      />
      <path
        d="M229 24.5L234 26.8868V21.1132L229 23.5V24.5ZM136 23.5L131 21.1132V26.8868L136 24.5V23.5ZM229.5 23.5L135.5 23.5V24.5L229.5 24.5V23.5Z"
        fill="#AF9032"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M148.174 155.726C148.199 155.947 148.212 156.172 148.212 156.399V171.999C148.212 175.313 145.526 177.999 142.212 177.999H129C126.83 177.999 124.928 176.847 123.875 175.121C123.84 175.084 123.807 175.046 123.778 175.007C123.259 174.326 123 173.482 123 172.476V171.999V156.399V99.8932L138.068 43.553C138.326 42.3687 138.854 41.4804 139.652 40.8882C140.451 40.2961 141.339 40 142.318 40H223.455C224.241 40 224.955 40.3257 225.597 40.9771C226.238 41.6284 226.683 42.4871 226.932 43.553L242 99.8932V156.399V171.999V172.476C242 173.482 241.741 174.326 241.222 175.007C241.184 175.057 241.145 175.106 241.106 175.152C240.048 176.861 238.157 177.999 236 177.999H222.788C219.474 177.999 216.788 175.313 216.788 171.999V156.399C216.788 156.172 216.801 155.947 216.826 155.726H148.174ZM234.273 92.7872H130.727L143.091 47.106H221.909L234.273 92.7872ZM152.46 131.43C150.979 133.415 149.159 134.408 147 134.408C144.841 134.408 143.006 133.435 141.494 131.489C139.983 129.544 139.227 127.153 139.227 124.316C139.227 121.48 139.968 119.069 141.449 117.083C142.93 115.098 144.75 114.105 146.909 114.105C149.068 114.105 150.903 115.078 152.415 117.024C153.926 118.969 154.682 121.36 154.682 124.197C154.682 127.033 153.941 129.444 152.46 131.43ZM223.551 131.43C222.07 133.415 220.25 134.408 218.091 134.408C215.932 134.408 214.097 133.435 212.585 131.489C211.074 129.544 210.318 127.153 210.318 124.316C210.318 121.48 211.059 119.069 212.54 117.083C214.021 115.098 215.841 114.105 218 114.105C220.159 114.105 221.994 115.078 223.506 117.024C225.017 118.969 225.773 121.36 225.773 124.197C225.773 127.033 225.032 129.444 223.551 131.43ZM236.591 148.62H128.409V99.8932H236.591V148.62Z"
        fill="#A7A7A8"
      />
      <rect x="127" y="44" width="111" height="98" rx="12" fill="#F9F9F9" />
      <rect
        x="127"
        y="44"
        width="111"
        height="98"
        rx="12"
        stroke="#A7A7A8"
        stroke-width="8"
      />
      <rect x="139" y="56" width="87" height="74" rx="4" fill="#D5D5D5" />
      <rect
        x={xPosition}
        y={yPosition}
        width={xArea}
        height={yArea}
        rx="4"
        fill="#AF9032"
      />
    </svg>
  );
};
