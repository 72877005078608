import React from 'react';
import { Box, Grid, GridItem, Heading, Stack, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import {
  brandPrimaryColor,
  brandSecondaryColor,
  CreateOrderImage,
  HiabImage,
  Layout,
  ManVanImage,
  OrdersImage,
  AddressBookImage,
  AccountImage,
  CalculatorImage,
  HomePageCard,
} from '@tradeaze/frontend/ui-web';
import { useGetMerchantAccount } from '@tradeaze/frontend/hooks';
import { useAuthContext } from '../components/auth/AuthProvider';
import { usePricingCalculator } from '../components/delivery-pricing/PricingCalculatorProvider';

interface DashboardTileProps {
  title: string | React.ReactNode;
  description: string;
  to?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  isBeta?: boolean;
}

interface DashboardTileConfig {
  title: string;
  description: string;
  to?: string;
  onClick?: () => void;
  isBeta?: boolean;
  dataCy: string;
  image?: React.ReactNode;
}

const BetaLabel = () => (
  <Text
    as="span"
    fontSize="xs"
    bg="blue.100"
    color="blue.700"
    px={2}
    py={1}
    borderRadius="full"
    fontWeight="medium"
  >
    BETA
  </Text>
);

const getDashboardTiles = ({
  onOpenDeliveryPricingModal,
}: {
  onOpenDeliveryPricingModal: () => void;
}): DashboardTileConfig[] => {
  return [
    {
      title: 'Create an Order',
      description:
        'Create a new delivery order for your customers. A to B, Multi-Collection, or Multi-Drop.',
      to: '/create-order',
      dataCy: 'home-page-create-order-button',
      image: <CreateOrderImage />,
    },
    {
      title: 'Existing Orders',
      description:
        'View and manage all your past and current delivery bookings',
      to: '/orders',
      dataCy: 'home-page-orders-button',
      image: <OrdersImage />,
    },
    {
      title: 'Address Book',
      description:
        'Manage your customer addresses and contact details for faster order creation',
      to: '/address-book',
      dataCy: 'home-page-address-book-button',
      image: <AddressBookImage />,
    },
    {
      title: 'Pricing Calculator',
      description:
        'Calculate estimated delivery costs based on distance and vehicle type',
      onClick: onOpenDeliveryPricingModal,
      dataCy: 'home-page-pricing-calculator-button',
      image: <CalculatorImage />,
    },
    {
      title: 'ManVan Enquiry',
      description: 'Rent a van and driver out on a day rate',
      to: '/man-van-hire',
      dataCy: 'home-page-man-van-enquiry-button',
      isBeta: true,
      image: <ManVanImage />,
    },
    {
      title: 'HIAB Enquiry',
      description: 'Request a truck with a mounted crane for heavy lifting',
      to: '/hiab-request',
      dataCy: 'home-page-hiab-request-button',
      isBeta: true,
      image: <HiabImage />,
    },
    {
      title: 'Account Settings',
      description:
        'View and manage your account details, contact information and preferences',
      to: '/account',
      dataCy: 'home-page-account-button',
      image: <AccountImage />,
    },
  ];
};

const DashboardTile = ({
  title,
  description,
  to,
  onClick,
  children,
  isBeta,
}: DashboardTileProps) => {
  const displayTitle = isBeta ? (
    <Box display="flex" alignItems="center" gap={2}>
      {title}
      <BetaLabel />
    </Box>
  ) : (
    title
  );

  return (
    <HomePageCard as={NavLink} to={to} onClick={onClick}>
      <Stack justifyContent="space-between" height="100%">
        <Box>
          <Heading size="md" mb={2} color="gray.800">
            {displayTitle}
          </Heading>
          <Text fontSize="sm" color="gray.500" mb={4}>
            {description}
          </Text>
        </Box>
        <Box>{children}</Box>
      </Stack>
    </HomePageCard>
  );
};

const getTimeBasedGreeting = () => {
  const hour = new Date().getHours();

  if (hour < 12) {
    return 'Good Morning!';
  } else if (hour < 17) {
    return 'Good Afternoon!';
  } else {
    return 'Good Evening!';
  }
};

export const HomePage = () => {
  const auth = useAuthContext();
  const merchantQuery = useGetMerchantAccount(auth.cognitoUserId);
  const greeting = getTimeBasedGreeting();
  const { openDeliveryPricingModal } = usePricingCalculator();

  return (
    <Layout isAuthScreen={false} lowPadding>
      <Box
        display="flex"
        alignItems="flex-start"
        py={{ base: 3, md: 6 }}
        px={{ base: 3, md: 6 }}
        minH="calc(100vh - 64px)"
      >
        <Box maxW="1600px" mx="auto" w="100%">
          <Box textAlign={{ base: 'center', md: 'left' }} mb={6}>
            <Heading size="xl" color={brandSecondaryColor} data-cy="greeting">
              {greeting}
            </Heading>
            <Heading
              size="md"
              color={brandPrimaryColor}
              mt={1}
              data-cy="merchant-name"
            >
              {merchantQuery?.data?.merchantName}
            </Heading>
          </Box>

          <Grid
            templateColumns={{
              base: '1fr',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
            }}
            gap={4}
            w="100%"
          >
            {getDashboardTiles({
              onOpenDeliveryPricingModal: openDeliveryPricingModal,
            }).map((tile, index) => (
              <GridItem key={tile.dataCy} h="100%">
                <DashboardTile
                  title={tile.title}
                  description={tile.description}
                  to={tile.to}
                  onClick={tile.onClick}
                  data-cy={tile.dataCy}
                  isBeta={tile.isBeta}
                >
                  {tile.image && (
                    <Box position="relative" height={'140px'} mt={2}>
                      {React.cloneElement(tile.image as React.ReactElement, { animationDelay: index * 0.1 })}
                    </Box>
                  )}
                </DashboardTile>
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};
