import { Icon } from '@chakra-ui/react';
import React from 'react';

export const NotificationIcon: React.FC<React.ComponentProps<typeof Icon> & { 
  count?: number 
  }>  = ({ count, ...rest }) => {
  const size = count ? "8" : "3";
  return (
    <Icon boxSize={size} viewBox='0 0 200 200' color='red.500' {...rest}>
      <path
        fill='currentColor'
        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
      />
      { (count && count > 0) && <text x="100" y="125" fontSize="75" fontWeight="bold" textAnchor="middle" fill="white">{count}</text>}
    </Icon>
  );
};
