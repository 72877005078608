import { Box, Button, Tooltip, Stack } from '@chakra-ui/react';
import { useGetOrderCounts } from '@tradeaze/frontend/hooks';
import {
  FaUserSlash,
  FaExclamationTriangle,
  FaClipboardList,
} from 'react-icons/fa';

interface OrderPriorityFilters {
  isUnassigned: boolean;
  isOverRunning: boolean;
  isOnJobBoard: boolean;
  deliveryToday: boolean;
  handleToggleUnassigned: () => void;
  handleToggleOverRunning: () => void;
  handleToggleOnJobBoard: () => void;
  size?: 'sm' | 'md';
  stackDirection?: 'row' | 'column';
}

interface FilterButtonProps {
  isActive: boolean;
  colorScheme: string;
  icon: React.ReactElement;
  label: string;
  count?: number;
  onClick: () => void;
  tooltipLabel: string;
}

const FilterButton: React.FC<
  FilterButtonProps & React.ComponentProps<typeof Button>
> = ({
  isActive,
  colorScheme,
  icon,
  label,
  count,
  onClick,
  tooltipLabel,
  size,
  ...props
}) => (
  <Tooltip label={tooltipLabel} placement="top">
    <Button
      size={size}
      variant={isActive ? 'solid' : 'outline'}
      colorScheme={colorScheme}
      leftIcon={icon}
      onClick={onClick}
      _hover={{ opacity: 0.8 }}
      minW="auto"
      flexShrink={0}
      {...props}
    >
      <Box as="span" display="flex" alignItems="center" whiteSpace="nowrap">
        {label}
        {count ? (
          <Box
            as="span"
            ml={2}
            bg={isActive ? 'white' : `${colorScheme}.500`}
            color={isActive ? `${colorScheme}.500` : 'white'}
            borderRadius="full"
            px={2}
            fontSize="xs"
            fontWeight="bold"
            display="inline-flex"
            alignItems="center"
          >
            {count}
          </Box>
        ) : null}
      </Box>
    </Button>
  </Tooltip>
);

const ONE_MINUTE_MS = 60 * 1000;

export const OrderPriorityFilters: React.FC<OrderPriorityFilters> = ({
  isUnassigned,
  isOverRunning,
  isOnJobBoard,
  deliveryToday,
  handleToggleUnassigned,
  handleToggleOverRunning,
  handleToggleOnJobBoard,
  size = 'sm',
  stackDirection = 'row',
}) => {
  const countsQuery = useGetOrderCounts(
    { deliveryToday },
    {
      refetchInterval: ONE_MINUTE_MS,
    },
  );

  const counts = countsQuery.data;

  const filterButtons = [
    {
      isActive: isUnassigned,
      colorScheme: 'orange',
      icon: <FaUserSlash />,
      label: 'Unassigned',
      count: counts?.unassignedCount,
      onClick: handleToggleUnassigned,
      tooltipLabel: 'Show Unassigned Orders',
    },
    {
      isActive: isOverRunning,
      colorScheme: 'red',
      icon: <FaExclamationTriangle />,
      label: 'Overrunning',
      count: counts?.overRunningCount,
      onClick: handleToggleOverRunning,
      tooltipLabel: 'Show Overrunning Orders',
    },
    {
      isActive: isOnJobBoard,
      colorScheme: 'blue',
      icon: <FaClipboardList />,
      label: 'Job Board',
      count: counts?.onJobBoardCount,
      onClick: handleToggleOnJobBoard,
      tooltipLabel: 'Show Job Board Orders',
    },
  ];

  return (
    <Stack
      spacing={2}
      direction={stackDirection}
      overflowX="auto"
      flexWrap="nowrap"
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {filterButtons.map((buttonProps) => (
        <FilterButton
          key={buttonProps.label}
          {...{
            ...buttonProps,
            size,
          }}
        />
      ))}
    </Stack>
  );
};
