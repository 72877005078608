import { UseFormReturn } from 'react-hook-form';
import { CreateOrder } from '@tradeaze-packages/schemas';

type Form = UseFormReturn<CreateOrder>;

export const isInvoicingSectionComplete = (form: Form) => {
  const invoicingName = form.watch('invoicingName');
  const invoicingEmail = form.watch('invoicingEmail');

  return (
    Boolean(invoicingName) &&
    !form.getFieldState('invoicingName').invalid &&
    Boolean(invoicingEmail) &&
    !form.getFieldState('invoicingEmail').invalid
  );
};
