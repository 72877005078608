import { extractFormValidationErrorMessages } from '../utils';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import {
  CreateDeliveryItem,
  CreateDeliveryStop,
  CreateOrder,
  CreateOrderMultiCollection,
} from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { handleError } from '@tradeaze/frontend/utils';

type UseMultiCollectionFormSubmission = {
  pickupForm: UseFormReturn<CreateDeliveryStop>;
  dropOffForm: UseFormReturn<CreateDeliveryStop>;
  itemForm: UseFormReturn<CreateDeliveryItem>;
  form: UseFormReturn<CreateOrder>;
  resetForms: () => void;
  selectedPickupIndex: number | null;
  setSelectedPickupIndex: (value: number | null) => void;
  setShowPickupSection: (value: boolean) => void;
  multiCollectionDetails: CreateOrderMultiCollection;
  onSubmit: (order: CreateOrder) => void;
  setShowMainBackButton?: (value: boolean) => void;
};

export const useMultiCollectionFormSubmission = ({
  pickupForm,
  dropOffForm,
  itemForm,
  form,
  resetForms,
  selectedPickupIndex,
  setSelectedPickupIndex,
  setShowPickupSection,
  multiCollectionDetails,
  onSubmit,
  setShowMainBackButton,
}: UseMultiCollectionFormSubmission) => {
  const { deliveryWindowStart, deliveryWindowEnd, pickups } =
    multiCollectionDetails;
  const currentPickups = form.getValues('pickups') || [];

  const validatePickupForm = useCallback(async () => {
    const validationErrors = [];
    const isPickupValid = await pickupForm.trigger();
    if (!isPickupValid) {
      validationErrors.push(pickupForm.formState.errors);
    }
    const isItemValid = await itemForm.trigger();
    if (!isItemValid) {
      validationErrors.push(itemForm.formState.errors);
    }

    const errorMessages = extractFormValidationErrorMessages(validationErrors);

    if (errorMessages.length > 0) {
      toast.error(
        `Please ensure all fields are valid: ${errorMessages.join(', ')}`,
      );
      return false;
    }
    return true;
  }, [pickupForm, itemForm]);

  const validateForm = useCallback(async () => {
    const validationErrors = [];
    const isDropOffValid = await dropOffForm.trigger();
    if (!isDropOffValid) {
      validationErrors.push(dropOffForm.formState.errors);
    }
    const isFormValid = await form.trigger();
    if (!isFormValid) {
      validationErrors.push(form.formState.errors);
    }

    const errorMessages = extractFormValidationErrorMessages(validationErrors);

    if (errorMessages.length > 0) {
      toast.error(
        `Please ensure all fields are valid: ${errorMessages.join(', ')}`,
      );
      return false;
    }
    return true;
  }, [dropOffForm, form]);

  const submitPickupAndItem = async (index: number) => {
    const pickupData = pickupForm.getValues();
    const itemData = itemForm.getValues();

    const updatedPickup = {
      ...currentPickups[index],
      ...pickupData,
      collectionReference: pickupData.collectionReference ?? '',
      type: 'PICK_UP' as const,
      deliveryItems: [itemData],
    };

    currentPickups[index] = updatedPickup;
    form.setValue('pickups', currentPickups);
  };

  const handleAddPickup = async () => {
    try {
      const isValidated = await validatePickupForm();
      if (!isValidated) return;

      const newPickup = {
        type: 'PICK_UP' as const,
        stopSequence: currentPickups.length + 2,
        companyName: '',
        addressLine1: '',
        postCode: '',
        city: '',
        windowStart: '',
        windowEnd: '',
        contacts: [
          {
            contactName: '',
            contactNumber: '',
            trackingEnabled: false,
            isPrimary: true,
          },
        ],
        deliveryItems: [],
        collectionReference: '',
      };

      currentPickups.push(newPickup);
      const newIndex = currentPickups.length - 1;

      await submitPickupAndItem(newIndex);

      resetForms();
      setShowPickupSection(false);
      setShowMainBackButton?.(true);
    } catch (error) {
      console.error('Error in form submission:', error);
    }
  };

  const handleUpdatePickup = async () => {
    if (selectedPickupIndex === null) return;

    const isValidated = await validatePickupForm();
    if (!isValidated) return;
    await submitPickupAndItem(selectedPickupIndex);
    setShowPickupSection(false);
    setShowMainBackButton?.(true);
    resetForms();
    setSelectedPickupIndex(null);
  };

  const handleSubmit = async () => {
    const isValidated = await validateForm();
    if (!isValidated) return;

    await dropOffForm.handleSubmit((data) => {
      const { id, ...rest } = data;
      const modifiedDropOffData = {
        ...rest,
        windowStart: deliveryWindowStart,
        windowEnd: deliveryWindowEnd,
        stopSequence: pickups?.length + 1,
        type: 'DROP_OFF' as const,
        addressId: data.addressId === '' ? null : data.addressId,
      };
      form.setValue('dropOff', modifiedDropOffData);
      pickups?.forEach((pickup, index) => {
        const { id, ...rest } = pickup;
        const modifiedPickupData = {
          ...rest,
          windowStart: deliveryWindowStart,
          windowEnd: deliveryWindowEnd,
          type: 'PICK_UP' as const,
          addressId: pickup.addressId === '' ? null : pickup.addressId,
          stopSequence: index + 1,
        };
        form.setValue(`pickups.${index}`, modifiedPickupData);
      });
      form.handleSubmit(
        (data) => {
          onSubmit(data);
        },
        (formErrors) => {
          handleError(new Error('Form submission error (useMultiCollectionFormSubmission)'), {
            sendToSentry: true,
            showNotification: true,
            consoleLog: true,
            notificationMessage: 'Please ensure all fields are valid',
            sentryContext: {
              formErrors,
            },
          });
        },
      )();
    })();
  };

  return {
    handleAddPickup,
    handleUpdatePickup,
    handleSubmit,
  };
};
