import { z } from 'zod';

export const CognitoJwtPayloadSchema = z.object({
  sub: z.string(),
  email_verified: z.boolean(),
  iss: z.string(),
  'cognito:username': z.string(),
  origin_jti: z.string(),
  aud: z.string(),
  event_id: z.string(),
  token_use: z.string(),
  auth_time: z.number(),
  exp: z.number(),
  iat: z.number(),
  jti: z.string(),
  email: z.string(),
});

export const UserTypeSchema = z.enum([
  'MERCHANT',
  'ADMIN',
  'RIDER',
  'UNKNOWN',
]);

export const UserPoolConfigSchema = z.object({
  region: z.string(),
  userPoolId: z.string(),
});

export const UserContextSchema = z.object({
  userId: z.string(),
  username: z.string(),
  email: z.string(),
  userPoolId: z.string(),
  userType: UserTypeSchema,
});

export type CognitoJwtPayload = z.infer<typeof CognitoJwtPayloadSchema>;
export type UserType = z.infer<typeof UserTypeSchema>;
export type UserPoolConfig = z.infer<typeof UserPoolConfigSchema>;
export type UserContext = z.infer<typeof UserContextSchema>; 