import { z } from "zod";

export const VehicleCategoryIdSchema = z.enum(['BIKE', 'CAR', 'VAN', 'LUTON', 'FLATBED', 'HIAB', 'LORRY']);

export const VehicleCategorySchema = z.object({
  id: VehicleCategoryIdSchema,
  name: z.string(),
  description: z.string().nullish(),
});

export type VehicleCategory = z.infer<typeof VehicleCategorySchema>;
export type VehicleCategoryId = z.infer<typeof VehicleCategoryIdSchema>;
