import { IconButton, Tooltip } from '@chakra-ui/react';
import { IoRefresh } from 'react-icons/io5';

type RefreshButtonProps = {
  isRefreshing?: boolean;
  onRefresh: () => void;
  showLabel?: boolean;
};

export const RefreshButton = ({ isRefreshing, onRefresh }: RefreshButtonProps) => {
  return (
    <Tooltip label="Refresh all data" placement="right" hasArrow>
      <IconButton
        icon={<IoRefresh />}
        aria-label="Refresh data"
        variant="ghost"
        size="md"
        fontSize={26}
        isLoading={isRefreshing}
        onClick={onRefresh}
        color="whiteAlpha.600"
        _hover={'none'}
      />
    </Tooltip>
  );
}; 