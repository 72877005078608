import { HStack, Tag, Text } from '@chakra-ui/react';
import { IoWarning } from 'react-icons/io5';

export const LateEtaTag = () => {
    return (
      <Tag
        width={'fit-content'}
        colorScheme={'red'}
        paddingY={0}
        paddingX={1
          
        }
        borderRadius={'5px'}
        borderWidth={'1px'}
      >
        <Text lineHeight={'1.2em'} fontSize={'0.8em'}>
          <HStack spacing={0.5}>
            <IoWarning fontSize={'1.2em'} />
            <Text>ETA</Text>
          </HStack>
        </Text>
      </Tag>
    );
  };