import { Card, CardBody, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { CreateDeliveryStop, OrderType } from '@tradeaze-packages/schemas';
import { formatAddressDisplayName } from '@tradeaze/shared/utils';
import { FaLocationArrow, FaTag, FaUser, FaBuilding } from 'react-icons/fa';

const AddressCardIcon = ({ icon }: { icon: React.ElementType }) => {
  return <Icon as={icon} color="gray.600" height={2.5} width={2.5} />;
};

const IncompleteText = () => {
  return (
    <Text as="span" color="red.500">
      Incomplete
    </Text>
  );
};

export const SummaryAddressCard: React.FC<{
  stop: CreateDeliveryStop;
  orderType: OrderType;
}> = ({ stop, orderType }) => {
  const primaryContact =
    stop.contacts.find((contact) => contact.isPrimary) || stop.contacts[0];

  const showCollectionRef =
    (orderType === 'MULTI_DROP' && stop.type === 'DROP_OFF') ||
    (orderType === 'MULTI_COLLECTION' && stop.type === 'PICK_UP') ||
    (orderType === 'A_TO_B' && stop.type === 'PICK_UP');

  return (
    <Card variant="outline" size="sm">
      <CardBody>
        <VStack align="stretch" spacing={1}>
          <HStack>
            <AddressCardIcon icon={FaBuilding} />
            <Text fontSize="sm">
              {stop.companyName || <IncompleteText />}
            </Text>
          </HStack>
          <HStack>
            <AddressCardIcon icon={FaLocationArrow} />
            <Text fontSize="sm">
              {stop.addressLine1 && stop.postCode && stop.city ? (
                formatAddressDisplayName({
                  addressLine1: stop.addressLine1,
                  postCode: stop.postCode,
                  city: stop.city,
                })
              ) : (
                <IncompleteText />
              )}
            </Text>
          </HStack>
          {primaryContact && (
            <HStack>
              <AddressCardIcon icon={FaUser} />
              <Text fontSize="sm">
                {primaryContact.contactName}, {primaryContact.contactNumber}
              </Text>
            </HStack>
          )}
          {showCollectionRef ? (
            <HStack>
              <AddressCardIcon icon={FaTag} />
              <Text fontSize="sm">
                {stop.collectionReference || <IncompleteText />}
              </Text>
            </HStack>
          ) : null}
        </VStack>
      </CardBody>
    </Card>
  );
};
