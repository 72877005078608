import { z } from 'zod';
import { OrdersQueryParamsSchema } from '../../../models';
import { MerchantOrdersCsvColumns } from './download-merchant-orders-csv-schema';

const CsvTypeSchema = z.enum(['ADMIN', 'MERCHANT']);

export const DownloadAdminOrdersCsvQuerySchema = OrdersQueryParamsSchema.omit({
  startIndex: true,
  limit: true,
}).extend({
  csvType: CsvTypeSchema,
});

export const DownloadAdminOrdersCsvResponseSchema = z.object({
  url: z.string(),
});

export type DownloadAdminOrdersCsvQuery = z.infer<
  typeof DownloadAdminOrdersCsvQuerySchema
>;

export type DownloadAdminOrdersCsvResponse = z.infer<
  typeof DownloadAdminOrdersCsvResponseSchema
>;

export type AdminOrdersCsvColumns = MerchantOrdersCsvColumns & {
  'Merchant ID': string;
  'Invoicing Name': string;
  'Invoicing Email': string;
};
