import { HStack, StackProps } from '@chakra-ui/react';
import { NAVBAR_WIDTH_PX } from '../../navbar';
import { ReactNode } from 'react';
import { Z_INDEX } from '../../constants';

interface OrderQueryOptionsBarContainerProps extends StackProps {
  children: ReactNode;
}

export const OrderQueryOptionsBarContainer: React.FC<OrderQueryOptionsBarContainerProps> = ({ children, ...props }) => {
  return (
    <HStack
      position={'fixed'}
      top={0}
      right={0}
      zIndex={Z_INDEX.ORDER_DASHBOARD_CONTROLS}
      bg={'rgba(255, 255, 255, 0.1)'}
      backdropFilter={'blur(8px)'}
      backgroundColor={'rgba(255, 255, 255, 0.1)'}
      borderBottom={'1px solid rgba(255, 255, 255, 0.1)'}
      minHeight={12}
      width={`calc(100vw - ${NAVBAR_WIDTH_PX})`}
      shadow={'md'}
      alignItems={'center'}
      paddingX={4}
      spacing={2}
      {...props}
    >
      {children}
    </HStack>
  );
}; 