import { z } from 'zod';

export const DownloadAdminRidersCsvQuerySchema = z.object({});

export const DownloadAdminRidersCsvResponseSchema = z.object({
  url: z.string(),
});

export type DownloadAdminRidersCsvQuery = z.infer<
  typeof DownloadAdminRidersCsvQuerySchema
>;

export type DownloadAdminRidersCsvResponse = z.infer<
  typeof DownloadAdminRidersCsvResponseSchema
>;

export type AdminRidersCsvColumns = {
  // Basic identification
  'Rider ID': string;
  Username: string;
  'First Name': string | null | undefined;
  'Last Name': string | null | undefined;

  // Contact information
  Email: string | null | undefined;
  'Contact Number': string | null | undefined;

  // Address details
  Address: string | null | undefined;
  City: string | null | undefined;
  Postcode: string | null | undefined;

  // Status and timestamps
  Status: string;
  'Created At': string | null | undefined;
  'Approved At': string;
  'Rejected At': string;
  'Deleted At': string;

  // Work details
  'Work Type': string;
  'Delivery Vehicle': string;
  'Can Work Shifts': boolean;
  'Last Clocked In': string;

  // Banking and financial
  'Account Type': string;
  'Name on Account': string;
  'Account Number': string;
  'Sort Code': string;
  'VAT Number': string;
};
