import { z } from 'zod';
import { ClockInWorkTypeSchema, HydratedRiderSchema, RiderStatusSchema } from '../../../models';
import { processStringBoolean } from '../../../utils';

export const GetRidersQuerySchema = z.object({
  withLocation: z.preprocess(processStringBoolean, z.boolean()).optional(),
  withLastUsedVehicle: z.preprocess(processStringBoolean, z.boolean()).optional(),
  withOwnedVehicles: z.preprocess(processStringBoolean, z.boolean()).optional(),
  withBankDetails: z.preprocess(processStringBoolean, z.boolean()).optional(),
  includeDeleted: z.preprocess(processStringBoolean, z.boolean()).optional(),
  riderName: z.string().optional(),
  includeUnassignable: z
    .preprocess(processStringBoolean, z.boolean())
    .optional(),
  workType: ClockInWorkTypeSchema.optional(),
  status: RiderStatusSchema.optional(),
});

export const GetRidersResponseSchema = z.array(HydratedRiderSchema);

export type GetRidersQuery = z.infer<typeof GetRidersQuerySchema>;
export type GetRidersResponse = z.infer<typeof GetRidersResponseSchema>;
