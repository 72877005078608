import {
  DeliveryVehicleIdSchema,
  FuelTypeSchema,
} from '../models/delivery-vehicle-schema';
import { DeliveryVehicleId } from '../models/delivery-vehicle-schema';
import { RiderDocumentTypeId } from '../models/rider-document-schema';
import {
  VehicleCategoryId,
  VehicleCategoryIdSchema,
} from '../models/vehicle-category-schema';
import { SERVICE_CHARGE } from './pricing';

export type VehicleCategoryConfig = {
  id: VehicleCategoryId;
  name: string;
  description: string;
};

export type VehicleConfig = {
  id: DeliveryVehicleId;
  name: string;
  category: VehicleCategoryId;
  description: string;
  lengthCapacityMetres: number;
  widthCapacityMetres: number;
  heightCapacityMetres: number;
  weightCapacityKg: number;
  pricingMultiplier: number;
  minimumTotalPrice: number;
  documentRequirements: {
    documentTypeId: RiderDocumentTypeId;
    isRequired: boolean;
  }[];
};

export const ALL_VEHICLE_CATEGORY_IDS = Object.values(
  VehicleCategoryIdSchema.Values,
);

export const VEHICLE_CATEGORY_CONFIGS: VehicleCategoryConfig[] = [
  {
    id: 'BIKE',
    name: 'Bike',
    description: 'Motorbikes or e-bikes',
  },
  {
    id: 'CAR',
    name: 'Car',
    description: 'Standard cars',
  },
  {
    id: 'VAN',
    name: 'Van',
    description: 'Standard vans',
  },
  {
    id: 'LUTON',
    name: 'Luton Van',
    description: 'Box vans with a tail lift',
  },
  {
    id: 'FLATBED',
    name: 'Flatbed',
    description: 'Open back vehicles with drop-sides',
  },
  {
    id: 'HIAB',
    name: 'HIAB',
    description: 'Open back vehicles with a crane attached',
  },
  {
    id: 'LORRY',
    name: 'Lorry',
    description: 'A large truck',
  },
];

export const VEHICLE_CONFIGS: VehicleConfig[] = [
  {
    id: 'BIKE',
    name: 'Bike',
    category: 'BIKE',
    description:
      'Motorised bikes - the fastest way to get smaller items around the city',
    lengthCapacityMetres: 0.5,
    widthCapacityMetres: 0.5,
    heightCapacityMetres: 0.5,
    weightCapacityKg: 25,
    pricingMultiplier: 1.7,
    minimumTotalPrice: 6 + SERVICE_CHARGE,
    documentRequirements: [
      { documentTypeId: 'CBT', isRequired: true },
      { documentTypeId: 'DRIVERS_LICENSE_FRONT', isRequired: true },
      { documentTypeId: 'DRIVERS_LICENSE_BACK', isRequired: true },
      { documentTypeId: 'HIRE_AND_REWARD_INSURANCE', isRequired: true },
      { documentTypeId: 'GOODS_IN_TRANSIT_INSURANCE', isRequired: false },
    ],
  },
  {
    id: 'BICYCLE',
    name: 'Bicycle',
    category: 'BIKE',
    description: 'A bicycle or e-bicycle',
    lengthCapacityMetres: 0.5,
    widthCapacityMetres: 0.5,
    heightCapacityMetres: 0.5,
    weightCapacityKg: 25,
    pricingMultiplier: 1.7,
    minimumTotalPrice: 6 + SERVICE_CHARGE,
    documentRequirements: [
      { documentTypeId: 'HIRE_AND_REWARD_INSURANCE', isRequired: true },
      { documentTypeId: 'GOODS_IN_TRANSIT_INSURANCE', isRequired: false },
    ],
  },
  {
    id: 'MOTORBIKE',
    name: 'Motorcycle',
    category: 'BIKE',
    description: 'A motorcycle or e-motorcycle',
    lengthCapacityMetres: 0.5,
    widthCapacityMetres: 0.5,
    heightCapacityMetres: 0.5,
    weightCapacityKg: 25,
    pricingMultiplier: 1.7,
    minimumTotalPrice: 6 + SERVICE_CHARGE,
    documentRequirements: [
      { documentTypeId: 'DRIVERS_LICENSE_FRONT', isRequired: true },
      { documentTypeId: 'DRIVERS_LICENSE_BACK', isRequired: true },
      { documentTypeId: 'CBT', isRequired: false },
      { documentTypeId: 'HIRE_AND_REWARD_INSURANCE', isRequired: true },
      { documentTypeId: 'GOODS_IN_TRANSIT_INSURANCE', isRequired: false },
    ],
  },
  {
    id: 'CAR',
    name: 'Car',
    category: 'CAR',
    description: 'A standard car with seats that can be put down',
    lengthCapacityMetres: 1.5,
    widthCapacityMetres: 1.0,
    heightCapacityMetres: 1.0,
    weightCapacityKg: 300,
    pricingMultiplier: 3.3,
    minimumTotalPrice: 11 + SERVICE_CHARGE,
    documentRequirements: [
      { documentTypeId: 'DRIVERS_LICENSE_FRONT', isRequired: true },
      { documentTypeId: 'DRIVERS_LICENSE_BACK', isRequired: true },
      { documentTypeId: 'HIRE_AND_REWARD_INSURANCE', isRequired: true },
      { documentTypeId: 'GOODS_IN_TRANSIT_INSURANCE', isRequired: false },
    ],
  },
  {
    id: 'VAN_MEDIUM',
    name: 'Van (MWB)',
    category: 'VAN',
    description: 'A medium wheelbase (MWB) van',
    lengthCapacityMetres: 2.4,
    widthCapacityMetres: 1.5,
    heightCapacityMetres: 2.0,
    weightCapacityKg: 600,
    pricingMultiplier: 3.87,
    minimumTotalPrice: 16 + SERVICE_CHARGE,
    documentRequirements: [
      { documentTypeId: 'DRIVERS_LICENSE_FRONT', isRequired: true },
      { documentTypeId: 'DRIVERS_LICENSE_BACK', isRequired: true },
      { documentTypeId: 'HIRE_AND_REWARD_INSURANCE', isRequired: true },
      { documentTypeId: 'GOODS_IN_TRANSIT_INSURANCE', isRequired: false },
    ],
  },
  {
    id: 'VAN_LARGE',
    name: 'Van (LWB)',
    category: 'VAN',
    description: 'A long wheelbase (LWB) van',
    lengthCapacityMetres: 3.3,
    widthCapacityMetres: 1.5,
    heightCapacityMetres: 2.0,
    weightCapacityKg: 1000,
    pricingMultiplier: 4.27,
    minimumTotalPrice: 21 + SERVICE_CHARGE,
    documentRequirements: [
      { documentTypeId: 'DRIVERS_LICENSE_FRONT', isRequired: true },
      { documentTypeId: 'DRIVERS_LICENSE_BACK', isRequired: true },
      { documentTypeId: 'HIRE_AND_REWARD_INSURANCE', isRequired: true },
      { documentTypeId: 'GOODS_IN_TRANSIT_INSURANCE', isRequired: false },
    ],
  },
  {
    id: 'VAN_XLARGE',
    name: 'Van (X-LWB)',
    category: 'VAN',
    description: 'An extra long wheelbase (X-LWB) van',
    lengthCapacityMetres: 4.2,
    widthCapacityMetres: 1.5,
    heightCapacityMetres: 2.0,
    weightCapacityKg: 1200,
    pricingMultiplier: 4.93,
    minimumTotalPrice: 26 + SERVICE_CHARGE,
    documentRequirements: [
      { documentTypeId: 'DRIVERS_LICENSE_FRONT', isRequired: true },
      { documentTypeId: 'DRIVERS_LICENSE_BACK', isRequired: true },
      { documentTypeId: 'HIRE_AND_REWARD_INSURANCE', isRequired: true },
      { documentTypeId: 'GOODS_IN_TRANSIT_INSURANCE', isRequired: false },
    ],
  },
  {
    id: 'LUTON',
    name: 'Luton Van',
    category: 'LUTON',
    description: 'A box van with a tail lift',
    lengthCapacityMetres: 4.0,
    widthCapacityMetres: 1.9,
    heightCapacityMetres: 2.1,
    weightCapacityKg: 1000,
    pricingMultiplier: 5.84,
    minimumTotalPrice: 31 + SERVICE_CHARGE,
    documentRequirements: [
      { documentTypeId: 'DRIVERS_LICENSE_FRONT', isRequired: true },
      { documentTypeId: 'DRIVERS_LICENSE_BACK', isRequired: true },
      { documentTypeId: 'HIRE_AND_REWARD_INSURANCE', isRequired: true },
      { documentTypeId: 'GOODS_IN_TRANSIT_INSURANCE', isRequired: false },
    ],
  },
  {
    id: 'FLATBED',
    name: 'Flatbed',
    category: 'FLATBED',
    description: 'An open back vehicle with drop-sides',
    lengthCapacityMetres: 7.0,
    widthCapacityMetres: 1.5,
    heightCapacityMetres: 2.0,
    weightCapacityKg: 1350,
    pricingMultiplier: 7.0,
    minimumTotalPrice: 36 + SERVICE_CHARGE,
    documentRequirements: [
      { documentTypeId: 'DRIVERS_LICENSE_FRONT', isRequired: true },
      { documentTypeId: 'DRIVERS_LICENSE_BACK', isRequired: true },
      { documentTypeId: 'HIRE_AND_REWARD_INSURANCE', isRequired: true },
      { documentTypeId: 'GOODS_IN_TRANSIT_INSURANCE', isRequired: false },
    ],
  },
  {
    id: 'HIAB',
    name: 'HIAB',
    category: 'HIAB',
    description: 'An open back vehicle with a crane attached',
    lengthCapacityMetres: 10,
    widthCapacityMetres: 1.5,
    heightCapacityMetres: 2.0,
    weightCapacityKg: 10000,
    pricingMultiplier: 10,
    minimumTotalPrice: 500 + SERVICE_CHARGE,
    documentRequirements: [
      { documentTypeId: 'DRIVERS_LICENSE_FRONT', isRequired: true },
      { documentTypeId: 'DRIVERS_LICENSE_BACK', isRequired: true },
      { documentTypeId: 'HIRE_AND_REWARD_INSURANCE', isRequired: true },
      { documentTypeId: 'GOODS_IN_TRANSIT_INSURANCE', isRequired: false },
    ],
  },
  {
    id: 'LORRY_MEDIUM',
    name: 'Lorry (Medium)',
    category: 'LORRY',
    description:
      'A medium-sized truck (e.g a 7.5 tonne truck) typically with a tail lift - ',
    lengthCapacityMetres: 6,
    widthCapacityMetres: 1.5,
    heightCapacityMetres: 2.0,
    weightCapacityKg: 3000,
    pricingMultiplier: 10,
    minimumTotalPrice: 300 + SERVICE_CHARGE,
    documentRequirements: [
      { documentTypeId: 'DRIVERS_LICENSE_FRONT', isRequired: true },
      { documentTypeId: 'DRIVERS_LICENSE_BACK', isRequired: true },
      { documentTypeId: 'HIRE_AND_REWARD_INSURANCE', isRequired: true },
      { documentTypeId: 'GOODS_IN_TRANSIT_INSURANCE', isRequired: false },
    ],
  },
];

/**
 * For form limits to stop drivers putting in invalid values
 */
export const fleetVehicleLimits: Record<
  DeliveryVehicleId,
  {
    lengthMetres: number;
    widthMetres: number;
    heightMetres: number;
    weightKg: number;
  }
> = {
  BIKE: {
    lengthMetres: 1.5,
    widthMetres: 1.5,
    heightMetres: 1.5,
    weightKg: 35,
  },
  BICYCLE: {
    lengthMetres: 1.5,
    widthMetres: 1.5,
    heightMetres: 1.5,
    weightKg: 35,
  },
  MOTORBIKE: {
    lengthMetres: 1.5,
    widthMetres: 1.5,
    heightMetres: 1.5,
    weightKg: 35,
  },
  CAR: {
    lengthMetres: 2.5,
    widthMetres: 1.5,
    heightMetres: 1.5,
    weightKg: 400,
  },
  LUTON: {
    lengthMetres: 6,
    widthMetres: 3,
    heightMetres: 2.5,
    weightKg: 2000,
  },
  FLATBED: {
    lengthMetres: 8,
    widthMetres: 3,
    heightMetres: 2.5,
    weightKg: 2000,
  },
  HIAB: {
    lengthMetres: 10,
    widthMetres: 3,
    heightMetres: 2.5,
    weightKg: 10000,
  },
  LORRY_MEDIUM: {
    lengthMetres: 6,
    widthMetres: 3,
    heightMetres: 2.5,
    weightKg: 3000,
  },
  /**
   * Vans are classified on the backend after creation, so give all vans the same limits
   */
  VAN_MEDIUM: {
    lengthMetres: 5,
    widthMetres: 2.5,
    heightMetres: 2.5,
    weightKg: 2000,
  },
  VAN_LARGE: {
    lengthMetres: 5,
    widthMetres: 2.5,
    heightMetres: 2.5,
    weightKg: 2000,
  },
  VAN_XLARGE: {
    lengthMetres: 5,
    widthMetres: 2.5,
    heightMetres: 2.5,
    weightKg: 2000,
  },
};

export const ALL_DELIVERY_VEHICLE_IDS = Object.values(
  DeliveryVehicleIdSchema.Values,
);

export const ALL_FUEL_TYPES = Object.values(FuelTypeSchema.Values);

/**
 * Limits to put on form inputs
 */
export const MAX_LOAD_LENGTH_MM = 7000;
export const MAX_LOAD_WIDTH_MM = 1900;
export const MAX_LOAD_HEIGHT_MM = 2100;
export const MAX_LOAD_WEIGHT_KG = 1350;

/**
 * Utils
 */

export const getVehicleConfig = (id: DeliveryVehicleId) => {
  const config = VEHICLE_CONFIGS.find((v) => v.id === id);
  if (!config) {
    throw new Error(`Vehicle config not found for vehicleId: ${id}`);
  }
  return config;
};

export const getVehicleCategoryConfig = (id: VehicleCategoryId) => {
  const config = VEHICLE_CATEGORY_CONFIGS.find((v) => v.id === id);
  if (!config) {
    throw new Error(`Vehicle category config not found for vehicleId: ${id}`);
  }
  return config;
};

const assertNever = (value: never): never => {
  throw new Error(`Unexpected value ${value}`);
};

export const getDefaultAllowedVehicles = (
  orderDeliveryVehicle: DeliveryVehicleId,
): DeliveryVehicleId[] => {
  switch (orderDeliveryVehicle) {
    case 'BIKE':
      return ['BIKE', 'BICYCLE', 'MOTORBIKE'];
    case 'BICYCLE':
      return ['BICYCLE', 'MOTORBIKE'];
    case 'MOTORBIKE':
      return ['MOTORBIKE'];
    case 'CAR':
      return [
        'CAR',
        'VAN_MEDIUM',
        'VAN_LARGE',
        'VAN_XLARGE',
        'LUTON',
        'FLATBED',
      ];
    case 'VAN_MEDIUM':
      return ['VAN_MEDIUM', 'VAN_LARGE', 'VAN_XLARGE', 'LUTON', 'FLATBED'];
    case 'VAN_LARGE':
      return ['VAN_LARGE', 'VAN_XLARGE', 'LUTON', 'FLATBED'];
    case 'VAN_XLARGE':
      return ['VAN_XLARGE', 'LUTON', 'FLATBED'];
    case 'LUTON':
      return ['LUTON'];
    case 'FLATBED':
      return ['FLATBED'];
    case 'HIAB':
      return ['HIAB'];
    case 'LORRY_MEDIUM':
      return ['LORRY_MEDIUM'];
    default:
      return assertNever(orderDeliveryVehicle);
  }
};
