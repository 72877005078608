import React from 'react';

export const BikeRearIcon: React.FC<{
  width?: number;
  height?: number;
  xArea?: number;
  yArea?: number;
  xPosition?: string;
  yPosition?: string;
}> = ({ height, width, xArea, xPosition, yArea, yPosition }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="50 0 221 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="155" y="10" fill="#232324" fontSize="12" fontFamily="Arial">
        Width
      </text>
      <text x="50" y="100" fill="#232324" fontSize="12" fontFamily="Arial">
        Height
      </text>
      <path
        d="M101.5 54L103.887 49L98.1132 49L100.5 54L101.5 54ZM100.5 131L98.1133 136L103.887 136L101.5 131L100.5 131ZM100.5 53.5L100.5 131.5L101.5 131.5L101.5 53.5L100.5 53.5Z"
        fill="#AF9032"
      />
      <path
        d="M207 24.5L212 26.8868V21.1132L207 23.5V24.5ZM130 23.5L125 21.1132V26.8868L130 24.5V23.5ZM207.5 23.5L129.5 23.5V24.5L207.5 24.5V23.5Z"
        fill="#AF9032"
      />
      <rect x="121" y="44" width="96" height="96" rx="12" fill="#F9F9F9" />
      <rect
        x="121"
        y="44"
        width="96"
        height="96"
        rx="12"
        stroke="#A7A7A8"
        stroke-width="8"
      />
      <rect x="133" y="56" width="72" height="72" rx="4" fill="#D5D5D5" />
      <rect
        x={xPosition}
        y={yPosition}
        width={xArea}
        height={yArea}
        rx="4"
        fill="#AF9032"
      />
      <path
        d="M157 143H182V180C182 184.418 178.418 188 174 188H165C160.582 188 157 184.418 157 180V143Z"
        fill="#A7A7A8"
      />
    </svg>
  );
};
