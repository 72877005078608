import { CurrentOrder, CreateDeliveryStop } from "@tradeaze-packages/schemas";

export const getPickups = (order: CurrentOrder): CreateDeliveryStop[] => {
  switch (order.type) {
    case 'A_TO_B':
      return order.pickup ? [order.pickup] : [];
    case 'MULTI_COLLECTION':
      return order.pickups || [];
    case 'MULTI_DROP':
      return order.pickup ? [order.pickup] : [];
    default:
      return [];
  }
};
