import React from 'react';

export const FlatbedRearIcon: React.FC<{
  width?: number;
  height?: number;
  xArea?: number;
  yArea?: number;
  xPosition?: string;
  yPosition?: string;
}> = ({ height, width, xArea, xPosition, yArea, yPosition }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="50 0 252 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <text x="170" y="10" fill="#232324" fontSize="14" fontFamily="Arial">
          Width
        </text>
        <text x="50" y="120" fill="#232324" fontSize="14" fontFamily="Arial">
          Height
        </text>
        <path
          d="M109.5 47L111.887 42L106.113 42L108.5 47L109.5 47ZM108.5 183L106.113 188L111.887 188L109.5 183L108.5 183ZM108.5 46.5L108.5 183.5L109.5 183.5L109.5 46.5L108.5 46.5Z"
          fill="#AF9032"
        />
        <path
          d="M246 24.5L251 26.8868V21.1132L246 23.5V24.5ZM138 23.5L133 21.1132V26.8868L138 24.5V23.5ZM246.5 23.5L137.5 23.5V24.5L246.5 24.5V23.5Z"
          fill="#AF9032"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.955 203.006C157.985 203.248 158 203.495 158 203.746V219.001C158 222.315 155.314 225.001 152 225.001H139C136.876 225.001 135.01 223.897 133.944 222.232C133.879 222.173 133.821 222.111 133.771 222.046C133.257 221.374 133 220.54 133 219.546V219.001V203.746V147.871L147.942 92.2351C148.197 91.0656 148.721 90.1885 149.512 89.6037C150.304 89.0189 151.185 88.7266 152.156 88.7266H232.61C233.39 88.7266 234.098 89.0482 234.735 89.6914C235.371 90.3347 235.812 91.1826 236.058 92.2351L251 147.871V203.732C251 203.737 251 203.741 251 203.746V219.001C251 219.006 251 219.01 251 219.015V219.546C251 220.54 250.743 221.374 250.229 222.046C250.158 222.139 250.084 222.225 250.009 222.306C248.935 223.93 247.093 225.001 245 225.001H232C228.686 225.001 226 222.315 226 219.001V203.746C226 203.495 226.015 203.248 226.045 203.006H157.955ZM243.338 140.854H140.662L152.922 95.7437H231.078L243.338 140.854ZM162.213 179.013C160.744 180.974 158.939 181.954 156.798 181.954C154.657 181.954 152.838 180.994 151.339 179.072C149.84 177.151 149.091 174.79 149.091 171.989C149.091 169.188 149.825 166.807 151.294 164.846C152.762 162.886 154.567 161.905 156.708 161.905C158.849 161.905 160.669 162.866 162.168 164.787C163.666 166.709 164.416 169.07 164.416 171.871C164.416 174.672 163.681 177.053 162.213 179.013ZM232.706 179.013C231.238 180.974 229.433 181.954 227.292 181.954C225.151 181.954 223.331 180.994 221.832 179.072C220.334 177.151 219.584 174.79 219.584 171.989C219.584 169.188 220.319 166.807 221.787 164.846C223.256 162.886 225.061 161.905 227.202 161.905C229.343 161.905 231.162 162.866 232.661 164.787C234.16 166.709 234.909 169.07 234.909 171.871C234.909 174.672 234.175 177.053 232.706 179.013ZM245.636 195.989H138.364V147.871H245.636V195.989Z"
          fill="#A7A7A8"
        />
        <rect
          x="132.8"
          y="43.8"
          width="118.4"
          height="144.741"
          rx="8.2"
          fill="#F9F9F9"
        />
        <rect
          x="132.8"
          y="43.8"
          width="118.4"
          height="144.741"
          rx="8.2"
          stroke="#A7A7A8"
          stroke-width="0.4"
        />
        <rect
          width="102"
          height="128.341"
          transform="translate(141 52)"
          fill="#D5D5D5"
        />
        <rect
          x={xPosition}
          y={yPosition}
          width={xArea}
          height={yArea}
          rx="4"
          fill="#AF9032"
        />
    </svg>
  );
};
