import { HStack } from '@chakra-ui/react';
import { Z_INDEX } from '../constants';

export const MapControlsContainerMobile = ({
  children,
  zIndex = Z_INDEX.MAP_CONTROLS,
}: {
  children: React.ReactNode;
  zIndex?: number;
}) => {
  return (
    <HStack position={'fixed'} bottom={4} right={3} zIndex={zIndex}>
      {children}
    </HStack>
  );
};
