import { HStack, Switch, Text } from '@chakra-ui/react';
import { Contact } from './ContactCard';
import { useFormContext } from 'react-hook-form';
import { CreateDeliveryStop } from '@tradeaze-packages/schemas';

type CollapsedContactFormProps = {
  contact: Contact;
  index: number;
  contactsAccessor: `contacts.${number}`;
  handleUpdateContact: (index: number, updates: Partial<Contact>) => void;
};

export const CollapsedContactForm = ({
  contact,
  index,
  contactsAccessor,
  handleUpdateContact,
}: CollapsedContactFormProps) => {
  const { register } = useFormContext<CreateDeliveryStop>();

  const nameAndNumber = [contact.contactName, contact.contactNumber]
    .filter(Boolean)
    .join(' | ');

  const completeDataText = 'Please complete details';

  const showCompleteDetails = !contact.contactName || !contact.contactNumber;

  return (
    <>
      <Text
        fontSize="sm"
        color={showCompleteDetails ? 'red.500' : 'gray.500'}
        mb={1}
        data-cy={'contact-details'}
      >
        {showCompleteDetails ? completeDataText : nameAndNumber}
      </Text>
      <HStack mb={2}>
        <Text fontSize="sm" color="gray.500" data-cy={'tracking-sms'}>
          Tracking SMS
        </Text>
        <Switch
          size="sm"
          isChecked={contact.trackingEnabled}
          {...register(`${contactsAccessor}.trackingEnabled`)}
          onChange={(e) => {
            handleUpdateContact(index, {
              trackingEnabled: e.target.checked,
            });
          }}
          data-cy={'toggle-collapsed-contact-card'}
          isDisabled={showCompleteDetails}
        />
      </HStack>
    </>
  );
};
