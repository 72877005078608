import { z } from 'zod';
import { VehicleCategoryIdSchema } from './vehicle-category-schema';

export const FuelTypeSchema = z.enum([
  'PETROL',
  'DIESEL',
  'ELECTRIC',
  'HYBRID',
]);

export const DeliveryVehicleIdSchema = z.enum([
  'BIKE', // BICYCLE or MOTORBIKE
  'BICYCLE',
  'MOTORBIKE',
  'CAR',
  'VAN_MEDIUM',
  'VAN_LARGE',
  'VAN_XLARGE',
  'LUTON',
  'FLATBED',
  'HIAB',
  'LORRY_MEDIUM',
]);

export const DeliveryVehicleSchema = z.object({
  deliveryVehicleId: DeliveryVehicleIdSchema,
  name: z.string(),
  description: z.string().nullish(),
  categoryId: VehicleCategoryIdSchema.nullish(),
  lengthCapacity: z.number().nullish(),
  weightCapacity: z.number().nullish(),
  widthCapacity: z.number().nullish(),
  heightCapacity: z.number().nullish(),
});

export type DeliveryVehicleId = z.infer<typeof DeliveryVehicleIdSchema>;

export type DeliveryVehicle = z.infer<typeof DeliveryVehicleSchema>;
