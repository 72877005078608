import React, { useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { OrderOptions, orderTypeOptions } from './OrderOptions';
import { AtoBOrderForm } from './order-type-forms';
import {
  CreateOrder,
  DuplicateOrder,
  MerchantAccount,
  OrderType,
  UpdateOrder,
} from '@tradeaze-packages/schemas';
import { AddMarkerFn, RemoveMarkerFn } from '../map';
import { MultiCollectionOrderForm } from './order-type-forms/multi-collection/MultiCollectionOrderForm';
import { PostHog } from 'posthog-js/react';
import { captureEvent } from '@tradeaze/frontend/utils';
import { MainBackButton } from './order-form-sections';
import { MultiDropOrderForm } from './order-type-forms/multi-drop/MultiDropOrderForm';

type OrderTypeProps = {
  isAdmin: boolean;
  merchantAccount?: MerchantAccount;
  addPickupMarker: AddMarkerFn;
  addDeliveryMarker: AddMarkerFn;
  removePickupMarker: RemoveMarkerFn;
  removeDeliveryMarker: RemoveMarkerFn;
  resetMapMarkers: () => void;
  onSubmit: (order: CreateOrder) => void;
  existingOrder?: UpdateOrder;
  posthog?: PostHog;
  isLoading: boolean;
  merchantsLoading?: boolean;
  selectedMerchantId?: string;
  handleMerchantSelect?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  sortedMerchants?: MerchantAccount[];
  duplicatedOrder?: DuplicateOrder;
};

export const OrderFormType = ({
  isAdmin,
  merchantAccount,
  onSubmit,
  addPickupMarker,
  addDeliveryMarker,
  removePickupMarker,
  removeDeliveryMarker,
  resetMapMarkers,
  posthog,
  isLoading,
  merchantsLoading,
  selectedMerchantId,
  handleMerchantSelect,
  sortedMerchants,
  duplicatedOrder,
}: OrderTypeProps) => {
  const [selectedType, setSelectedType] = useState<OrderType | null>(
    duplicatedOrder?.type ?? null,
  );

  const [showMainBackButton, setShowMainBackButton] = useState(false);

  const [isGuestOrder, setIsGuestOrder] = useState(false);

  const handleOrderSelection = (orderType: OrderType) => {
    setSelectedType(orderType);
    setShowMainBackButton(true);
    if (posthog) {
      if (orderType === 'A_TO_B') {
        captureEvent(posthog)('start_create_ab_order');
      }
      if (orderType === 'MULTI_COLLECTION') {
        captureEvent(posthog)('start_create_multicollection_order');
      }
      if (orderType === 'MULTI_DROP') {
        captureEvent(posthog)('start_create_multidrop_order');
      }
    }
  };

  const handleMainBackButton = () => {
    resetMapMarkers();
    setSelectedType(null);
    setShowMainBackButton(false);
  };

  const handleGuestOrderToggle = (isGuest: boolean) => {
    setIsGuestOrder(isGuest);
  };

  return (
    <>
      {!selectedType && (
        <OrderOptions
          handleOrderSelection={handleOrderSelection}
          isAdmin={isAdmin}
          merchantsLoading={merchantsLoading}
          selectedMerchant={merchantAccount}
          selectedMerchantId={selectedMerchantId}
          handleMerchantSelect={handleMerchantSelect}
          sortedMerchants={sortedMerchants}
          handleGuestOrderToggle={handleGuestOrderToggle}
          isGuestOrder={isGuestOrder}
        />
      )}
      <Box>
        {showMainBackButton && selectedType ? (
          <HStack mb={14} alignItems={'center'}>
            <MainBackButton
              handleMainBackButton={handleMainBackButton}
              cypressTestId="main-back-button"
            />
            <Text fontSize="xl" fontWeight={600}>
              Create an order - {orderTypeOptions[selectedType]?.title}
            </Text>
          </HStack>
        ) : null}
        {selectedType === 'A_TO_B' && (
          <AtoBOrderForm
            isAdmin={isAdmin}
            merchantAccount={merchantAccount}
            addPickupMarker={addPickupMarker}
            addDeliveryMarker={addDeliveryMarker}
            removePickupMarker={removePickupMarker}
            removeDeliveryMarker={removeDeliveryMarker}
            onSubmit={onSubmit}
            posthog={posthog}
            isLoading={isLoading}
            duplicateOrder={duplicatedOrder}
          />
        )}
        {selectedType === 'MULTI_COLLECTION' && (
          <MultiCollectionOrderForm
            isAdmin={isAdmin}
            merchantAccount={merchantAccount}
            addPickupMarker={addPickupMarker}
            addDeliveryMarker={addDeliveryMarker}
            removePickupMarker={removePickupMarker}
            removeDeliveryMarker={removeDeliveryMarker}
            onSubmit={onSubmit}
            setShowMainBackButton={setShowMainBackButton}
            posthog={posthog}
            isLoading={isLoading}
          />
        )}
        {selectedType === 'MULTI_DROP' && (
          <MultiDropOrderForm
            merchantAccount={merchantAccount}
            isAdmin={isAdmin}
            onSubmit={onSubmit}
            addPickupMarker={addPickupMarker}
            addDeliveryMarker={addDeliveryMarker}
            removePickupMarker={removePickupMarker}
            removeDeliveryMarker={removeDeliveryMarker}
            setShowMainBackButton={setShowMainBackButton}
            isLoading={isLoading}
          />
        )}
      </Box>
    </>
  );
};
