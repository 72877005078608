export const Z_INDEX = {
  // Base layer
  ORDER_DASHBOARD: 1,
  ORDER_DASHBOARD_CARD: 2,
  
  // Map layer
  MAP: 10,
  MAP_CONTROLS: 11,
  
  // UI Controls layer
  ORDER_DASHBOARD_CONTROLS: 15,
  
  // Modal/Overlay layers
  ORDER_DELIVERIES: 30,
  ORDER_DELIVERIES_MAP: 35,
  ORDER_DELIVERIES_MAP_CONTROLS: 36,
  
  // Top layer
  ASSIGN_SCHEDULE: 40,
  ASSIGN_SCHEDULE_MAP: 45,
  ASSIGN_SCHEDULE_MAP_CONTROLS: 46,
} as const; 
