export const formatTotalPrice = ({
  deliveryPrice, serviceCharge,
}: {
  deliveryPrice: number;
  serviceCharge: number;
}) => {
  const totalPrice = deliveryPrice + serviceCharge;

  return `£${totalPrice.toFixed(2)}`;
};
