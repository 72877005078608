import { Image, ImageProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState } from 'react';

const MotionImage = motion(Image);

interface AnimatedImageProps extends ImageProps {
  animationDelay?: number;
}

export const AnimatedImage = ({ animationDelay = 0, ...props }: AnimatedImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <MotionImage
      {...props}
      initial={{ opacity: 0, scale: 0.95, x: 20 }}
      animate={{ 
        opacity: isLoaded ? 1 : 0,
        scale: isLoaded ? 1 : 0.95,
        x: isLoaded ? 0 : 20,
      }}
      transition={{
        duration: 0.6,
        delay: animationDelay,
        // ease: "easeOut",
        type: "spring",
        stiffness: 100,
        damping: 10,
      }}
      onLoad={() => setIsLoaded(true)}
    />
  );
}; 