import { Button } from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import React from 'react';
import { CreateOrder, OrderType } from '@tradeaze-packages/schemas';
import { OrderConfirmationModal } from '../order-confirmation-summary/OrderConfirmationModal';

type CompleteOrderButtonProps = {
  isLoading: boolean;
  handleSubmit: () => Promise<void>;
  isUpdatingOrder?: boolean;
  label: string;
  currentOrder: Partial<CreateOrder>;
  orderType: OrderType;
};

export const CompleteOrderButton = ({
  isLoading,
  handleSubmit,
  isUpdatingOrder = false,
  label,
  currentOrder,
  orderType,
}: CompleteOrderButtonProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onConfirm = () => {
    setIsModalOpen(false);
    // prevent double submission
    debounce(() => {
      handleSubmit();
    }, 200)();
  };

  return (
    <>
      <Button
        fontSize={20}
        borderRadius={16}
        w="100%"
        h={16}
        isLoading={isLoading}
        onClick={() => setIsModalOpen(true)}
        data-cy={
          isUpdatingOrder ? 'update-order-button' : 'create-order-button'
        }
      >
        {label}
      </Button>

      <OrderConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={onConfirm}
        currentOrder={currentOrder}
        isLoading={isLoading}
        isUpdatingOrder={isUpdatingOrder}
        orderType={orderType}
      />
    </>
  );
};
