import { DeliveryItem } from '@tradeaze-packages/schemas';
import { convertToMetres } from '../item-sizing';

export const getMaxItemDimension = (
  deliveryItems: Pick<
    DeliveryItem,
    'length' | 'width' | 'height' | 'lengthUnit' | 'widthUnit' | 'heightUnit'
  >[],
) => {
  let overallMaxDimension = 0;

  for (const item of deliveryItems) {
    const itemLength = convertToMetres(item.length, item.lengthUnit);
    const itemWidth = convertToMetres(item.width, item.widthUnit);
    const itemHeight = convertToMetres(item.height, item.heightUnit);

    const maxItemDimension = Math.max(itemLength, itemWidth, itemHeight);
    if (maxItemDimension > overallMaxDimension) {
      overallMaxDimension = maxItemDimension;
    }
  }
  return overallMaxDimension;
};
