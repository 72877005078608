import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  ModalBody,
  ModalFooter,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import {
  ALL_DELIVERY_VEHICLE_IDS,
  DEFAULT_REGION_ID,
  DeliveryOptionId,
  DeliveryVehicleId,
  getDeliveryOptionDescription,
  MAX_DISTANCE_FROM_CENTRE_OF_LONDON_METRES,
  OrderType,
} from '@tradeaze-packages/schemas';
import {
  useCalculateTotalFees,
  useGetDeliveryOptions,
  useGetDeliveryPrice,
} from '@tradeaze/frontend/hooks';
import { captureEvent, initialStartDate } from '@tradeaze/frontend/utils';
import {
  formatDateIgnoreTime,
  getApiErrorMessage,
  getIsEveryStopOverThreshold,
} from '@tradeaze/shared/utils';
import { PostHog } from 'posthog-js/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { TradeazeLogoNoText } from '../../brand';
import { getNextOptionOnVehicleChange } from '../../order';
import { SmallDatePicker } from '../../shared/SmallDatePicker';
import { DeliveryVehicleIcon } from './../delivery-vehicle/DeliveryVehicleIcon';
import { DeliveryVehicleInfo } from './../delivery-vehicle/DeliveryVehicleInfo';
import { getIsValidPostcode, getStopTitle, postcodeValidate } from './utils';

const OptimisedDropOffsAlert: React.FC = () => (
  <Alert status="info" mb={4} borderRadius="md">
    <AlertIcon />
    Price is based on the most efficient route. Enter drops in any order.
  </Alert>
);

export const PricingCalculator: React.FunctionComponent<{
  defaultFromPostcode?: string;
  onClose: () => void;
  merchantId?: string;
  isAdmin?: boolean;
  posthog?: PostHog;
  hidePriorityDelivery: boolean;
  allowLongDistance: boolean;
  orderType: OrderType;
}> = ({
  defaultFromPostcode,
  merchantId,
  onClose,
  isAdmin = false,
  hidePriorityDelivery,
  posthog,
  allowLongDistance,
  orderType,
}) => {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);

  const [shouldUseAccountPostcode, setShouldUseAccountPostcode] = useState(
    !!defaultFromPostcode && getIsValidPostcode(defaultFromPostcode),
  );

  const isAB = orderType === 'A_TO_B';
  const isMultiCollection = orderType === 'MULTI_COLLECTION';
  const isMultiDrop = orderType === 'MULTI_DROP';

  const posthogOrderType = useMemo(() => {
    if (isMultiDrop) return 'MULTI_DROP';
    if (isMultiCollection) return 'MULTI_COLLECTION';
    return 'A_TO_B';
  }, [isMultiDrop, isMultiCollection]);

  const initialStopPostcodes = [defaultFromPostcode ?? '', ''];

  if (!isAB) initialStopPostcodes.push('');

  const [stopPostcodes, setStopPostcodes] =
    useState<string[]>(initialStopPostcodes);

  const postcodeErrors = useMemo(
    () => postcodeValidate(stopPostcodes),
    [stopPostcodes],
  );

  const hasInvalidPostcodes = useMemo(
    () => postcodeErrors.some(Boolean),
    [postcodeErrors],
  );

  const [deliveryOptionId, setDeliveryOptionId] =
    useState<DeliveryOptionId | null>('BIKE_THREE_HOUR');

  const [deliveryVehicleId, setDeliveryVehicleId] =
    useState<DeliveryVehicleId>('BIKE');

  const [date, setDate] = useState(initialStartDate);

  const [heavySideItems, setHeavySideItems] = useState<boolean>();

  const [enableCalculation, setEnableCalculation] = useState(false);

  const deliveryOptionsQuery = useGetDeliveryOptions({
    date: date.toISOString(),
    regionId: DEFAULT_REGION_ID,
    hidePriorityDelivery,
    isAdmin,
    isAnyStopOverThreshold: false,
  });

  const deliveryVehicles =
    Object.values(deliveryOptionsQuery.data ?? {}).map((v) => v.vehicle) ?? [];

  const allAvailableDeliveryOptions = Object.values(
    deliveryOptionsQuery.data ?? {},
  )
    .flatMap((v) => v.options)
    .filter((o) => o.isAvailable);

  const selectedVehicleData = deliveryOptionsQuery.data?.[deliveryVehicleId];

  const selectedVehicleOptions = selectedVehicleData?.options ?? [];

  const selectedVehicle = selectedVehicleData?.vehicle;

  const selectedOption = selectedVehicleOptions.find(
    (o) => o.deliveryOptionId === deliveryOptionId,
  );

  const shouldDisableCalculate =
    hasInvalidPostcodes || heavySideItems === undefined;

  const shouldOptimiseStops = isMultiDrop;

  const isOptionAvailable = selectedOption && selectedOption.isAvailable;

  const shouldCalculateDelivery = Boolean(
    enableCalculation &&
      deliveryOptionId &&
      deliveryVehicleId &&
      !hasInvalidPostcodes,
  );

  const { data, isFetching, error, status } = useGetDeliveryPrice(
    {
      orderType: orderType,
      optimiseStops: shouldOptimiseStops,
      deliveryOptionId: deliveryOptionId,
      deliveryStopLocations: stopPostcodes,
      throwOverThreshold: false,
      throwOnBikeOverThreshold: !isAdmin,
      heavySideItems,
      scheduledTime: date.toISOString(),
    },
    {
      enabled: shouldCalculateDelivery,
      onSuccess: (data) => {
        if (!posthog) {
          return;
        }
        captureEvent(posthog)('delivery_price_calculated', {
          deliveryOption: deliveryOptionId,
          deliveryVehicle: deliveryVehicleId,
          deliveryStopPostcodes: stopPostcodes,
          isAdmin,
          merchantId,
          heavySideItems,
          scheduledTime: date.toISOString(),
          deliveryPrice: data.deliveryPrice,
          serviceCharge: data.serviceCharge,
        });
      },
    },
  );

  useEffect(() => {
    if (status !== 'success') return;
    if (modalRef.current) {
      modalRef.current.scrollTop = modalRef.current.scrollHeight;
    }
  }, [status]);

  const totalTradeazeCharges = useCalculateTotalFees({
    deliveryPrice: data?.deliveryPrice,
    serviceCharge: data?.serviceCharge,
  });

  const isEveryStopOverThreshold = useMemo(
    () =>
      data?.thresholdDistances
        ? getIsEveryStopOverThreshold(
            data.thresholdDistances,
            MAX_DISTANCE_FROM_CENTRE_OF_LONDON_METRES,
          )
        : false,
    [data?.thresholdDistances],
  );

  const isSaturday = useMemo(() => {
    return date.getDay() === 6;
  }, [date]);

  const handleSetDate = (date: Date | null) => {
    setDate(date ? new Date(formatDateIgnoreTime(date)) : new Date());
  };

  const handleChangeDeliveryOption = (value: DeliveryOptionId) => {
    setDeliveryOptionId(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !shouldDisableCalculate) {
      setEnableCalculation(true);
    }
  };

  const handleResetCalculation = () => {
    setEnableCalculation(false);
  };

  const handleChangeUseAccountPostcode = () => {
    setShouldUseAccountPostcode((prevState) => {
      if (!prevState && defaultFromPostcode) {
        setStopPostcodes((prevPostcodes) => [
          defaultFromPostcode,
          ...prevPostcodes.slice(1),
        ]);
      }
      return !prevState;
    });
    handleResetCalculation();
  };

  const handleCalculate = () => {
    setEnableCalculation(true);
  };

  const handleCreateOrder = () => {
    if (posthog) {
      captureEvent(posthog)('start_create_order_from_pricing_modal', {
        deliveryOption: deliveryOptionId,
        deliveryVehicle: deliveryVehicleId,
        stopPostcodes,
        isAdmin,
        merchantId,
        heavySideItems,
        scheduledTime: date.toISOString(),
        deliveryPrice: data?.deliveryPrice,
        serviceCharge: data?.serviceCharge,
        orderType: posthogOrderType,
      });
    }
    navigate('/create-order');
    onClose();
  };

  const handleChangeHeavySideItems = (
    value: string,
    setState: (val: boolean) => void,
    posthog?: PostHog,
  ) => {
    const valueAsBoolean = value === 'yes';
    setState(valueAsBoolean);
    if (posthog) {
      captureEvent(posthog)(
        `toggle_heavy_side_items_${valueAsBoolean ? 'on' : 'off'}`,
      );
    }
  };

  const handleRemoveCollection = (stopPostcodes: string[], index: number) => {
    const newStopPostcodes = stopPostcodes.filter((_, i) => i !== index);

    setStopPostcodes(newStopPostcodes);
  };

  const handleAddCollection = (stopPostcodes: string[]) => {
    const lastStop = stopPostcodes[stopPostcodes.length - 1];

    const newStopPostcodes = [...stopPostcodes.slice(0, -1), '', lastStop];

    setStopPostcodes(newStopPostcodes);
  };

  const handleChangeDeliveryVehicle = (value: DeliveryVehicleId) => {
    const nextOption = getNextOptionOnVehicleChange({
      currentOption: deliveryOptionId,
      currentVehicle: deliveryVehicleId,
      newVehicle: value,
      availableDeliveryOptions: allAvailableDeliveryOptions,
    });

    if (value === 'BIKE') {
      setHeavySideItems(false);
    }

    setDeliveryVehicleId(value);
    setDeliveryOptionId(nextOption);
  };

  const handlePostcodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    stopIndex: number,
    stopPostcodes: string[],
  ) => {
    const newStopPostcodes = [...stopPostcodes];

    newStopPostcodes[stopIndex] = e.target.value.toUpperCase();

    setStopPostcodes(newStopPostcodes);
  };

  return (
    <>
      <ModalBody ref={modalRef} maxHeight={'65vh'} overflowY="auto">
        {defaultFromPostcode && (
          <FormControl mt={2} mb={6} display="flex" alignItems="center">
            <FormLabel htmlFor="use-account-postcode" mb="0">
              Use default pickup postcode {defaultFromPostcode}
            </FormLabel>
            <Switch
              id="use-account-postcode"
              size={'md'}
              checked={shouldUseAccountPostcode}
              defaultChecked={shouldUseAccountPostcode}
              onChange={handleChangeUseAccountPostcode}
              data-cy="use-default-postcode-switch"
            />
          </FormControl>
        )}
        {stopPostcodes.map((postcode, index) => (
          <Flex mt={2} mb={5} alignItems={'center'} justify="space-between">
            <HStack width={'160px'} justifyContent={'flex-start'}>
              <Text
                fontSize={16}
                fontWeight={'bold'}
                data-cy={`stop-title-${index}`}
              >
                {getStopTitle(stopPostcodes, index, isMultiDrop)}
              </Text>
              {!enableCalculation &&
              ((!isMultiDrop &&
                index > (isAB ? 0 : 1) &&
                index < stopPostcodes.length - 1) ||
                (isMultiDrop && index > 2)) ? (
                <Button
                  size="xs"
                  variant="unstyled"
                  onClick={() => handleRemoveCollection(stopPostcodes, index)}
                  data-cy="remove-collection-button"
                >
                  <IoIosRemoveCircle fontSize={18} color={'red'} />
                </Button>
              ) : null}
              {((isMultiCollection && index === stopPostcodes.length - 2) ||
                (isMultiDrop && index === stopPostcodes.length - 1)) &&
              !enableCalculation ? (
                <Button
                  size="xs"
                  variant="unstyled"
                  onClick={() => handleAddCollection(stopPostcodes)}
                  color={'black'}
                  data-cy="add-collection-button"
                >
                  <IoIosAddCircle fontSize={18} />
                </Button>
              ) : null}
            </HStack>
            {enableCalculation || (index === 0 && shouldUseAccountPostcode) ? (
              <Text px={5} my={'8px'} data-cy={`stop-postcode-${index}`}>
                {postcode}
              </Text>
            ) : (
              <Box>
                <FormControl isInvalid={Boolean(postcodeErrors[index])}>
                  <Input
                    w={28}
                    name="toPostcode"
                    onChange={(e) =>
                      handlePostcodeChange(e, index, stopPostcodes)
                    }
                    value={postcode}
                    onKeyDown={handleKeyDown}
                    data-cy={`stop-postcode-input-${index}`}
                  />
                  <FormErrorMessage
                    fontSize={10}
                    w={28}
                    data-cy={`stop-postcode-error-${index}`}
                  >
                    {postcodeErrors[index]}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            )}
          </Flex>
        ))}
        {isMultiDrop && <OptimisedDropOffsAlert />}
        <Box mt={5} mb={4}>
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Box>
              <Text fontSize={16} fontWeight={'bold'} data-cy="delivery-date">
                Delivery Date
              </Text>
            </Box>
            <SmallDatePicker
              size="small"
              date={date}
              onChange={handleSetDate}
              allowPastDates={false}
              allowSunday={false}
              data-cy="delivery-date-picker"
            />
          </Flex>
        </Box>
        <Divider />
        {deliveryOptionsQuery.status === 'loading' && (
          <Center h={'200px'}>
            <Spinner />
          </Center>
        )}
        {deliveryOptionsQuery.status === 'success' && (
          <Box>
            <RadioGroup
              mt={6}
              mb={3}
              onChange={(val: DeliveryVehicleId) =>
                handleChangeDeliveryVehicle(val)
              }
              value={deliveryVehicleId}
              data-cy="delivery-vehicle-radio-group"
            >
              <Flex flexWrap="wrap" alignItems={'center'}>
                {deliveryVehicles
                  ?.sort((a, b) => {
                    return (
                      ALL_DELIVERY_VEHICLE_IDS.indexOf(a.deliveryVehicleId) -
                      ALL_DELIVERY_VEHICLE_IDS.indexOf(b.deliveryVehicleId)
                    );
                  })
                  .map(({ deliveryVehicleId, name }) => (
                    <Radio
                      key={deliveryVehicleId}
                      value={deliveryVehicleId}
                      mr={3}
                      mb={3}
                      data-cy={`delivery-vehicle-${deliveryVehicleId}`}
                    >
                      <HStack spacing={1}>
                        <DeliveryVehicleIcon
                          deliveryVehicle={deliveryVehicleId}
                        />
                        <Text>{name}</Text>
                      </HStack>
                    </Radio>
                  ))}
              </Flex>
              {selectedVehicle ? (
                <DeliveryVehicleInfo
                  fontSize={12}
                  selectedVehicle={selectedVehicle}
                />
              ) : null}
            </RadioGroup>
            <Divider />
            <RadioGroup
              mt={6}
              mb={3}
              onChange={handleChangeDeliveryOption}
              value={deliveryOptionId ?? ''}
              data-cy="delivery-option-radio-group"
            >
              <Flex flexWrap="wrap" alignItems="center">
                {selectedVehicleOptions.map((o) => (
                  <Radio
                    key={o.deliveryOptionId}
                    value={o.deliveryOptionId}
                    mr={3}
                    mb={3}
                    data-cy={`delivery-option-${o.deliveryOptionId}`}
                  >
                    <Text>{o.name}</Text>
                  </Radio>
                ))}
              </Flex>
              <Text color={'blackAlpha.500'} fontSize={12}>
                {deliveryOptionId
                  ? getDeliveryOptionDescription(deliveryOptionId)
                  : ''}
              </Text>
              {isOptionAvailable === false ? (
                <Text fontSize={12} color={'orange.400'}>
                  <Icon as={WarningTwoIcon} mr={1} />
                  Unavailable for selected date
                </Text>
              ) : null}
            </RadioGroup>
          </Box>
        )}
        {deliveryOptionsQuery.status === 'error' && (
          <Text color={'red.500'}>Something went wrong fetching options</Text>
        )}
        <Divider />
        <Stack
          direction={'row'}
          justifyContent="space-between"
          spacing={3}
          py={4}
        >
          <Text>
            <Text>Includes yard items (heavyside)</Text>
            <Text color={'blackAlpha.500'} fontSize={12}>
              (e.g. bricks, sand, timber, plasterboard etc..)
            </Text>
          </Text>
          <RadioGroup
            onChange={(value) => {
              handleChangeHeavySideItems(value, setHeavySideItems, posthog);
            }}
            value={
              heavySideItems === undefined
                ? undefined
                : heavySideItems === true
                ? 'yes'
                : 'no'
            }
            data-cy="heavy-side-items-radio-group"
          >
            <Flex flexWrap="wrap" alignItems={'center'}>
              <Radio value={'yes'} mr={3} mb={3} data-cy="heavy-side-items-yes">
                <Text>Yes</Text>
              </Radio>
              <Radio value={'no'} mr={3} mb={3} data-cy="heavy-side-items-no">
                <Text>No</Text>
              </Radio>
            </Flex>
          </RadioGroup>
        </Stack>
        {status === 'loading' && enableCalculation && shouldCalculateDelivery && (
          <Center h={'218px'}>
            <Spinner />
          </Center>
        )}
        {status === 'error' && enableCalculation && (
          <Center h={36}>
            <Text color="red.500">
              {getApiErrorMessage(error) ?? 'Something went wrong'}
            </Text>
          </Center>
        )}
        {status === 'success' && enableCalculation && (
          <>
            <Divider />
            {isEveryStopOverThreshold ? (
              allowLongDistance ? (
                <Alert status="warning" borderRadius={'md'} my={4} size="sm">
                  <AlertIcon />
                  <Text>
                    <Text fontWeight={'bold'}>
                      Every stop is over the threshold distance from London.
                    </Text>
                    Please confirm with Tradeaze before booking.
                    <Text as="i">{` The below price may not be accurate.`}</Text>
                  </Text>
                </Alert>
              ) : (
                <Alert status="error" borderRadius={'md'} my={4} size="sm">
                  <AlertIcon />
                  <Text>
                    <Text fontWeight={'bold'}>
                      At least one stop must be in London.
                    </Text>
                    You must contact Tradeaze to book this delivery.
                    <Text as="i">{` The below price may not be accurate.`}</Text>
                  </Text>
                </Alert>
              )
            ) : null}
            <Flex my={3} justify={'space-between'}>
              <Text>
                Delivery Price
                <Text fontSize={14} color={'grey'}>
                  (exc. VAT)
                </Text>
              </Text>
              <Text>£{data.deliveryPrice.toFixed(2)}</Text>
            </Flex>
            <Flex my={2} justify={'space-between'}>
              <Text>
                Service Charge
                <Text fontSize={14} color={'grey'}>
                  (exc. VAT)
                </Text>
              </Text>
              <Text>£{data.serviceCharge.toFixed(2)}</Text>
            </Flex>
            <Flex my={4} justify={'space-between'}>
              <Text fontSize={16} fontWeight={'bold'}>
                Total{' '}
                <Text fontSize={14} color={'grey'}>
                  (exc. VAT)
                </Text>
              </Text>
              <Box>
                <Text fontSize={16} fontWeight={'bold'} textAlign="right">
                  £{totalTradeazeCharges.toFixed(2)}
                </Text>
                {isSaturday && (
                  <Text color={'blackAlpha.500'} fontSize={12} mt={2}>
                    (Saturday charges apply)
                  </Text>
                )}
              </Box>
            </Flex>
          </>
        )}
      </ModalBody>
      <ModalFooter
        borderTop={'1px'}
        borderColor="blackAlpha.100"
        justifyContent={'space-between'}
      >
        <TradeazeLogoNoText height={'34'} />
        {enableCalculation ? (
          <HStack>
            <Button
              variant={'outline'}
              isLoading={isFetching}
              onClick={handleResetCalculation}
              data-cy="reset-calculation-button"
            >
              Reset
            </Button>
            <Button onClick={handleCreateOrder} data-cy="create-order-button">
              Create Order
            </Button>
          </HStack>
        ) : (
          <Button
            isLoading={isFetching}
            isDisabled={shouldDisableCalculate}
            variant="solid"
            onClick={handleCalculate}
            data-cy="calculate-button"
          >
            Calculate
          </Button>
        )}
      </ModalFooter>
    </>
  );
};
