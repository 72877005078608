import { AnimatedImage } from '../shared/AnimatedImage';
import Svg from './assets/Logistics-rafiki.svg';

interface ManVanImageProps {
  animationDelay?: number;
}

export const ManVanImage = ({ animationDelay }: ManVanImageProps) => {
  return (
    <AnimatedImage
      src={Svg}
      alt="Man Van Booking"
      position="absolute"
      bottom="0"
      right="0"
      height="100%"
      maxWidth="70%"
      objectFit="contain"
      animationDelay={animationDelay}
    />
  );
};
