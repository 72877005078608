import { Tag, TagLabel } from '@chakra-ui/react';
import { ClockInWorkType } from '@tradeaze-packages/schemas';

type WorkTypeTagProps = {
  workType: ClockInWorkType | 'EXTERNAL';
  size?: 'sm' | 'md';
};

export const WorkTypeTag = ({ workType, size = 'sm' }: WorkTypeTagProps) => {
  const label = {
    GIG: 'GIG',
    SHIFT: 'SHIFT',
    EXTERNAL: 'EXTERNAL',
  }[workType] || 'SHIFT';

  return (
    <Tag
      colorScheme="yellow"
      size={size}
      fontWeight={'bold'}
      mx={1}
      border="1px solid #FBE7AA"
    >
      <TagLabel data-cy={`work-type-${workType.toLowerCase()}`}>
        {label}
      </TagLabel>
    </Tag>
  );
};
