import { z } from 'zod';
import {
  CutOffTimeSchema,
  DeliveryOptionIdSchema,
  DeliveryVehicleIdSchema,
  OrderTypeSchema,
  PositionSchema,
} from '../../../models';
import { processStringBoolean } from '../../../utils';

export const DistanceAndDurationSchema = z.object({
  duration: z.number(),
  distance: z.number(),
});

export const GetDeliveryPriceStringQuerySchema = z.object({
  deliveryOptionId: DeliveryOptionIdSchema,
  orderType: OrderTypeSchema,
  scheduledTime: z.string(),
  optimiseStops: z.preprocess(processStringBoolean, z.boolean()).optional(),
  throwOverThreshold: z
    .preprocess(processStringBoolean, z.boolean())
    .optional(),
  throwOnBikeOverThreshold: z
    .preprocess(processStringBoolean, z.boolean())
    .optional(),
  heavySideItems: z.preprocess(processStringBoolean, z.boolean()).optional(),
});

export const GetDeliveryPriceArrayQuerySchema = z.object({
  deliveryStopLocations: z.string().array(),
});

export const GetDeliveryPriceQuerySchema =
  GetDeliveryPriceStringQuerySchema.merge(GetDeliveryPriceArrayQuerySchema);

export const GetDeliveryPriceResponseSchema = z.object({
  orderType: OrderTypeSchema,
  deliveryVehicleId: DeliveryVehicleIdSchema,
  deliveryOptionId: DeliveryOptionIdSchema,
  deliveryPrice: z.number(),
  serviceCharge: z.number(),
  totalTimeEstimate: z.number(),
  distance: z.number(),
  duration: z.number(),
  thresholdDistances: z.array(DistanceAndDurationSchema),
  isAvailable: z.boolean(),
  cutOffTime: CutOffTimeSchema,
  windowStart: z.string(),
  windowEnd: z.string(),
  optimisedStopOrder: z.array(z.object({
      stopLocation: z.string(),
      originalIndex: z.number(),
      optimisedIndex: z.number(),
    })
  ).optional(),
  deliveryJourney: z.object({
    distance: z.number(),
    duration: z.number(),
    routeLegs: z.array(
      z.object({
        startLocation: PositionSchema,
        endLocation: PositionSchema,
        distance: z.number(),
        duration: z.number(),
      })
    ),
  }),
});

export const GetDeliveryPriceErrorDataSchema = z.object({
  deliveryStops: z.array(
    z.object({
      distance: z.number(),
      duration: z.number(),
      isOverThreshold: z.boolean(),
    })
  ),
  thresholdMetres: z.number(),
});

export type DistanceAndDuration = z.infer<typeof DistanceAndDurationSchema>;
export type GetDeliveryPriceQuery = z.infer<typeof GetDeliveryPriceQuerySchema>;
export type GetDeliveryPriceResponse = z.infer<
  typeof GetDeliveryPriceResponseSchema
>;
export type GetDeliveryPriceErrorData = z.infer<
  typeof GetDeliveryPriceErrorDataSchema
>;
