import { Grid, GridItem } from '@chakra-ui/react';
import { NAVBAR_HEIGHT_PX } from '../navbar';

export const TwoColumnLayout = ({
  left,
  right,
  leftContainerProps,
  rightContainerProps,
}: {
  left: React.ReactNode;
  right: React.ReactNode;
  leftContainerProps?: React.ComponentProps<typeof GridItem>;
  rightContainerProps?: React.ComponentProps<typeof GridItem>;
}) => {
  return (
    <Grid
      position={'fixed'}
      templateColumns={'repeat(6, 1fr)'}
      w={`calc(100vw - ${NAVBAR_HEIGHT_PX})`}
      right={0}
      top={0}
    >
      <GridItem
        colSpan={3}
        minWidth={[null, null, '450px']}
        overflowY={'auto'}
        paddingX={[6, 8, 10]}
        paddingTop={8}
        paddingBottom={16}
        h={'100vh'}
        {...leftContainerProps}
      >
        {left}
      </GridItem>
      <GridItem
        colSpan={3}
        overflowY={'hidden'}
        position={'sticky'}
        bottom={0}
        h={'100vh'}
        {...rightContainerProps}
      >
        {right}
      </GridItem>
    </Grid>
  );
};
