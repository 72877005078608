import { DeliveryItem, WeightUnit } from '@tradeaze-packages/schemas';

const assertNever = (value: never): never => {
  throw new Error(`Unhandled weight unit: ${value}`);
};

const convertToKg = (value: number, unit: WeightUnit) => {
  switch (unit) {
    case 'kg':
      return value;
    default:
      return assertNever(unit);
  }
};

export const calculateTotalWeightKg = (deliveryItems: Pick<DeliveryItem, 'weight' | 'weightUnit'>[]) =>
  deliveryItems.reduce(
    (acc, item) => acc + convertToKg(item.weight, item.weightUnit),
    0
  );
