import { useQuery } from '@tanstack/react-query';
import { getOrderCounts } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared';
import { GetOrderCountsQuery } from '@tradeaze-packages/schemas';

export const getOrderCountsQueryKey = (query?: GetOrderCountsQuery) => {
  const key: (string | GetOrderCountsQuery)[] = ['getOrderCounts'];

  if (query) {
    key.push(query);
  }

  return key;
};

export const useGetOrderCounts = (
  query: GetOrderCountsQuery,
  options?: CustomUseQueryOptions<typeof getOrderCounts>,
) => {
  return useQuery({
    queryKey: getOrderCountsQueryKey(query),
    queryFn: () => getOrderCounts(query),
    ...options,
  });
};
