import { AnimatedImage } from '../shared/AnimatedImage';
import Svg from './assets/Address-cuate.svg';

interface AddressBookImageProps {
    animationDelay?: number;
}

export const AddressBookImage = ({ animationDelay }: AddressBookImageProps) => {
    return (
        <AnimatedImage
            src={Svg}
            alt="Address Book"
            position="absolute"
            bottom="0"
            right="0"
            height="100%"
            maxWidth="70%"
            objectFit="contain"
            animationDelay={animationDelay}
        />
    );
};