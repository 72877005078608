import { SimpleGrid } from '@chakra-ui/react';
import { Company } from '@tradeaze-packages/schemas';
import { CustomerCompanyCard } from './CustomerCompanyCard';
import { Waypoint } from 'react-waypoint';

interface MerchantCustomerPreviewGridProps {
  merchantCustomers: Company[];
  onWaypointEnter: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  columns?: number[];
  merchantId: string;
}

export const MerchantCustomerPreviewGrid = ({
  merchantCustomers,
  onWaypointEnter,
  hasNextPage,
  isFetchingNextPage,
  columns = [1, 1, 2, 3],
  merchantId,
}: MerchantCustomerPreviewGridProps) => {
  return (
    <SimpleGrid columns={columns} spacing={4}>
      {merchantCustomers.map((customer, index) => (
        <CustomerCompanyCard
          key={customer.companyId}
          customer={customer}
          merchantId={merchantId}
        />
      ))}
      {hasNextPage && !isFetchingNextPage && (
        <Waypoint onEnter={onWaypointEnter} />
      )}
    </SimpleGrid>
  );
};
