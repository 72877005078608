import React from 'react';

export const BikeSideIcon: React.FC<{
  width?: number;
  height?: number;
  xArea?: number;
  yArea?: number;
  xPosition?: string;
  yPosition?: string;
}> = ({ height, width, xArea, xPosition, yArea, yPosition }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="40 0 348 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="60" y="95" fill="#232324" fontSize="14" fontFamily="Arial">
        Height
      </text>
      <text x="160" y="10" fill="#232324" fontSize="14" fontFamily="Arial">
        Length
      </text>
      <path
        d="M202.682 206.113C195.84 206.113 190.014 203.861 185.204 199.358C180.394 194.855 177.989 189.387 177.989 182.955H157V159.797C157 150.599 160.411 142.798 167.232 136.392C174.054 129.987 182.362 126.784 192.157 126.784H227.313V176.057H274.014L314.418 129.248V104.612C314.418 102.312 313.63 100.424 312.056 98.9454C310.482 97.4673 308.471 96.7282 306.022 96.7282H282.934V89.8301H306.022C310.351 89.8301 314.057 91.2775 317.14 94.1722C320.222 97.0669 321.764 100.547 321.764 104.612V131.219L278.212 182.955H227.313C227.313 189.36 224.919 194.821 220.129 199.338C215.34 203.854 209.524 206.113 202.682 206.113ZM202.651 199.214C207.374 199.214 211.44 197.613 214.851 194.41C218.262 191.208 219.967 187.389 219.967 182.955H185.335C185.335 187.389 187.041 191.208 190.451 194.41C193.862 197.613 197.929 199.214 202.651 199.214ZM323.369 206.113C316.527 206.113 310.701 203.864 305.891 199.367C301.081 194.869 298.676 189.408 298.676 182.984C298.676 176.559 301.07 171.088 305.86 166.572C310.649 162.055 316.465 159.797 323.307 159.797C330.149 159.797 335.975 162.045 340.785 166.543C345.595 171.04 348 176.501 348 182.926C348 189.35 345.605 194.821 340.816 199.338C336.026 203.854 330.211 206.113 323.369 206.113ZM323.338 199.214C328.06 199.214 332.127 197.613 335.538 194.41C338.948 191.208 340.654 187.389 340.654 182.955C340.654 178.52 338.948 174.702 335.538 171.499C332.127 168.296 328.06 166.695 323.338 166.695C318.615 166.695 314.549 168.296 311.138 171.499C307.727 174.702 306.022 178.52 306.022 182.955C306.022 187.389 307.727 191.208 311.138 194.41C314.549 197.613 318.615 199.214 323.338 199.214ZM164.346 176.057H219.967V133.682H192.105C184.444 133.682 177.902 136.239 172.479 141.354C167.057 146.468 164.346 152.615 164.346 159.797V176.057Z"
        fill="#A7A7A8"
      />
      <rect x="132" y="44" width="96" height="96" rx="12" fill="#F9F9F9" />
      <rect
        x="132"
        y="44"
        width="96"
        height="96"
        rx="12"
        stroke="#A7A7A8"
        stroke-width="8"
      />
      <rect x="144" y="56" width="72" height="72" rx="4" fill="#D5D5D5" />
      <rect
        x={xPosition}
        y={yPosition}
        width={xArea}
        height={yArea}
        rx="4"
        fill="#AF9032"
      />
      <path
        d="M112.5 53L114.887 48L109.113 48L111.5 53L112.5 53ZM111.5 130L109.113 135L114.887 135L112.5 130L111.5 130ZM111.5 52.5L111.5 130.5L112.5 130.5L112.5 52.5L111.5 52.5Z"
        fill="#AF9032"
      />
      <path
        d="M218 24.5L223 26.8868V21.1132L218 23.5V24.5ZM141 23.5L136 21.1132V26.8868L141 24.5V23.5ZM218.5 23.5L140.5 23.5V24.5L218.5 24.5V23.5Z"
        fill="#AF9032"
      />
    </svg>
  );
};
