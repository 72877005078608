import { CurrentDeliveryOptionIdSchema, DeliveryOptionId, DeliveryOptionTypeId, DeliveryOptionType, DeliveryVehicleId } from '../models';
import { getOptionPricing } from './pricing';

export const ALL_DELIVERY_OPTION_IDS = Object.values(
  CurrentDeliveryOptionIdSchema.Values,
);

export const DELIVERY_OPTION_TYPES: DeliveryOptionType[] = [
  {
    id: 'MORNING',
    name: 'Morning',
    description: 'Delivered before 10am',
  },
  {
    id: 'DAY',
    name: 'Day',
    description: 'Delivered before the end of the day',
  },
  {
    id: 'EVENING',
    name: 'Evening',
    description: 'Delivered after 4pm and before 8pm',
  },
  {
    id: 'PRIORITY',
    name: 'Priority',
    description: 'Delivered as soon as possible',
  },
  {
    id: 'SCHEDULED',
    name: 'Scheduled',
    description: 'Delivered or collected within the given time slots',
  },
  {
    id: 'THREE_HOUR',
    name: 'Three Hour',
    description: 'Delivered within 3 hours from the time of order',
  },
];

export const getDeliveryOptionDescription = (optionId?: DeliveryOptionId | null) => {
  if (!optionId) {
    return null;
  }

  const optionType = DELIVERY_OPTION_TYPES.find(
    (ot) => optionId.includes(ot.id),
  );
  
  if (!optionType) {
    throw new Error(`Delivery option type not found for optionId: ${optionId}`);
  }
  return optionType.description;
};

export const createDeliveryOption = ({
  vehicleId,
  optionTypeId,
  name,
}: {
  vehicleId: DeliveryVehicleId;
  optionTypeId: DeliveryOptionTypeId;
  name: string;
}) => {
  const deliveryOptionId = `${vehicleId}_${optionTypeId}` as DeliveryOptionId;

  const pricing = getOptionPricing(vehicleId, optionTypeId);

  return {
    deliveryOptionId,
    name,
    deliveryVehicleId: vehicleId,
    pricingMultiplier: pricing.multiplier,
    minimumPrice: pricing.minimum,
    serviceCharge: pricing.serviceCharge,
    fixedCharge: pricing.fixedCharge,
  };
};

export const createVehicleOptions = (
  vehicleId: DeliveryVehicleId,
  config: {
    MORNING: boolean;
    PRIORITY: boolean;
    DAY: boolean;
    EVENING: boolean;
    SCHEDULED: boolean;
    THREE_HOUR: boolean;
  },
) => {
  const options = [
    {
      enabled: config.MORNING,
      type: 'MORNING' as const,
      name: 'Pre 10AM',
    },
    {
      enabled: config.SCHEDULED,
      type: 'SCHEDULED' as const,
      name: 'Scheduled',
    },
    {
      enabled: config.THREE_HOUR,
      type: 'THREE_HOUR' as const,
      name: '3 Hour',
    },
    {
      enabled: config.PRIORITY,
      type: 'PRIORITY' as const,
      name: 'Priority',
    },
    {
      enabled: config.DAY,
      type: 'DAY' as const,
      name: 'Day',
    },
    {
      enabled: config.EVENING,
      type: 'EVENING' as const,
      name: 'Evening',
    },
  ];

  return options
    .filter((option) => option.enabled)
    .map((option) =>
      createDeliveryOption({
        vehicleId,
        optionTypeId: option.type,
        name: option.name,
      }),
    );
};