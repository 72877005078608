// requires its own file to avoid circular dependencies

import { z } from 'zod';

export const DeliveryStatusSchema = z.enum([
  'PENDING',
  'CONFIRMED',
  'DELIVERED',
  'REJECTED',
  'CANCELLED',
]);

export type DeliveryStatus = z.infer<typeof DeliveryStatusSchema>;