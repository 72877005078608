import { useQuery } from '@tanstack/react-query';
import {
  GetDeliveryPriceErrorData,
  GetDeliveryPriceQuery,
} from '@tradeaze-packages/schemas';
import { fetchDeliveryPrice } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared';

type PricingError = {
  error: {
    message: string;
    data: GetDeliveryPriceErrorData;
  };
  status: number;
};

type UseGetDeliveryPriceArgs = Omit<GetDeliveryPriceQuery, 'deliveryOptionId'> & {
  deliveryOptionId: GetDeliveryPriceQuery['deliveryOptionId'] | null;
};


export const getDeliveryPriceQueryKey = (args: UseGetDeliveryPriceArgs) => {
  return ['getDeliveryPrice', args];
};

export const useGetDeliveryPrice = (
  args: UseGetDeliveryPriceArgs,
  options?: CustomUseQueryOptions<typeof fetchDeliveryPrice, PricingError>,
) =>
  useQuery({
    queryKey: getDeliveryPriceQueryKey(args),
    queryFn: async () => {
      if (!args.deliveryOptionId) {
        throw new Error('Delivery option ID is required');
      }
      return fetchDeliveryPrice({
        ...args,
        deliveryOptionId: args.deliveryOptionId,
      });
    },
    retry: false,
    ...options,
  });
