import { useMutation } from '@tanstack/react-query';
import { DownloadAdminOrdersCsvQuery } from '@tradeaze-packages/schemas';
import { downloadUrl } from '@tradeaze/frontend/utils';
import { fetchAdminOrdersCsv } from '@tradeaze-packages/api-client';
import toast from 'react-hot-toast';

export const useDownloadAdminOrders = () => {
  let loadingToastId: string;

  return useMutation(
    {
      mutationFn: async (filters: DownloadAdminOrdersCsvQuery) => fetchAdminOrdersCsv(filters),
      onError() {
        toast.error('Error generating CSV');
      },
      onSuccess: async (data) => {
        toast.success('Generated CSV');
        downloadUrl(data.url, `Orders - ${new Date().toISOString()}.csv`);
      },
      onMutate: () => {
        loadingToastId = toast.loading('Generating CSV');
      },
      onSettled: () => {
        toast.dismiss(loadingToastId);
      },
    }
  );
};

